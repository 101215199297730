import ToastNotification from "@/components/util/toastNotification";
import { toast } from "react-toastify";

/**
 * creates a toast notification using react-toastify
 *
 * example:
 * createToast({type: 'success', msg: 'this is my test notification'});
 *
 * @param {Object} notification object {type: 'success|error|warning|default', msg: 'the notification message'}
 * @param {number} duration optional - defaults to 5000
 **/
export const createToastSuccess = (msg: string, duration: number = 5000) => {
  createToast({ type: "success", msg }, duration);
};
export const createToastWarning = (msg: string, duration: number = 5000) => {
  createToast({ type: "warning", msg }, duration);
};
export const createToastError = (msg: string, duration: number = 5000) => {
  createToast({ type: "error", msg }, duration);
};

export const createToast = (
  notification: {
    type: "success" | "error" | "warning" | "default";
    msg: string;
  },
  duration: number = 5000
) => {
  let toastClass;
  let toastIcon: JSX.Element;
  let toastIconClass;

  switch (notification.type) {
    case "success":
      toastClass = "success-toast";
      toastIcon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 150 150"
        >
          <path
            fill="#4ca22f"
            fillRule="evenodd"
            d="M63.438 101c-1.15 0-2.301-.438-3.18-1.316L40.32 79.776c-1.76-1.756-1.761-4.605-.005-6.364 1.756-1.758 4.606-1.76 6.364-.004L63.438 90.14l39.883-39.824c1.758-1.758 4.607-1.755 6.364.004 1.756 1.759 1.754 4.608-.005 6.364L66.618 99.684c-.88.878-2.03 1.316-3.18 1.316"
          />
        </svg>
      );
      toastIconClass = "success-toast-icon";
      break;
    case "error":
      toastClass = "error-toast";
      toastIcon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 150 150"
        >
          <path
            fill="#d51317"
            fillRule="evenodd"
            d="M99.682 93.318c1.757 1.757 1.757 4.607 0 6.364-.88.879-2.03 1.318-3.182 1.318-1.152 0-2.303-.44-3.182-1.318L75 81.364 56.682 99.682c-.88.879-2.03 1.318-3.182 1.318-1.152 0-2.303-.44-3.182-1.318-1.757-1.757-1.757-4.607 0-6.364L68.636 75 50.318 56.682c-1.757-1.757-1.757-4.607 0-6.364 1.758-1.757 4.606-1.757 6.364 0L75 68.636l18.318-18.318c1.758-1.757 4.606-1.757 6.364 0 1.757 1.757 1.757 4.607 0 6.364L81.364 75l18.318 18.318z"
          />
        </svg>
      );
      toastIconClass = "error-toast-icon";
      break;
    case "warning":
      toastClass = "warning-toast";
      toastIcon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 150 150"
        >
          <path
            fill="#f39200"
            fillRule="evenodd"
            d="M78.54 100.7c.93-.94 1.46-2.22 1.46-3.54 0-1.32-.53-2.61-1.46-3.54-.94-.93-2.22-1.46-3.54-1.46-1.32 0-2.61.53-3.54 1.46-.93.93-1.46 2.22-1.46 3.54 0 1.32.53 2.6 1.46 3.54.93.93 2.22 1.46 3.54 1.46 1.32 0 2.61-.53 3.54-1.46zM79 84.091V65.486c0-2.209-1.79-4-4-4-2.209 0-4 1.791-4 4v18.605c0 2.209 1.791 4 4 4 2.21 0 4-1.791 4-4zM39.87 108h70.26L75 46.104 39.87 108zm77.13 8H33c-1.424 0-2.74-.757-3.457-1.987-.716-1.23-.725-2.749-.02-3.987l41.998-74c.711-1.252 2.04-2.026 3.48-2.026 1.438 0 2.767.774 3.477 2.026l42 74c.704 1.238.695 2.757-.022 3.987-.716 1.23-2.032 1.987-3.456 1.987z"
          />
        </svg>
      );
      toastIconClass = "warning-toast-icon";
      break;

    default:
      toastClass = "default-toast";
      toastIcon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 150 150"
        >
          <path
            fill="#00b1eb"
            fillRule="evenodd"
            d="M113 75c0 20.953-17.047 38-38 38S37 95.953 37 75s17.047-38 38-38 38 17.047 38 38m8 0c0-25.364-20.636-46-46-46S29 49.636 29 75s20.636 46 46 46 46-20.636 46-46M82.24 55.05c.93-.93 1.46-2.22 1.46-3.53 0-1.32-.53-2.61-1.46-3.54-.93-.93-2.22-1.46-3.54-1.46-1.31 0-2.6.53-3.53 1.46-.94.93-1.47 2.22-1.47 3.54 0 1.31.53 2.6 1.47 3.53.93.93 2.21 1.47 3.53 1.47 1.32 0 2.61-.54 3.54-1.47m-9.934 47.76c-.784 0-1.56-.264-2.194-.773-1.014-.815-1.492-2.127-1.24-3.404l4.926-25.009-2.713 1.528c-1.686.949-3.82.352-4.767-1.332-.949-1.684-.353-3.818 1.331-4.767l9.334-5.258c1.191-.671 2.663-.587 3.769.214 1.107.8 1.647 2.172 1.383 3.512L77.03 93.434l4.186-1.824c1.774-.77 3.835.04 4.607 1.811.772 1.772-.039 3.834-1.811 4.607l-10.308 4.491c-.449.195-.925.291-1.398.291"
          />
        </svg>
      );
      toastIconClass = "default-toast-icon";
  }

  toast(
    <ToastNotification toastIcon={toastIcon} toastMessage={notification.msg} />,
    {
      position: "bottom-right",
      autoClose: duration,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      className: toastClass,
    }
  );
};
