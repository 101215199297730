import { determineStoreValueId } from "@/services/ceSettings/ceSettingsService";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import { STORE_TYPOGRAPHY_CLASS_PREFIX } from "@/utils/constants";

export default function useStrTypography(
  typographyStoreSetting?: StoreSetting,
  ceSettings?: StoreSetting
) {
  const typographyId = determineStoreValueId(
    typographyStoreSetting,
    ceSettings
  );

  const typographyClassName = typographyId
    ? `${STORE_TYPOGRAPHY_CLASS_PREFIX}${typographyId}`
    : `${STORE_TYPOGRAPHY_CLASS_PREFIX}unstyled`;

  return { typographyClassName };
}
