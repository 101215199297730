import CmsMediaManagerModal from "@/components/cms/cmsMediaManager/cmsMediaManagerModal";
import useCmsTopBarEffects from "@/hooks/useCmsTopBarEffects";
import { PbPage } from "@/types/content-elements";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import CmsDynamicListEditorModal from "../cmsDynamicList/cmsDynamicListEditorModal";
import CmsTopBarCenterControls from "./cmsTopBarCenterControls";
import CmsTopBarLeftControls from "./cmsTopBarLeftControls";
import CmsTopBarRightControls from "./cmsTopBarRightControls";
import CmsTopBarStyle from "./cmsTopBarStyle";
import PbCmsStyle from "./pbCmsStyle";

export interface CmsTopBarProps {
  page?: PbPage;
}

/**
 * CmsTopBar component
 * 
 * This component indicates that the user is logged into the PageBuilder 
 * Frontend and provides the content manager with main navigation and 
 * action buttons. The main navigation is a dropdown autocomplete with 
 * all existing pages (both published and unpublished). The action 
 * buttons include options to edit a page, add a page, and other 
 * relevant actions.
 *
 * This component displays:
 * - A main navigation dropdown autocomplete with all existing 
 *    pages (published and unpublished).
 * - Action buttons such as edit page, add page, etc.
 * - the cms language selector
 *
 * @param {CmsTopBarProps} props - The properties passed to the CmsTopBar 
 *    component.
 * @param {page} [props.page] - optional page object that is passed 
 *    from layout 
 * @returns {JSX.Element} The CmsTopBar component.
 */
const CmsTopBar = (props: CmsTopBarProps): JSX.Element => {
  useCmsTopBarEffects(props.page);

  return (
    <>
      <AppBar
        position="sticky"
        sx={{ backgroundColor: "#2a3035", flexFlow: "row" }}
        className="pb-cms cms-top-bar"
      >
        <Container
          maxWidth={false}
          sx={{
            padding: 0,
            "@media (min-width: 600px)": {
              paddingLeft: "5px",
              paddingRight: "5px",
            },
          }}
        >
          <Toolbar disableGutters variant="dense">
            <CmsTopBarLeftControls />
            <CmsTopBarCenterControls />
            <CmsTopBarRightControls />
            <CmsDynamicListEditorModal />
            <CmsMediaManagerModal />
          </Toolbar>
        </Container>
      </AppBar>
      <CmsTopBarStyle />
      <PbCmsStyle />
    </>
  );
};

export default CmsTopBar;
