import {
  CFG_CONTENT_ELEMENT_SETTING_NAME,
  firstCeSettingByStoreType,
  sanitizeDraftPageResult,
} from "@/services/ceSettings/ceSettingsService";
import { cmsTranslate } from "@/services/cmsTranslation/cmsTranslationService";
import {
  createEmptyContentElement,
  createEmptyNestedContentElement,
  createPageRequest,
  deletePageRequest,
  getCmsPageRequest,
  getDraftPageRequest,
  getPageContentRequest,
  publishPageRequest,
  updateDraftPageRequest,
  updatePageSettingsRequest,
} from "@/services/pageService/pageService";
import { ReduxThunkAction } from "@/store/store";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import { CMS_PABU_VERSION } from "@/utils/constants";
import { deepImmutableCopy, getRandomSlug } from "@/utils/util";
import { setEditModeAction } from "../cmsGeneral/cmsGeneralSlice";
import {
  hideLoadingOverlayAction,
  showLoadingOverlayAction,
} from "../general/generalSlice";
import {
  addToArrayAction,
  initEditDataAction,
  updateAttributeAction,
} from "./cmsEditSlice";

export const cmsGetDraftPageAndStartEditModeThunk =
  (url: string): ReduxThunkAction =>
  async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction(cmsTranslate("loadingPage")));
    const cmsPageByUrlResult = await getCmsPageRequest(url);
    if (!cmsPageByUrlResult.success) {
      dispatch(hideLoadingOverlayAction());
      return Promise.reject();
    }

    const draftPageResult = await getDraftPageRequest(
      cmsPageByUrlResult.data.refId
    );
    const sanitizedDraftPage = sanitizeDraftPageResult(draftPageResult.data);

    dispatch(hideLoadingOverlayAction());
    if (draftPageResult.success) {
      dispatch(setEditModeAction(true));
      dispatch(initEditDataAction(sanitizedDraftPage));
      return Promise.resolve();
    }
    return Promise.reject();
  };

export const cmsSaveDraftPageContentThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction(cmsTranslate("updatingPage")));
    const cmsSaveDraftPageResult = await updateDraftPageRequest(
      getState().cmsEdit.data?.draftPage?.id,
      getState().cmsEdit.data?.draftPage?.content
    );
    dispatch(hideLoadingOverlayAction());
    if (!cmsSaveDraftPageResult.success) {
      return Promise.reject();
    }

    // case saving a temp localized page
    if (
      getState().cmsEdit.data?.draftPage?.id !==
        cmsSaveDraftPageResult.data.id &&
      getState().cmsEdit.data?.draftPage?.locale !==
        cmsSaveDraftPageResult.data.locale
    ) {
      global.log.debug(
        "Saved a temporary localized draftpage. Updating state..."
      );
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.id`,
          value: cmsSaveDraftPageResult.data.id,
        })
      );
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.locale`,
          value: cmsSaveDraftPageResult.data.locale,
        })
      );
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.refId`,
          value: cmsSaveDraftPageResult.data.refId,
        })
      );
    }

    return Promise.resolve();
  };

export const cmsPublishDraftPageContentThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction(cmsTranslate("publishingPage")));
    const cmsSaveDraftPageResult = await publishPageRequest(
      getState().cmsEdit.data?.draftPage?.id
    );
    dispatch(hideLoadingOverlayAction());
    if (!cmsSaveDraftPageResult.success) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

export const cmsCreatePageThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction(cmsTranslate("creatingPage")));
    const cmsCreatePageResult = await createPageRequest(
      getState().cmsEdit.data
    );
    dispatch(hideLoadingOverlayAction());
    if (!cmsCreatePageResult.success) {
      return Promise.reject(cmsCreatePageResult.error.response.data);
    }
    return Promise.resolve(cmsCreatePageResult.data.page);
  };

export const cmsUpdatePageSettingsThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction(cmsTranslate("updatingPage")));
    const cmsUpdatePageSettingsResult = await updatePageSettingsRequest({
      ...getState().cmsEdit.data?.draftPage,
    });
    dispatch(hideLoadingOverlayAction());
    return !cmsUpdatePageSettingsResult.success
      ? Promise.reject(cmsUpdatePageSettingsResult.error?.response?.data)
      : Promise.resolve(cmsUpdatePageSettingsResult.data);
  };

export const cmsPageDeleteThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction(cmsTranslate("deletingPage")));
    const cmsPageDeleteResult = await deletePageRequest(
      getState().cmsEdit.data.draftPage.id
    );
    const cmsPageRefDeleteResult = await deletePageRequest(
      getState().cmsEdit.data.draftPage.refId
    );
    dispatch(hideLoadingOverlayAction());
    return !cmsPageDeleteResult && !cmsPageRefDeleteResult
      ? Promise.reject()
      : Promise.resolve();
  };

export const cmsCloneContentElementThunk =
  (position: number): ReduxThunkAction =>
  async (dispatch, getState) => {
    const elementToClone = getState().cmsEdit.data.draftPage.content[position];
    const clonedElement = deepImmutableCopy(elementToClone);
    delete clonedElement.id;
    clonedElement.__new_id = `new_${getRandomSlug()}`;
    dispatch(
      addToArrayAction({
        attributePath: "draftPage.content",
        value: clonedElement,
        pos: position + 1,
      })
    );
  };

export const cmsAddContentElementThunk =
  (element: any, position?: number | undefined): ReduxThunkAction =>
  async (dispatch, getState) => {
    const newContentElement = createEmptyContentElement(element);
    if (newContentElement) {
      if (newContentElement.hasOwnProperty(CFG_CONTENT_ELEMENT_SETTING_NAME)) {
        const ceSettingInput = newContentElement[
          CFG_CONTENT_ELEMENT_SETTING_NAME
        ] as StoreSetting;
        const defaultCeSetting = firstCeSettingByStoreType(
          ceSettingInput.storeType
        );
        newContentElement[CFG_CONTENT_ELEMENT_SETTING_NAME] = {
          ...ceSettingInput,
          values: defaultCeSetting ? [defaultCeSetting.id] : [],
        } as StoreSetting;
      }

      if (
        newContentElement.hasOwnProperty("cfgInfo") &&
        !newContentElement.cfgInfo
      ) {
        // PaBu specific informations about the CE.
        newContentElement.cfgInfo = {
          // (custom) CreatedAt
          created: new Date().toISOString(),
          // CMS PABU Version
          version: CMS_PABU_VERSION,
        };
      }
    }
    dispatch(
      addToArrayAction({
        attributePath: "draftPage.content",
        value: newContentElement,
        pos: position,
      })
    );
  };

export const cmsAddNestedContentElementThunk =
  (
    nestedElement: any,
    position: number,
    nestedFieldName: string,
    nestedPosition?: number | undefined
  ): ReduxThunkAction =>
  async (dispatch, getState) => {
    const newNestedContentElement =
      createEmptyNestedContentElement(nestedElement);

    dispatch(
      addToArrayAction({
        attributePath: `draftPage.content[${position}].${nestedFieldName}`,
        value: newNestedContentElement,
        pos: nestedPosition,
      })
    );
  };

export const cmsResetDraftPageContentToDefaultLocaleThunk =
  (pageId: number, locale: string): ReduxThunkAction =>
  async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction());
    const response = await getPageContentRequest(pageId, locale);
    dispatch(hideLoadingOverlayAction());
    if (!response.success) {
      return Promise.reject();
    }
    dispatch(
      updateAttributeAction({
        attributePath: `draftPage.content`,
        value: response.data,
      })
    );

    return Promise.resolve();
  };

export const cmsResetDraftPageToPublishedThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction(cmsTranslate("resettingToPublished")));

    const draftPage = getState().cmsEdit.data?.draftPage;
    if (!draftPage?.refId) {
      return Promise.reject();
    }

    dispatch(
      updateAttributeAction({
        attributePath: `draftPage.content`,
        value: [],
      })
    );

    const response = await getPageContentRequest(
      draftPage.refId,
      draftPage.locale
    );
    dispatch(hideLoadingOverlayAction());
    if (!response.success) {
      return Promise.reject();
    }
    dispatch(
      updateAttributeAction({
        attributePath: `draftPage.content`,
        value: response.data,
      })
    );
    return Promise.resolve();
  };
