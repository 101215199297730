import { RICHTEXT_CONTENT_SELECTOR } from "@/utils/constants";
import clsx from "clsx";
import PbRichTextViewGlobalStyle from "./pbRichTextViewGlobalStyle";

interface PbRichTextViewProps {
  /**
   * The html string
   */
  htmlString: string;
  /**
   * "cesstr-richtext-{id}" where {id} is the id of a contentElementSetting
   */
  className: string;
}

/**
 * RichTextView
 *
 * The styled view for showing content from the rich text editor
 */
export default function PbRichTextView(props: PbRichTextViewProps) {
  return (
    <>
      <div
        className={clsx(
          "richtext-view",
          RICHTEXT_CONTENT_SELECTOR,
          props.className
        )}
        dangerouslySetInnerHTML={{
          __html: props.htmlString,
        }}
      ></div>
      <PbRichTextViewGlobalStyle />
    </>
  );
}
