import { useAppDispatch } from "@/store/store";
import useReduxClipboard from "@/hooks/useReduxClipboard";
import { showMediaManagerModalAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { MediaManagerFilterOption } from "@/components/cms/cmsMediaManager/cmsMediaManagerFilter";
import { rootFolder } from "@/services/cmsMediaManager/cmsMediaManagerService";

export default function useCmsMediaManagerSelector(
  confirm?: Function,
  cancel?: Function
) {
  const dispatch = useAppDispatch();
  const { setClipboardPending, clipboardData } = useReduxClipboard({
    confirmSelectionFunction: () => confirm && confirm(clipboardData),
    cancelSelectionFunction: () => cancel && cancel,
  });

  const openMediaManagerSelector = async (
    mimeTypes?: Array<MediaManagerFilterOption>
  ) => {
    setClipboardPending(true);
    const folder = await rootFolder();
    dispatch(
      showMediaManagerModalAction({
        itemSelection: !!confirm,
        mediaTypes: mimeTypes,
        currentFolder: folder ?? undefined,
        rootFolder: folder ?? undefined,
      })
    );
  };

  return { openMediaManagerSelector };
}
