import { useAppSelector } from "@/store/store";
import { updateLanguageCookie } from "@/utils/localizationUtil";
import { isSSR } from "@/utils/util";
import { useRouter } from "next/router";
import { useEffect } from "react";

/**
 * updates the language cookie to the visited page language
 */
function useLanguageCookie() {
  const router = useRouter();
  const isPageVisible = useAppSelector((state) => state.general.isPageVisible);

  useEffect(() => {
    if (!isSSR() && router && router.locale) {
      updateLanguageCookie(router.locale);
    }
  }, [router]);

  // this useEffects syncs the language cookie if you are 
  // re visiting the browser tab.
  // update the language cookie to the language selected in
  // the current tab/page
  useEffect(() => {
    if (document && isPageVisible && router.locale) {
      updateLanguageCookie(router.locale);
    }
  }, [isPageVisible, router]);
}

export default useLanguageCookie;
