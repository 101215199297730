import PbButton from "@/components/input/pbButton/pbButton";
import PbFileInput from "@/components/input/pbFileInput/pbFileInput";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { mediaManagerModalNewFolderAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch } from "@/store/store";

interface CmsMediaManagerDetailActionButtonsProps {
  onNewFileChange: (e: any, file: any) => void;
}

export default function CmsMediaManagerDetailActionButtons(
  props: CmsMediaManagerDetailActionButtonsProps
) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  return (
    <div style={{ display: "flex" }}>
      <div>
        <PbFileInput
          onChange={(e, file) => props.onNewFileChange(e, file)}
          resetOnClick
        />
      </div>
      <PbButton
        onClick={() => dispatch(mediaManagerModalNewFolderAction())}
        startIcon={
          <PbIcon
            name="folder-light"
            className="svg-fill-white"
            height={20}
            width={20}
          />
        }
      >
        {tCms("managedFolderCreate")}
      </PbButton>
    </div>
  );
}
