import { determineStoreValueId } from "@/services/ceSettings/ceSettingsService";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import { STORE_COLOR_CLASS_PREFIX } from "@/utils/constants";

export default function useStrColor(
  colorStoreSetting?: StoreSetting,
  ceSettings?: StoreSetting,
  fallback?: string
) {
  const colorId = determineStoreValueId(colorStoreSetting, ceSettings);

  const fallbackValue = fallback ? fallback : "";

  const colorClassName = colorId
    ? `${STORE_COLOR_CLASS_PREFIX}${colorId}`
    : fallbackValue;
  const colorCssVar = colorId
    ? `var(--${STORE_COLOR_CLASS_PREFIX}${colorId})`
    : fallbackValue;

  return { colorClassName, colorCssVar };
}
