import { AxiosResponse } from "axios";

export default class ResponseResult<T> {
  success: boolean;
  data: T;
  response?: AxiosResponse<any, any>;
  error?: any;
  additionalInfo?: any;

  constructor(
    data: T,
    response: AxiosResponse<any, any>,
    success: boolean = true,
    error?: any,
    additionalInfo?: any
  ) {
    this.success = success;
    this.data = data;
    this.response = response;
    this.error = error;
    this.additionalInfo = additionalInfo;
  }

  static fromError<T>(
    error: any,
    response?: any,
    additionalInfo?: any
  ): ResponseResult<T> {
    return new ResponseResult<T>(error, response, false, error, additionalInfo);
  }
}
