import PbAutoComplete from "@/components/input/pbAutoComplete/pbAutoComplete";
import LoadingText from "@/components/loadingText/loadingText";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useIsMounted from "@/hooks/useIsMounted";
import { getPropertyDataProviderOptions } from "@/services/cmsPropertyService/cmsPropertyService";
import {
  CmsPropertyAttributeDataProvider,
  CmsPropertyAttributeGroup,
} from "@/types/strapi";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import CmsTooltip from "../cmsTooltip/cmsTooltip";

export interface CmsPropertyDataProviderProps {
  attributeGroup: CmsPropertyAttributeGroup;
  attribute: CmsPropertyAttributeDataProvider;
  currentValue: any;
  isNonLocalizedDisabled?: boolean;
  onChange: (
    attributeGroupName: string,
    attributeName: string,
    value: any
  ) => void;
}

export default function CmsPropertyDataProvider(
  props: CmsPropertyDataProviderProps
) {
  const isMounted = useIsMounted();
  const [selectOptions, setSelectOptions] = useState<Array<any> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [relationNotFound, setRelationNotFound] = useState<boolean>(false);
  const tCms = useCmsTranslation();

  useEffect(() => {
    const checkForMissingRelations = (options: Array<any>) => {
      if (!props.currentValue) {
        return null;
      }

      if (Array.isArray(props.currentValue)) {
        const values = props.currentValue
          .map((valueElement) => {
            const selectedOption = options?.find(
              (selectOption) =>
                selectOption[props.attribute.valueAttribute] ===
                valueElement[props.attribute.valueAttribute]
            );
            if (selectedOption) {
              return selectedOption;
            }
            return null;
          })
          .filter((selectedOption) => selectedOption !== null);

        if (values.length !== props.currentValue.length) {
          setRelationNotFound(true);
        }
      } else {
        const selectedOption = options?.find(
          (selectOption) =>
            selectOption[props.attribute.valueAttribute] ===
            props.currentValue[props.attribute.valueAttribute]
        );
        if (!selectedOption) {
          setRelationNotFound(true);
        }
      }
    };

    const getSelectOptions = async () => {
      const options = await getPropertyDataProviderOptions(props.attribute.url);
      if (isMounted()) {
        checkForMissingRelations(options);
        setSelectOptions(options);
        setIsLoading(false);
      }
    };

    if (selectOptions === null) {
      setIsLoading(true);
      getSelectOptions();
    }
  }, [
    isMounted,
    props.attribute.url,
    props.currentValue,
    props.attribute.valueAttribute,
    selectOptions,
  ]);

  /**
   * convert saved value back to objects that are in the selectOptions.
   *
   * @param value
   * @returns
   */
  const convertValueToSelectValue = (value: Array<any> | any | null) => {
    if (!value) {
      return null;
    }

    if (Array.isArray(value)) {
      const values = value
        .map((valueElement) => {
          const selectedOption = selectOptions?.find(
            (selectOption) =>
              selectOption[props.attribute.valueAttribute] ===
              valueElement[props.attribute.valueAttribute]
          );
          if (selectedOption) {
            return selectedOption;
          }
          return null;
        })
        .filter((selectedOption) => selectedOption !== null);
      return values;
    } else {
      const selectedOption = selectOptions?.find(
        (selectOption) =>
          selectOption[props.attribute.valueAttribute] ===
          value[props.attribute.valueAttribute]
      );
      return selectedOption ?? null;
    }
  };

  return (
    <>
      {selectOptions !== null ? (
        <PbAutoComplete
          disablePortal={false}
          withCmsStyle
          fullWidth
          multiple={props.attribute.multiple}
          options={selectOptions}
          defaultValue={convertValueToSelectValue(props.currentValue) ?? null}
          getOptionLabel={(option) =>
            `${
              option[props.attribute.labelAttribute] ??
              option[props.attribute.valueAttribute]
            }`
          }
          id={props.attribute.name}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={props.currentValue ? "" : tCms("choose-option")}
              margin="none"
            />
          )}
          onChange={(e, value) => {
            props.onChange(
              props.attributeGroup.name,
              props.attribute.name,
              value
            );
            setRelationNotFound(false);
          }}
        />
      ) : (
        <>{isLoading && <LoadingText />}</>
      )}
      {relationNotFound && (
        <div className="d-flex">
          <div>
            <CmsTooltip
              iconName="circle-exclamation-light"
              iconWidth={20}
              iconHeight={20}
              title={tCms("property-dataProviderOptionNotFoundDescription")}
              iconClassName="svg-fill-red"
            />
          </div>
          <div className="my-auto">
            <p className="cms-modal-text">
              {tCms("property-dataProviderOptionNotFound")}
            </p>
          </div>
        </div>
      )}
    </>
  );
}
