import { PbIcon } from "../pbIcon/PbIcon";

export interface FilePlaceholderIconProps {
  /**
   * The mime type of the file to display the appropriate icon.
   */
  mimeType: string;
  /**
   * Additional class names to apply to the SVG.
   */
  className?: string;

  /**
   * The width of the SVG. Defaults to 16 if not provided.
   */
  width?: number;

  /**
   * The height of the SVG. Defaults to 16 if not provided.
   */
  height?: number;

  /**
   * Additional styles to apply to the SVG.
   */
  style?: React.CSSProperties;
}

/**
 * Represents a component for displaying a placeholder icon based on the file's mime type.
 *
 * @component
 * @param {FilePlaceholderIconProps} props - The properties for configuring the component.
 * @returns {React.ReactElement} The React element representing the placeholder icon.
 */
const FilePlaceholderIcon = (
  props: FilePlaceholderIconProps
): React.ReactElement => {
  if (!props.mimeType) {
    return (
      <PbIcon
        name="file-lines-light"
        className={props.className}
        width={props.width}
        height={props.height}
        style={props.style}
      />
    );
  }

  if (props.mimeType.includes("video")) {
    return (
      <PbIcon
        name="file-video-light"
        className={props.className}
        width={props.width}
        height={props.height}
        style={props.style}
      />
    );
  }

  if (props.mimeType.includes("audio")) {
    return (
      <PbIcon
        name="file-audio-light"
        className={props.className}
        width={props.width}
        height={props.height}
        style={props.style}
      />
    );
  }
  if (
    props.mimeType.includes("application") &&
    props.mimeType.includes("pdf")
  ) {
    return (
      <PbIcon
        name="file-pdf-light"
        className={props.className}
        width={props.width}
        height={props.height}
        style={props.style}
      />
    );
  }
  if (
    props.mimeType.includes("application") &&
    props.mimeType.includes("sheet")
  ) {
    return (
      <PbIcon
        name="file-spreadsheet-light"
        className={props.className}
        width={props.width}
        height={props.height}
        style={props.style}
      />
    );
  }
  if (
    props.mimeType.includes("application") &&
    props.mimeType.includes("document")
  ) {
    return (
      <PbIcon
        name="file-doc-light"
        className={props.className}
        width={props.width}
        height={props.height}
        style={props.style}
      />
    );
  }
  return (
    <PbIcon
      name="file-lines-light"
      className={props.className}
      width={props.width}
      height={props.height}
      style={props.style}
    />
  );
};

export default FilePlaceholderIcon;
