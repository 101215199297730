import useStrSpaceX from "@/hooks/useStrSpaceX";
import useStrSpaceY from "@/hooks/useStrSpaceY";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { lowerBreakpointDesktopPx, lowerBreakpointWqhdPx } from "@/utils/util";

export default function NavigationStyle() {
  const spaceX: number =
    useStrSpaceX(globalConfig?.navigation?.spaceX, undefined) ?? 0;
  const spaceY: number =
    useStrSpaceY(globalConfig?.navigation?.spaceY, undefined) ?? 0;

  const scalingFactorSpaceY: number =
    globalConfig?.layout?.scalingfactorSpaceY.wqhd ?? 1;
  const scalingFactorSpaceX: number =
    globalConfig?.layout.scalingfactorSpaceX.wqhd ?? 1;

  const calculateWQHDSpaceY = spaceY * scalingFactorSpaceY + "px";
  const calculateWQHDSpaceX = spaceX * scalingFactorSpaceX + "px";

  return (
    <style jsx global>{`
      .navigation {
        padding: 10px;
        .MuiContainer-root {
          position: unset;
          padding: 0;
          .MuiToolbar-root {
            min-height: 0;
          }
        }
        @media (min-width: ${lowerBreakpointDesktopPx}) {
          padding: ${spaceY}px ${spaceX}px;
        }
        @media (min-width: ${lowerBreakpointWqhdPx}) {
          padding: ${calculateWQHDSpaceY} ${calculateWQHDSpaceX};
        }
      }
    `}</style>
  );
}
