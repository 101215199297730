import { ReduxThunkAction } from "@/store/store";
import {
  hideLoadingOverlayAction,
  redirectStartAction
} from "./generalSlice";

/**
 * redirect function that can be dispatched from everywhere
 *
 * @param {*} redirectFunction mandatory e.g.: () => router.push("/")
 * @param {*} redirectUrl the redirect url is just for logging purpose
 *      in redux devtools you it is easier to see where this redirect action
 *      redirects to
 * @param {*} hideOverlay optional
 * @param {*} deferTime optional default is 500ms
 * @returns
 */
export const redirectThunk =
  (
    redirectFunction: Function,
    redirectUrl?: string,
    hideOverlay?: boolean,
    deferTime?: number
  ): ReduxThunkAction =>
  (dispatch, getState) => {
    dispatch(redirectStartAction(redirectUrl ?? ""));
    setTimeout(
      () => {
        redirectFunction();
        if (hideOverlay) {
          dispatch(hideLoadingOverlayAction());
        }
      },
      deferTime ? deferTime : 500
    );
  };