import { PbIcon } from "@/components/pbIcon/PbIcon";
import { SvgIds } from "@/components/pbIcon/svg";
import withStyle from "@/middleware/withStyle";
import { SelectChangeEvent } from "@mui/material";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { useState } from "react";
import PbInputLabel from "../pbInputLabel/pbInputLabel";

const MenuItem = dynamic(() => import("@mui/material/MenuItem"));
const Select = dynamic(() => import("@mui/material/Select"));

export interface PbDropdownProps {
  dropdownList: Array<PbDropdownItem>;
  defaultValue?: string;
  value?: string;
  onChange: (event: SelectChangeEvent<unknown>) => void;
  itemNameKey: keyof PbDropdownItem;
  itemValueKey: keyof PbDropdownItem;
  emptyValue?: string;
  disableEmptyValue?: boolean;
  name?: string;
  id?: string;
  disabled?: boolean;
  className?: string;
  label?: string;
  mandatory?: boolean;

  /**
   * Toggles usage of CmsThemeProvider and sets a class
   * @see withStyle
   */
  withCmsStyle?: boolean;

  /**
   * Toggles usage of PublicThemeProvider and sets a class
   */
  withPublicStyle?: boolean;
}

export interface PbDropdownItem {
  name: string;
  value: string;
  iconName?: SvgIds;
  iconStyle?: any;
}

const PbDropdown = (props: PbDropdownProps) => {
  const isControlled = typeof props.value !== "undefined";
  const hasDefaultValue = typeof props.defaultValue !== "undefined";

  const [internalValue, setInternalValue] = useState(
    hasDefaultValue ? props.defaultValue : ""
  );

  const value = isControlled ? props.value : internalValue;

  const onChange = (e: SelectChangeEvent<unknown>) => {
    if (props.onChange) {
      props.onChange(e);
    }
    if (!isControlled) {
      setInternalValue(`${e.target.value}`);
    }
  };

  /**
   * get the value of the dropdown item. the value can be
   * different in dropdownStyled use cases
   * @param {*} item
   * @returns
   */
  const getValue = (item: PbDropdownItem): string | undefined => {
    if (props.itemValueKey && item[props.itemValueKey]) {
      return item[props.itemValueKey];
    } else if (item.value) {
      return item.value;
    } else if (typeof item.value === "number" && item.value === 0) {
      return item.value;
    }
    return undefined;
  };

  return (
    <>
      {props.label && (
        <PbInputLabel label={props.label} mandatory={props.mandatory} />
      )}
      <Select
        name={props.name}
        id={props.id}
        value={value}
        onChange={(e: SelectChangeEvent<unknown>) => onChange(e)}
        fullWidth
        displayEmpty
        className={clsx(
          "pb-mui-select",
          props.className,
          props.withCmsStyle && "with-cms-style",
          props.withPublicStyle && "with-public-style"
        )}
        disabled={props.disabled}
        variant="outlined"
        MenuProps={{
          disablePortal: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          marginThreshold: 0,
        }}
      >
        {props.emptyValue && (
          <MenuItem
            disabled={props.disableEmptyValue}
            className="dropdown-style-menu-item"
            value=""
          >
            <em>{props.emptyValue}</em>
          </MenuItem>
        )}
        {props.dropdownList &&
          props.dropdownList.map((item, index) => {
            return (
              <MenuItem
                className="dropdown-style-menu-item"
                key={index}
                value={getValue(item)}
              >
                {item.iconName && (
                  <PbIcon
                    style={{
                      marginRight: "0.5rem",
                      marginTop: "-3px",
                      ...item.iconStyle,
                    }}
                    name={item.iconName}
                    width={15}
                  />
                )}
                {item[props.itemNameKey] || item.name || item.value}
              </MenuItem>
            );
          })}
      </Select>
    </>
  );
};

export default withStyle(PbDropdown);
