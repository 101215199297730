import {
  createCategoryRequest,
  deleteCategoryRequest,
  updateCategoryRequest,
} from "@/services/categoryService/categoryService";
import {
  hideLoadingOverlayAction,
  showLoadingOverlayAction,
} from "@/store/slices/general/generalSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { PbCategory } from "@/types/strapi";
import { getDefaultLocale } from "@/utils/localizationUtil";
import { triggerGetServerSideProps } from "@/utils/util";
import { createToastError } from "@/utils/utilComponents";
import { useRouter } from "next/router";
import { useState } from "react";
import useCmsTranslation from "./useCmsTranslation";

/**
 * Provides create, update, delete functionality for categories
 */
export default function useCategory() {
  const nextLocales = useAppSelector(
    (state) => state.cmsGeneral.localesStatus.nextLocales
  );
  const defaultLocale = getDefaultLocale();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const tCms = useCmsTranslation();

  const getCategoryPlaceholder = () => {
    const categoryPlaceholder = {
      localizedName: {
        [defaultLocale]: "",
      },
    };
    nextLocales.forEach((locale) => {
      if (locale !== defaultLocale) {
        categoryPlaceholder.localizedName = {
          ...categoryPlaceholder.localizedName,
          [locale]: "",
        };
      }
    });
    return categoryPlaceholder;
  };

  const [category, setCategory] = useState<PbCategory>(() =>
    getCategoryPlaceholder()
  );

  const [showModal, setShowModal] = useState(false);

  const fillEmptyCategoryNames = () => {
    const fullCategory = { ...category };
    Object.keys(fullCategory.localizedName).forEach((key) => {
      if (!fullCategory.localizedName[key]) {
        fullCategory.localizedName[key] = category.localizedName[defaultLocale];
      }
    });
    setCategory(fullCategory);
  };

  const onCategoryChange = (locale: string, value: string) => {
    setCategory((prevState: PbCategory) => ({
      ...prevState,
      localizedName: {
        ...prevState.localizedName,
        [locale]: value,
      },
    }));
  };

  const onHideModal = () => {
    setShowModal(false);
    setCategory(getCategoryPlaceholder());
  };

  const openModal = () => {
    setShowModal(true);
  };

  const editCategory = (category: PbCategory) => {
    setCategory(category);
    setShowModal(true);
  };

  const handleCategory = async (
    type: "create" | "update" | "delete",
    id?: number
  ) => {
    setShowModal(false);
    dispatch(showLoadingOverlayAction());
    fillEmptyCategoryNames();
    let result = null;
    if (type === "create") {
      result = await createCategoryRequest(category);
    }
    if (type === "update") {
      result = await updateCategoryRequest(category);
    }
    if (type === "delete") {
      if (id) {
        result = await deleteCategoryRequest(id);
      }
    }
    if (result?.success) {
      triggerGetServerSideProps(router);
    } else {
      createToastError(
        tCms(result?.data.response.data.message ?? "genericError")
      );
    }
    dispatch(hideLoadingOverlayAction());
    setCategory(getCategoryPlaceholder());
  };

  return {
    category: category,
    showModal: showModal,
    openModal: openModal,
    onCategoryChange: onCategoryChange,
    onHideModal: onHideModal,
    handleCategory: handleCategory,
    editCategory: editCategory,
  };
}
