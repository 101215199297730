import ResponseResult from "@/types/classes/ResponseResult";
import { getNextJsApiURL } from "@/utils/api";
import { axiosGetRequestClientSide } from "@/utils/axiosClientUtil";

export const getAllCmsPages = async (): Promise<Array<any>> => {
  const allCmsPagesResult: ResponseResult<any> =
    await axiosGetRequestClientSide(
      getNextJsApiURL(
        `/cms/manage/pabu/pages?locale=${process.env
          .NEXT_PUBLIC_DEFAULT_LOCALE!}`
      )
    );

  if (!allCmsPagesResult.success) {
    global.log.warn("[getAllCmsPages] could not get all cms pages");
    return [];
  }

  return allCmsPagesResult.data;
};

export const getAllCmsPageTypes = async (): Promise<Array<any>> => {
  const allCmsPageTypesResult: ResponseResult<any> =
    await axiosGetRequestClientSide(
      getNextJsApiURL(
        `/cms/manage/pabu/page-types?locale=${process.env
          .NEXT_PUBLIC_DEFAULT_LOCALE!}`
      )
    );

  if (!allCmsPageTypesResult.success) {
    global.log.warn("[getAllCmsPageTypes] could not get all cms page types");
    return [];
  }

  return allCmsPageTypesResult.data;
};