import { useAppSelector } from "@/store/store";
import {
  CONTEXT_CATEGORY,
  CONTEXT_FORM,
  CONTEXT_NAVIGATION,
  CONTEXT_PAGE,
} from "@/utils/constants";
import Box from "@mui/material/Box";
import CmsTopBarLeftControlsCategory from "./cmsTopBarLeftControlsCategory";
import CmsTopBarLeftControlsForm from "./cmsTopBarLeftControlsForm";
import CmsTopBarLeftControlsNavigation from "./cmsTopBarLeftControlsNavigation";
import CmsTopBarLeftControlsPage from "./cmsTopBarLeftControlsPage";

/**
 * Represents the props for the CmsTopBarProps component.
 */
export interface CmsTopBarLeftControlsProps {}

/**
 * CmsInputLabel component represents a label for an input field.
 * @param props - The component properties.
 * @returns A JSX element representing the CmsInputLabel.
 */
const CmsTopBarLeftControls = (
  props: CmsTopBarLeftControlsProps
): JSX.Element => {
  const currentContext = useAppSelector(
    (state) => state.cmsGeneral.currentContext
  );

  return (
    <Box sx={{ display: { xs: "none", sm: "flex" }, flexGrow: 1 }}>
      {currentContext === CONTEXT_FORM && <CmsTopBarLeftControlsForm />}
      {currentContext === CONTEXT_NAVIGATION ? (
        <CmsTopBarLeftControlsNavigation />
      ) : null}
      {currentContext === CONTEXT_CATEGORY && <CmsTopBarLeftControlsCategory />}
      {currentContext === CONTEXT_PAGE ? <CmsTopBarLeftControlsPage /> : null}
    </Box>
  );
};

export default CmsTopBarLeftControls;
