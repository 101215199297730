import { StrapiCmsUser, StrapiLoginResponse } from "@/types/strapi";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import {
  authenticateCmsUserThunk,
  refreshCmsUserTokenThunk,
} from "./cmsUserThunks";

export interface CmsUserState {
  isCmsUserAuthenticated: boolean;
  showUserCfgModal: boolean;
  user: StrapiCmsUser;
  tokenExpDate: Date | null;
  renewTokenDate: Date | null;
  cmsUserSettings: CmsUserSettings | null;
}

export interface CmsUserSettings {
  id: number;
  userId: number;
  sideMenu: boolean;
}

export const initialState: CmsUserState = {
  isCmsUserAuthenticated: false,
  showUserCfgModal: false,
  tokenExpDate: null,
  renewTokenDate: null,
  user: {
    id: 0,
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    isActive: false,
    blocked: false,
    preferedLanguage: null,
    createdAt: "",
    updatedAt: "",
    roles: [],
  },
  cmsUserSettings: null,
};

export const cmsUserSlice = createSlice({
  name: "cmsUser",
  initialState,
  reducers: {
    logoutAction: (state) => {
      state.isCmsUserAuthenticated = false;
      state.showUserCfgModal = false;
      state.user = {
        id: 0,
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        isActive: false,
        blocked: false,
        preferedLanguage: null,
        createdAt: "",
        updatedAt: "",
        roles: [],
      };
    },
    updateCmsUserSettingsAction: (state, action) => {
      state.cmsUserSettings = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        authenticateCmsUserThunk.fulfilled,
        (state, action: PayloadAction<StrapiLoginResponse>) => {
          state.isCmsUserAuthenticated = true;
          state.user = action.payload.currentCmsUser;
          state.tokenExpDate = action.payload.expireDate;
          state.renewTokenDate = action.payload.refreshDate;
        }
      )
      .addCase(authenticateCmsUserThunk.rejected, (state, action) => {
        state.isCmsUserAuthenticated = false;
        state.user = {
          id: 0,
          firstname: "",
          lastname: "",
          username: "",
          email: "",
          isActive: false,
          blocked: false,
          preferedLanguage: null,
          createdAt: "",
          updatedAt: "",
          roles: [],
        };
      })
      .addCase(
        refreshCmsUserTokenThunk.fulfilled,
        (state, action: PayloadAction<StrapiLoginResponse>) => {
          state.tokenExpDate = action.payload.expireDate;
          state.renewTokenDate = action.payload.refreshDate;
        }
      );
  },
});

export const { logoutAction, updateCmsUserSettingsAction } =
  cmsUserSlice.actions;
