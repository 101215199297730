import { PbIcon } from "@/components/pbIcon/PbIcon";
import { useAppSelector } from "@/store/store";
import { ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import clsx from "clsx";
import { useState } from "react";
import PbButtonStyle from "./pbButtonStyle";

/**
 * Represents the props for the PbInput component.
 */
export interface PbButtonProps extends ButtonProps {
  /**
   * below this width in px the text of the button will be hidden
   */
  showOnlyIconBelowWidth?: number;

  /**
   * displays the button only in editView
   */
  onlyVisibleInEditView?: boolean;

  /**
   * roundedBg button (icon button would be a circle then)
   */
  rounded?: boolean;

  /**
   * background color and background color hover effect is set to transparent
   * instead an opacity change is done on hover
   */
  noBg?: boolean;

  /**
   * loading indicator on the button
   */
  isLoading?: boolean;

  /**
   * Overrides the default btn loading icon width. If you have a specific width set for the
   * start icon, ensure to use the same width here. This helps prevent any visual
   * inconsistency or "jumping" of the button in the CSS layout while toggling the loading state.
   */
  loadingIconWidth?: number;

  /**
   * Overrides the default btn loading icon height. If you have a specific height set for the
   * start icon, ensure to use the same height here. This helps prevent any visual
   * inconsistency or "jumping" of the button in the CSS layout while toggling the loading state.
   */
  loadingIconHeight?: number;

  /**
   * className for the loading icon
   */
  loadingIconClassName?: string;

  /**
   * Toggles the btn loading spinner and disables the button for a specified duration.
   * Use this function in scenarios where there's no explicit success/fail callback for
   * your button action. Specify a reasonable time to prevent multiple clicks, providing
   * feedback to the user about the ongoing process.
   *
   * If you have a callback for your button action, you can manage this behavior using
   * the isLoading prop.
   */
  loadOnClickTimerMS?: number;
}

/**
 * PbButton component
 * This should be the only button component next to the
 * PbContent button component
 *
 * @param props - The component properties.
 * @returns A JSX element representing the PbButton.
 */
const PbButton = (props: PbButtonProps) => {
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const [temporaryLoad, setTemporaryLoad] = useState(false);

  // extracting all props that are not known by the mui button to
  // prevent setting non mui button props to the original mui button
  const {
    showOnlyIconBelowWidth,
    onlyVisibleInEditView,
    rounded,
    noBg,
    isLoading,
    className,
    onClick,
    loadOnClickTimerMS,
    loadingIconWidth,
    loadingIconHeight,
    loadingIconClassName,
    ...muiButtonProps
  } = props;

  const showOnlyIconViewPort = useMediaQuery(
    `(max-width:${showOnlyIconBelowWidth || 600}px)`
  );

  if (onlyVisibleInEditView && !editView) {
    return null;
  }

  const theme = createTheme({
    palette: {
      secondary: { main: "#333333", contrastText: "#FFFFFF" },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Button
          {...muiButtonProps}
          className={clsx(
            "cms-default-button cms-default-button-mui",
            props.startIcon &&
              (showOnlyIconViewPort || !props.children) &&
              "no-text",
            className,
            rounded && "rounded-bg",
            noBg && "no-bg"
          )}
          sx={{ ...props.sx }}
          startIcon={
            isLoading || temporaryLoad ? (
              <PbIcon
                name="loader-light"
                width={loadingIconWidth ? loadingIconWidth : 15}
                height={loadingIconHeight ? loadingIconHeight : 15}
                className={
                  loadingIconClassName
                    ? loadingIconClassName
                    : "svg-fill-white rotate"
                }
              />
            ) : (
              props.startIcon
            )
          }
          disabled={isLoading || props.disabled || temporaryLoad}
          onClick={(e) => {
            if (loadOnClickTimerMS) {
              setTemporaryLoad(true);
              setTimeout(() => setTemporaryLoad(false), loadOnClickTimerMS);
            }
            if (props.onClick) {
              props.onClick(e);
            }
          }}
        >
          {props.startIcon && showOnlyIconViewPort ? "" : props.children}
        </Button>
      </ThemeProvider>
      <PbButtonStyle />
    </>
  );
};

export default PbButton;
