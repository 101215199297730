import PbDropdown from "@/components/input/pbDropdown/pbDropdown";
import useAvailableLocales from "@/hooks/useAvailableLocales";
import { updateAttributeAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch } from "@/store/store";
import { useRouter } from "next/router";
import { useState } from "react";

export default function CmsMediaManagerLanguageSelector() {
  const availableLocales = useAvailableLocales();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(
    router.locale
  );
  return (
    <PbDropdown
      withCmsStyle
      dropdownList={availableLocales}
      onChange={(e) => {
        dispatch(
          updateAttributeAction({
            attributePath: "mediaManagerModal.locale",
            value: e.target.value,
          })
        );
        setSelectedLanguage(e.target.value as string);
      }}
      value={selectedLanguage}
      itemNameKey={"name"}
      itemValueKey={"value"}
    ></PbDropdown>
  );
}
