/* Instruments */
import { cmsEditSlice } from "./slices/cmsEdit/cmsEditSlice";
import { cmsGeneralSlice } from "./slices/cmsGeneral/cmsGeneralSlice";
import { cmsUserSlice } from "./slices/cmsUser/cmsUserSlice";
import { generalSlice } from "./slices/general/generalSlice";

export const reducer = {
  [generalSlice.name]: generalSlice.reducer,
  [cmsUserSlice.name]: cmsUserSlice.reducer,
  [cmsEditSlice.name]: cmsEditSlice.reducer,
  [cmsGeneralSlice.name]: cmsGeneralSlice.reducer,
};
