import dynamic from "next/dynamic";

// The only purpose of this wrapper is to prevent using dynamic imports in richTextEditor
// Otherwise it seems impossible to get named exports correctly from quill
// Also quill is not SSR compatible and this forces to only do CSR
const PbRichTextEditor = dynamic(() => import("./richTextEditor"), {
  ssr: false,
});

PbRichTextEditor.displayName = "PbRichTextEditor";

export default PbRichTextEditor;
