import PbDropdown from "@/components/input/pbDropdown/pbDropdown";
import useAvailableLocales from "@/hooks/useAvailableLocales";
import { useAppSelector } from "@/store/store";
import { changeLanguage } from "@/utils/localizationUtil";
import { useRouter } from "next/router";
import LanguageSelectorStyle from "./languageSelectorStyle";

export default function LanguageSelector() {
  const router = useRouter();
  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);
  const availableLocales = useAvailableLocales();
  const localesInSync = useAppSelector(
    (state) => state.cmsGeneral.localesStatus.localesInSync
  );
  return (
    <>
      <PbDropdown
        withPublicStyle
        className="language-selector"
        disabled={editMode || !localesInSync}
        dropdownList={availableLocales}
        onChange={(e) => changeLanguage(e.target.value as string, router)}
        value={router.locale}
        itemNameKey={"name"}
        itemValueKey={"value"}
      ></PbDropdown>
      <LanguageSelectorStyle />
    </>
  );
}
