import { PbPageType } from "@/types/strapi";
import { getLocalizedValue } from "@/utils/localizationUtil";

/**
 * returns the localized pagetype name with the
 * context path if it exists
 *
 * @param pageType
 * @param locale
 * @returns
 */
export const getPageTypeNameLocalized = (
  pageType: PbPageType,
  locale: string,
  showContextPath?: boolean
): string | null => {
  let pageTypeName = getLocalizedValue(pageType?.localizedName, locale);
  if (pageTypeName) {
    pageTypeName = pageTypeName;
  } else {
    pageTypeName = pageType.name;
  }

  if (showContextPath && pageType.contextPath) {
    pageTypeName = pageTypeName + ` (${pageType.contextPath})`;
  }

  return pageTypeName;
};
