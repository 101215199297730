import { useAppSelector } from "@/store/store";
import LoadingSpinnerStyle from "./loadingSpinnerStyle";

export interface LoadingSpinnerProps {
  hideWhileFullScreenOverlayIsVisible?: boolean;
}

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const isFullScreenOverlayVisible = useAppSelector(
    (state) => state.general.loadingOverlay.visible
  );
  return (
    <>
      {props.hideWhileFullScreenOverlayIsVisible &&
      isFullScreenOverlayVisible ? (
        <></>
      ) : (
        <>
          <div className="loading-spinner"></div>
          <LoadingSpinnerStyle />
        </>
      )}
    </>
  );
};

export default LoadingSpinner;
