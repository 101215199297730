import ResponseResult from "@/types/classes/ResponseResult";
import { StrapiLoginResponse } from "@/types/strapi";
import { getNextJsApiURL, getStrapiURLClientSide } from "@/utils/api";
import { axiosPostRequestClientSide } from "@/utils/axiosClientUtil";

export const authenticateCmsUserRequest = async (
  email: string,
  password: string
): Promise<ResponseResult<StrapiLoginResponse>> => {
  const authResult: ResponseResult<StrapiLoginResponse> =
    await axiosPostRequestClientSide(getNextJsApiURL("/cms/auth/login"), {
      email,
      password,
    });

  return authResult;
};

export const forgotPasswordRequest = async (
  email: string
): Promise<ResponseResult<void>> => {
  return await axiosPostRequestClientSide(
    getNextJsApiURL("/cms/auth/forgot-password"),
    {
      email,
    }
  );
};

export const resetPasswordRequest = async (
  resetPasswordToken: string,
  password: string
): Promise<ResponseResult<{ email: string }>> => {
  return await axiosPostRequestClientSide(
    getNextJsApiURL("/cms/auth/reset-password"),
    {
      resetPasswordToken,
      password,
    }
  );
};

export const logoutCmsUserRequest = async (): Promise<ResponseResult<void>> => {
  const logoutResult: ResponseResult<void> = await axiosPostRequestClientSide(
    getNextJsApiURL("/cms/auth/logout"),
    {}
  );

  return logoutResult;
};

export const renewCmsUserTokenRequest = async (): Promise<
  ResponseResult<StrapiLoginResponse>
> => {
  const refreshResult: ResponseResult<StrapiLoginResponse> =
    await axiosPostRequestClientSide(
      getNextJsApiURL("/cms/auth/renew-token"),
      {}
    );

  return refreshResult;
};
