import { useEffect, useState } from "react";

/**
 * 
 * 
 * Example:
 * 
 * 
  const {debouncedValue, valueToDebounce: content, setValueToDebounce: setContent } =
    useStateDebounce(
      convertToHtmlText(props.content),
      300,
      (debouncedValue) => {
        props.onChange(debouncedValue);
      }
    );
 *
 * @param value 
 * @param delayMilliSeconds 
 * @param onDebounce 
 * @returns 
 */
export default function useStateDebounce(
  value: any,
  delayMilliSeconds: number,
  onDebounce: (debouncedValue: any) => void
) {
  /* This is the state that replaces the useState in the calling component */
  const [valueToDebounce, setValueToDebounce] = useState<any>(value);
  /* This is the state that checks if a debounce was done */
  const [debouncedValue, setDebouncedValue] = useState<any>(value);

  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
      if (debouncedValue !== valueToDebounce) {
        setDebouncedValue(valueToDebounce);
        onDebounce(valueToDebounce);
      }
    }, delayMilliSeconds);
    return () => {
      clearTimeout(timeoutHandler);
    };
  }, [debouncedValue, valueToDebounce, delayMilliSeconds, onDebounce]);

  return { debouncedValue, valueToDebounce, setValueToDebounce };
}
