import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { lowerBreakpointDesktopPx } from "@/utils/util";

export default function NavigationDesktopStyle() {
  const height = globalConfig?.navigation?.logoMaxHeight?.desktop ?? 0;
  return (
    <style jsx global>{`
      .navigation-desktop {
        display: none;

        @media (min-width: ${lowerBreakpointDesktopPx}) {
          display: ${globalConfig?.navigation.onlyMobileNavigation
            ? "none"
            : "flex"};
        }
        @media screen and (hover: none) {
          display: none;
        }

        align-items: center;
        gap: 1rem;
        flex: 1 1 100%;
        min-height: 50px;
        height: ${height > 0 ? height + "px" : "auto"};

        nav {
          display: flex;
          flex: 1 1 auto;
          justify-content: left;
          flex-wrap: wrap;
        }
        .navigation-desktop-action-group {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
      }
      .navigation-desktop > .navigation-search,
      .navigation-desktop.search > .navigation-desktop-logo,
      .navigation-desktop.search > nav,
      .navigation-desktop.search > .navigation-desktop-action-group {
        display: none;
      }

      .navigation-desktop.search {
        align-items: center;
        justify-content: center;
      }
      .navigation-desktop.search > .navigation-search {
        display: flex;
        flex: 1 0 auto;
        max-width: 80%;
      }
    `}</style>
  );
}
