const CmsLanguageSelectorStyle = (): JSX.Element => {
  return (
    <style jsx global>{`
      .cms-language-selector {
        .MuiInputBase-root {
          background-color: #2a3035 !important;
          color: white;

          svg {
            fill: white;
          }

          &.Mui-disabled {
            svg {
              fill: var(--pb-black);
            }
          }

          .MuiSelect-select.Mui-disabled:hover {
            cursor: not-allowed;
          }

          .MuiList-root {
            background-color: #2a3035 !important;
            color: white !important;

            .Mui-selected {
              background-color: #485361;
            }

            .MuiMenuItem-root:hover {
              background-color: #485361;
            }
          }

          fieldset {
            border-color: #2a3035;
          }

          .MuiOutlinedInput-notchedOutline {
            overflow: hidden;
          }
        }
      }
    `}</style>
  );
};

export default CmsLanguageSelectorStyle;
