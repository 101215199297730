import ResponseResult from "@/types/classes/ResponseResult";
import { StrapiUploadFile, StrapiUploadFolder } from "@/types/strapi";
import { getNextJsApiURL } from "@/utils/api";
import {
  axiosDeleteRequestClientSide,
  axiosGetRequestClientSide,
  axiosPostRequestClientSide,
  axiosPutRequestClientSide,
} from "@/utils/axiosClientUtil";

export interface MediaManagerCreateFolderRequest {
  name: string;
  description?: string;
  parentId: number | null;
}

export interface MediaManagerUpdateFolderRequest {
  name: string;
  description?: string;
}

export interface MediaManagerFetchResponse {
  files: StrapiUploadFile[];
  folders: StrapiUploadFolder[];
  parents: StrapiUploadFolder[];
}

export interface MediaManagerFileReferenceResponse {
  modelType: string;
  amount: number;
  description: string;
}

export interface MediaManagerFolderBulkDeleteResponse {
  folders: StrapiUploadFolder[];
  totalFileNumber: number;
  totalFolderNumber: number;
}

export const itemsByPath = async (
  folderPath: string,
  folder: number | undefined
): Promise<MediaManagerFetchResponse> => {
  let query = `?folderPath=${folderPath}`;
  if (folder) {
    query += `&folder=${folder}`;
  }
  const requestUrl = getNextJsApiURL("/cms/manage/pabu/files" + query);
  const result = await axiosGetRequestClientSide(requestUrl);
  if (!result.success) {
    return Promise.resolve({
      files: [],
      folders: [],
      parents: [],
    });
  }
  return Promise.resolve({
    ...result.data,
  });
};

export const searchFile = async (
  name: string | undefined
): Promise<StrapiUploadFile[]> => {
  const requestUrl = getNextJsApiURL(
    `/cms/manage/pabu/files/search?name=${name}`
  );
  const result = await axiosGetRequestClientSide(requestUrl);
  if (!result.success) {
    return [];
  }
  return result.data;
};

export const getFileById = async (id: number): Promise<any> => {
  const requestUrl = getNextJsApiURL(`/cms/manage/pabu/files/${id}`);
  const result: ResponseResult<StrapiUploadFile> =
    await axiosGetRequestClientSide(requestUrl);
  if (!result.success) {
    return Promise.reject();
  }
  return result.data;
};

export const createStrapiFolder = async (
  folderCreateRequest: MediaManagerCreateFolderRequest
): Promise<ResponseResult<StrapiUploadFolder>> => {
  return axiosPostRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/folders`),
    folderCreateRequest
  );
};

export const updateStrapiFolder = async (
  id: number,
  folderUpdateRequest: MediaManagerUpdateFolderRequest
): Promise<ResponseResult<StrapiUploadFolder>> => {
  return axiosPutRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/folders/${id}`),
    folderUpdateRequest
  );
};

export const deleteStrapiFolder = async (
  id: number
): Promise<ResponseResult<MediaManagerFolderBulkDeleteResponse>> => {
  return axiosPostRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/folders/bulk-delete`),
    {
      folderIds: [id],
    }
  );
};

export const updateStrapiFile = async (
  file: StrapiUploadFile
): Promise<ResponseResult<StrapiUploadFile>> => {
  return axiosPutRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/files/${file.id}`),
    {
      file,
    }
  );
};

export const deleteStrapiFile = async (
  fileId: number
): Promise<ResponseResult<StrapiUploadFile>> => {
  return axiosDeleteRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/files/${fileId}`)
  );
};

export const findStrapiFileReferences = async (
  file: StrapiUploadFile
): Promise<ResponseResult<MediaManagerFileReferenceResponse>> => {
  return axiosGetRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/files/references/${file.id}`)
  );
};

export const rootFolder = async (): Promise<StrapiUploadFolder | undefined> => {
  const requestUrl = getNextJsApiURL(`/cms/manage/pabu/media-manager-folder`);
  const result: ResponseResult<StrapiUploadFolder> =
    await axiosGetRequestClientSide(requestUrl);
  if (!result.success) {
    return Promise.resolve(undefined);
  }
  return Promise.resolve(result.data);
};

export const displayNameWithoutFileExt = (fileName: string) => {
  if (fileName.includes(".")) {
    return fileName.substring(0, fileName.lastIndexOf("."));
  }
  return fileName;
};

export const fileNameExt = (fileName: string) => {
  if (fileName.includes(".")) {
    return fileName.substring(fileName.lastIndexOf("."));
  }
  return fileName;
};
