export let customHeadScripts: string = "";
export let customBodyScripts: string = "";

export const setCustomHeadScripts = (cstmHeadScripts: string) => {
  if (cstmHeadScripts) {
    customHeadScripts = cstmHeadScripts;
  }
};

export const setCustomBodyScripts = (cstmBodyScripts: string) => {
  if (cstmBodyScripts) {
    customBodyScripts = cstmBodyScripts;
  }
};
