import { initialState as initialCmsEditState } from "./slices/cmsEdit/cmsEditSlice";
import { initialState as initialCmsGeneralState } from "./slices/cmsGeneral/cmsGeneralSlice";
import { initialState as initialCmsUserState } from "./slices/cmsUser/cmsUserSlice";
import { initialState as initialGeneralState } from "./slices/general/generalSlice";

export const emptyReduxState = {
  general: { ...initialGeneralState },
  cmsUser: { ...initialCmsUserState },
  cmsEdit: { ...initialCmsEditState },
  cmsGeneral: { ...initialCmsGeneralState },
};
