import CmsImage from "@/components/cms/cmsImage/cmsImage";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { StrapiUploadFile } from "@/types/strapi";
import { ROOT_PAGE_URL } from "@/utils/constants";
import Link from "next/link";

interface NavigationBrandProps {
  logo: StrapiUploadFile;
  url?: string;
  isMobile?: boolean;
  logoRatio?: number;
}
export default function NavigationBrand(props: NavigationBrandProps) {
  const tCms = useCmsTranslation();

  const maxHeightDesktop =
    globalConfig?.navigation?.logoMaxHeight?.desktop ?? 0;
  const maxHeightMobile = globalConfig?.navigation?.logoMaxHeight?.mobile ?? 0;
  const maxHeight = props.isMobile ? maxHeightMobile : maxHeightDesktop;

  const alt = props.url
    ? props.url === ROOT_PAGE_URL
      ? tCms("navigation-routeToHomepage")
      : tCms("navigation-routeToPage", { page: props.url })
    : "logo";

  return (
    <Link href={props.url ?? ROOT_PAGE_URL}>
      <CmsImage
        style={{
          height: maxHeight > 0 ? maxHeight : "auto",
          width: "auto",
        }}
        image={props.logo}
        objectFit="contain"
        alt={alt}
        format={"small"}
      />
    </Link>
  );
}
