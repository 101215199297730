import { storeColorCss } from "@/services/ceSettings/ceSettingsService";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { lowerBreakpointDesktopPx } from "@/utils/util";

export default function NavigationMobileStyle() {
  const height = globalConfig?.navigation?.logoMaxHeight?.mobile ?? 0;

  return (
    <style jsx global>{`
      @supports (-webkit-touch-callout: none) {
        body:has(.drawer-open) {
          position: fixed;
          width: 100%;
        }
      }

      .MuiPaper-root.navigation-mobile-menu {
        background-color: ${storeColorCss(
          globalConfig?.navigation.bgColor,
          "var(--pb-white)"
        )};
        height: 100%;
        width: ${globalConfig?.navigation.mobileNavDrawerWidth ?? "100%"};

        li {
          cursor: pointer;
        }

        .navigation-mobile-header {
          display: flex;
          align-items: center;
          padding: 8px 16px;

          .navigation-mobile-logo {
            flex: 1 0 auto;
          }

          button {
            padding: 0;
          }
        }
      }

      nav .navigation-item.active {
        color: ${storeColorCss(
          globalConfig?.navigation.linkActiveColor,
          "unset"
        )};
      }

      .navigation-mobile {
        display: flex;
        align-items: center;
        flex: 1 1 100%;
        min-height: 50px;
        height: ${height > 0 ? height + "px" : "auto"};

        .navigation-mobile-spacer {
          display: flex;
          flex: 1 0 auto;
        }

        .navigation-mobile-action-group {
          display: flex;
          align-items: center;
          gap: 1rem;
        }

        @media (min-width: ${lowerBreakpointDesktopPx}) {
          display: ${globalConfig?.navigation.onlyMobileNavigation
            ? "flex"
            : "none"};
        }

        @media screen and (hover: none) {
          display: flex;
        }
      }

      .navigation-mobile > .navigation-search,
      .navigation-mobile.search > .navigation-mobile-spacer,
      .navigation-mobile.search > .navigation-mobile-logo,
      .navigation-mobile.search > .navigation-mobile-action-group {
        display: none;
      }

      .navigation-mobile.search {
        align-items: center;
        justify-content: center;
      }

      .navigation-mobile.search > .navigation-search {
        display: flex;
        flex: 1 0 auto;
        max-width: 80%;
      }

      .navigation-item.level-2 {
        padding-left: 4rem;
      }
      .navigation-item.level-3 {
        padding-left: 6rem;
      }
    `}</style>
  );
}
