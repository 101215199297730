import { useAppDispatch, useAppSelector } from "@/store/store";
import { useEffect, useState } from "react";
import { resetClipBoardAction } from "@/store/slices/general/generalSlice";

interface useReduxClipboardProps {
  confirmSelectionFunction: Function;
  cancelSelectionFunction: Function;
}

export default function useReduxClipboard(props: useReduxClipboardProps) {
  const dispatch = useAppDispatch();
  const clipboard = useAppSelector((state) => state.general.clipboard);
  const [isClipboardSelectionPending, setClipboardSelectionPending] =
    useState(false);

  const setClipboardPending = (clipboardPending: boolean) => {
    dispatch(resetClipBoardAction());
    setClipboardSelectionPending(clipboardPending);
  };

  useEffect(() => {
    if (!isClipboardSelectionPending || !clipboard?.clipboardSet) {
      return;
    }

    if (props.confirmSelectionFunction && clipboard?.clipboardData) {
      props.confirmSelectionFunction();
    }

    if (props.cancelSelectionFunction && clipboard?.clipboardCanceled) {
      props.cancelSelectionFunction();
    }

    setClipboardSelectionPending(false);
  }, [
    dispatch,
    clipboard,
    props.cancelSelectionFunction,
    props.confirmSelectionFunction,
    isClipboardSelectionPending,
  ]);

  return {
    isClipboardSelectionPending,
    setClipboardPending,
    clipboardData: clipboard.clipboardData,
  };
}
