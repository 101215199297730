import withStyle from "@/middleware/withStyle";
import clsx from "clsx";
import { useState } from "react";
import PbInput from "../pbInput/pbInput";

/**
 * Represents the props for the PbTimeInput component.
 */
export interface PbTimeInputProps {
  /**
   * The default value for the time input. If not provided, "00:00" is used.
   */
  defaultValue?: Date;

  /**
   * The controlled value for the time input.
   */
  value?: string;

  /**
   * The label for the time input.
   */
  label?: string;

  /**
   * The callback function called when the value of the time input changes.
   * @param value - The new value of the time input.
   */
  onChange?: (value: any) => void;

  /**
   * Indicates whether an error state should be displayed.
   */
  error?: boolean;

  /**
   * disables the time input and renders a text disabled text input instead
   */
  disabled?: boolean;

  /**
   * placeholder that is displayed on the disabled input
   */
  disabledPlaceholder?: string;

  fullWidth?: boolean;

  /**
   * Toggles usage of CmsThemeProvider and sets a class
   * @see withStyle
   */
  withCmsStyle?: boolean;

  /**
   * Toggles usage of PublicThemeProvider and sets a class
   */
  withPublicStyle?: boolean;
}

/**
 * PbTimeInput component represents an input field for time.
 * @param props - The component properties.
 * @returns A JSX element representing the PbTimeInput.
 */
const PbTimeInput = (props: PbTimeInputProps) => {
  // if defaultValue or no value at all is given, this will be used.
  // internal state to work as 'uncontrolled' component
  const defaultDate = props.defaultValue ? new Date(props.defaultValue) : null;
  // if we change the time input to localized values
  // we will have different time depending on the selected language
  const [time, setTime] = useState(
    defaultDate ? defaultDate.toLocaleTimeString("de-DE") : "00:00"
  );
  // if a value (controlled) is given, it has priority over the default value / internal state
  const timeValue = props.value
    ? new Date(props.value).toLocaleTimeString("de-DE")
    : time;

  const onTimeChange = (event: any) => {
    props.onChange && props.onChange(event.target.value);
    setTime(event.target.value);
  };

  return (
    <div
      className={clsx(
        "time-input-wrapper",
        props.withCmsStyle && "with-cms-style",
        props.withPublicStyle && "with-public-style"
      )}
      style={{ width: props.fullWidth ? "100%" : "auto" }}
    >
      {props.label && (
        <>
          <label>{props.label}</label>
          <br />
        </>
      )}
      <PbInput
        defaultValue={timeValue}
        onChange={onTimeChange}
        withCmsStyle
        type="time"
        disabled={props.disabled}
        fullWidth
        error={props.error}
        placeholder={props.disabled ? props.disabledPlaceholder : ""}
      />
    </div>
  );
};

export default withStyle(PbTimeInput);
