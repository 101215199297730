import CmsMaxLengthIndicator from "@/components/cms/cmsMaxLengthIndicator/cmsMaxLengthIndicator";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import withStyle from "@/middleware/withStyle";
import { InputAdornment, TextField } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { ChangeEvent, useRef } from "react";
import PbInputLabel from "../pbInputLabel/pbInputLabel";

/**
 * Represents the props for the PbInput component.
 */
export interface PbInputProps {
  /**
   * The label for the input.
   */
  label?: string;

  /**
   * The maximum length allowed for the input.
   */
  maxLength?: number;

  /**
   * Indicates whether the input is mandatory.
   */
  mandatory?: boolean;

  /**
   * Indicates whether to hide the length indicator.
   */
  hideLengthIndicator?: boolean;

  /**
   * Specifies the distance from the right for displaying the length indicator.
   */
  showMaxLengthIndicatorRight?: string;

  /**
   * The error text to display.
   */
  errorText?: string;

  /**
   * Toggles usage of CmsThemeProvider and sets a class
   * @see withStyle
   */
  withCmsStyle?: boolean;

  /**
   * Toggles usage of PublicThemeProvider and sets a class
   */
  withPublicStyle?: boolean;

  /**
   * The callback function called when the value of the input changes.
   */
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;

  tooltip?: string;
  type?: string;
  onKeyDown?: (event: any) => void;
  onKeyUp?: (event: any) => void;
  multiline?: boolean;
  fullWidth?: boolean;
  sx?: any;
  error?: boolean;
  name?: string;
  defaultValue?: string | number;
  placeholder?: string;
  autoFocus?: boolean;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
  minRows?: number;
  endAdornment?: JSX.Element;
  startAdornment?: JSX.Element;
  disabled?: boolean;
}

/**
 * PbInput component represents an input field.
 * @param props - The component properties.
 * @returns A JSX element representing the PbInput.
 */
const PbInput = (props: PbInputProps) => {
  const { t: tPublic } = useTranslation("public");

  const maxLength = props.maxLength || 250;
  const inputWithMaxLength = useRef<any>();

  const sxProps = {
    ...(props.sx ? props.sx : {}),
    ...(props.multiline ? { ".MuiInputBase-root": { height: "auto" } } : {}),
  };

  return (
    <>
      {props.label && (
        <PbInputLabel
          label={props.label}
          mandatory={props.mandatory}
          tooltip={props.tooltip}
        />
      )}
      <div className="with-max-length-indicator">
        <TextField
          variant="outlined"
          onKeyDown={(event) => props.onKeyDown && props.onKeyDown(event)}
          label=""
          multiline={props.multiline}
          fullWidth={props.fullWidth}
          sx={sxProps}
          className={clsx(
            "cms-collection-type-text",
            props.error && "cms-input-error",
            props.withCmsStyle && "with-cms-style",
            props.withPublicStyle && "with-public-style"
          )}
          type={props.type ?? "text"}
          name={props.name ?? ""}
          defaultValue={props.defaultValue}
          onChange={(event) => {
            props.onChange && props.onChange(event);
          }}
          placeholder={props.placeholder ?? ""}
          autoFocus={props.autoFocus}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          inputProps={{ maxLength: `${maxLength}` }}
          inputRef={inputWithMaxLength}
          error={props.error}
          minRows={props.minRows && ""}
          InputProps={{
            endAdornment: props.endAdornment ? (
              <>
                {props.endAdornment}
                {props.error ? (
                  <InputAdornment position="end">
                    <div className="input-error-icon d-flex align-items-center">
                      <PbIcon
                        name="triangle-exclamation-light"
                        className="svg-fill-red"
                        width={20}
                        height={20}
                      />
                    </div>
                  </InputAdornment>
                ) : null}
              </>
            ) : (
              <>
                {props.error ? (
                  <InputAdornment position="end">
                    <div className="input-error-icon d-flex align-items-center">
                      <PbIcon
                        name="triangle-exclamation-light"
                        className="svg-fill-red"
                        width={20}
                        height={20}
                      />
                    </div>
                  </InputAdornment>
                ) : null}
              </>
            ),
            startAdornment: props.startAdornment,
          }}
          disabled={props.disabled ? props.disabled : false}
          onKeyUp={props.onKeyUp ? props.onKeyUp : (e) => {}}
        />
        {!props.hideLengthIndicator && (
          <CmsMaxLengthIndicator
            right={props.showMaxLengthIndicatorRight ? "-51px" : "0px"}
            maxLength={props.maxLength}
            length={inputWithMaxLength.current?.value?.length}
          />
        )}
      </div>
      {props.error && (
        <div className="cms-input-error-msg">
          {props.errorText ? props.errorText : tPublic("inputIncorrect")}
        </div>
      )}
    </>
  );
};

export default withStyle(PbInput);
