import PbButton from "@/components/input/pbButton/pbButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Theme,
} from "@mui/material";
import { SxProps } from "@mui/system";
import clsx from "clsx";
import React from "react";
import PbGenericModalStyle from "./pbGenericModalStyle";

export interface PbGenericModalProps {
  children: React.ReactNode;
  open: boolean;
  title: string;
  buttonConfirmLabel?: string;
  buttonDenyLabel?: string;
  buttonCancelLabel?: string;
  onConfirmClick?: () => void;
  onDenyClick?: () => void;
  onCancelClick?: () => void;
  hideCloseIcon?: boolean;
  onCloseIconClick?: () => void;
  onBackdropClick?: () => void;
  muiDialogProps?: Partial<DialogProps>;
  muiDialogContentSxProps?: SxProps<Theme>;
  className?: string;
  hideHeader?: boolean;
}

export default function PbGenericModal(props: PbGenericModalProps) {
  const handleConfirmClick = () => {
    props.onConfirmClick && props.onConfirmClick();
  };

  const handleDenyClick = () => {
    props.onDenyClick && props.onDenyClick();
  };

  const handleCancelClick = () => {
    props.onCancelClick && props.onCancelClick();
  };

  const handleCloseIconClick = () => {
    if (props.onCloseIconClick) {
      props.onCloseIconClick();
    } else {
      handleCancelClick();
    }
  };

  return (
    <>
      <Dialog
        open={props.open}
        className={clsx("pb-generic-modal", props.className)}
        {...props.muiDialogProps}
        onClose={(event, reason) => {
          if (reason === "backdropClick" && props.onBackdropClick) {
            props.onBackdropClick();
          }
        }}
      >
        {!props.hideHeader && (
          <>
            <DialogTitle>{props.title}</DialogTitle>
            {props.hideCloseIcon ? null : (
              <IconButton
                className="modal-close"
                aria-label="close"
                onClick={handleCloseIconClick}
              >
                <CloseIcon />
              </IconButton>
            )}
          </>
        )}
        <DialogContent
          sx={{
            ...props.muiDialogContentSxProps,
          }}
        >
          {props.children}
        </DialogContent>
        {props.onDenyClick || props.onCancelClick || props.onConfirmClick ? (
          <DialogActions>
            {props.onCancelClick && (
              <PbButton
                color="secondary"
                variant="outlined"
                onClick={handleCancelClick}
              >
                {props.buttonCancelLabel ?? "Cancel"}
              </PbButton>
            )}
            {props.onDenyClick && (
              <PbButton
                color="secondary"
                variant="outlined"
                onClick={handleDenyClick}
              >
                {props.buttonDenyLabel ?? "Deny"}
              </PbButton>
            )}
            {props.onConfirmClick && (
              <PbButton color="primary" onClick={handleConfirmClick}>
                {props.buttonConfirmLabel ?? "Confirm"}
              </PbButton>
            )}
          </DialogActions>
        ) : null}
      </Dialog>
      <PbGenericModalStyle />
    </>
  );
}
