import useCesStrRichText from "@/hooks/useCesStrRichText";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useStrSpaceX from "@/hooks/useStrSpaceX";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { openDynamicListModalAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { useRouter } from "next/router";
import { useRef } from "react";
import { Col } from "react-bootstrap";
import CmsGeneralConfigFrame from "../cms/cmsGeneralConfigFrame/cmsGeneralConfigFrame";
import FooterContent from "./footerContent";
import FooterSpace from "./footerSpace";
import { FooterStyle } from "./footerStyle";

/**
 * Represents the props for the Footer component.
 */
export interface FooterProps {}

/**
 * CmsInputLabel component represents a label for an input field.
 * @param props - The component properties.
 * @returns A JSX element representing the CmsInputLabel.
 */
export default function Footer(props: FooterProps): JSX.Element {
  const dispatch = useAppDispatch();
  const footerRef = useRef<HTMLDivElement>(null);
  const tCms = useCmsTranslation();
  const router = useRouter();

  const cmsEditView = useAppSelector((state) => state?.cmsGeneral?.editView);
  const footerContent = useAppSelector((state) =>
    state.general.dynamicLists && Array.isArray(state.general.dynamicLists)
      ? state.general.dynamicLists.find(
          (dynamicList) => dynamicList.name === "footer"
        )
      : []
  );

  const { richTextClassName } = useCesStrRichText(
    globalConfig?.footer?.richtextFooter, globalConfig?.footer?.richtextFooter
  );

  const copyrightText = globalConfig?.footer?.copyrightTextJson;
  const footerType = globalConfig?.footer?.footerType;
  const parallaxOrStickyFooter =
    footerType === "parallax" || footerType === "sticky";

  const spaceX = useStrSpaceX(globalConfig?.footer?.spaceX);

  return (
    <>
      <div className="footer-wrapper">
        <div
          ref={footerRef}
          className={`${
            globalConfig?.footer?.spaceX === 0 ? "" : "container-fluid"
          } footer-space footer ${
            parallaxOrStickyFooter && cmsEditView
              ? "footer-sticky-edit-mode"
              : ""
          }`}
        >
          {/* Row */}
          <div className="row g-0 justify-content-center footer-content m-auto">
            <Col className="repeatable-elements-column">
              {cmsEditView ? (
                <CmsGeneralConfigFrame
                  smallTitle
                  configButtonTitle={tCms("process")}
                  frameOnHover
                  hideAddCEButton
                  title={tCms("footer-content")}
                  openContentEditModal={() => {
                    dispatch(
                      openDynamicListModalAction({
                        dynamicList: {
                          dynamicListDisplayName: tCms("footer-element"),
                          dynamicListName: "footer",
                          enabledListAttributes: [],
                          enabledListItemAttributes: [
                            "richTextContent",
                            "img",
                            "imgAlt",
                            "imgTitle",
                          ],
                        },
                      })
                    );
                  }}
                >
                  <FooterContent
                    copyrightText={copyrightText}
                    footerContent={footerContent}
                    cmsEditView={cmsEditView}
                    richTextClassName={richTextClassName}
                  />
                </CmsGeneralConfigFrame>
              ) : (
                <FooterContent
                  copyrightText={copyrightText}
                  footerContent={footerContent}
                  cmsEditView={cmsEditView}
                  richTextClassName={richTextClassName}
                />
              )}
            </Col>
          </div>
        </div>
        {copyrightText && (
          <div
            className={`${
              spaceX === 0 ? "" : "container-fluid"
            } footer-space footer-copyright ${richTextClassName}`}
            dangerouslySetInnerHTML={{
              __html:
                (router.locale &&
                  copyrightText &&
                  copyrightText[router.locale]) ??
                "",
            }}
          ></div>
        )}
      </div>
      <FooterSpace settings={{ spaceX }} />
      <FooterStyle
        repeatableElementsAmount={
          footerContent?.content ? footerContent.content.length : 1
        }
        spaceX={spaceX}
      />
      <style jsx>{`
        :global(.main) {
          ${parallaxOrStickyFooter ? `position: relative;` : ""}
          ${footerType === "parallax" ? "z-index: 10" : ""}
        }

        .footer-wrapper {
          ${parallaxOrStickyFooter ? "max-height: 50vh" : ""}
        }
      `}</style>
    </>
  );
}
