import ResponseResult from "@/types/classes/ResponseResult";
import axios, { AxiosRequestConfig } from "axios";
import { NextApiRequest, NextApiResponse } from "next";
import {
  ASSET_BODY_HTML,
  ASSET_CONFIG_CONTENTELEMENTSETTINGS_JSON,
  ASSET_CONFIG_GLOBAL_JSON,
  ASSET_CONTENT_DYNAMICLIST_JSON,
  ASSET_CONTENT_NAVIGATION_JSON,
  ASSET_CSS_GLOBAL_MIN_CSS,
  ASSET_HEAD_HTML,
  REQ_METHOD_DELETE,
  REQ_METHOD_POST,
  REQ_METHOD_PUT,
  SUPRESSED_404_ERRORS,
} from "./constants";
import { deepMerge } from "./util";

/**
 * Axios request configuration for client-side requests.
 *
 * This configuration is intended to be used with Axios for making HTTP requests on the client side.
 *
 * @type {AxiosRequestConfig}
 * @property {Object} headers - Request headers.
 *   @property {string} Cache-Control - Specifies the cache control directive (e.g., "no-cache").
 *   @property {string} Pragma - Specifies the Pragma directive (e.g., "no-cache").
 *   @property {string} Expires - Specifies the Expires directive (e.g., "0").
 */
export const globalAxiosConfigServerSide: AxiosRequestConfig = {
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
};

/**
 * Creates an Axios request configuration object by merging a custom Axios
 * configuration with global Axios server configuration and adding an Authorization
 * header if an access token is provided.
 *
 * @param {string|undefined} accessToken - The access token to be included in the
 * Authorization header.
 * @param {AxiosRequestConfig} customAxiosConfig - Custom Axios request
 * configuration to be merged with global configuration.
 * @returns {AxiosRequestConfig} The merged Axios request configuration object.
 */
const createAxiosConfigServerSide = (
  accessToken: string | undefined,
  customAxiosConfig: AxiosRequestConfig = {}
): AxiosRequestConfig => {
  let newReqAxiosConfig = {
    ...globalAxiosConfigServerSide,
    headers: { ...globalAxiosConfigServerSide.headers },
  };
  // merge custom config with global config (custom config will override global keys)
  newReqAxiosConfig = deepMerge(newReqAxiosConfig, customAxiosConfig);
  // add Authorization header to the axios config
  addAuthorizationHeaderServerSide(newReqAxiosConfig, accessToken);
  return newReqAxiosConfig;
};

/**
 * Adds an Authorization header to the Axios request configuration if an access token
 * is provided.
 *
 * @param {string|undefined} accessToken - The access token to be included in the
 * Authorization header.
 * @param {AxiosRequestConfig} requestConfig - The Axios request configuration to which
 * the Authorization header will be added.
 * @returns {AxiosRequestConfig} The modified Axios request configuration with the
 * Authorization header, if applicable.
 */
const addAuthorizationHeaderServerSide = (
  requestConfig: AxiosRequestConfig,
  accessToken?: string | undefined
): AxiosRequestConfig => {
  const newRequestConfig = { ...requestConfig };
  if (accessToken) {
    newRequestConfig.headers!.Authorization = `Bearer ${accessToken}`;
  }
  return newRequestConfig;
};

export const axiosGetRequestServerSide = async (
  url: string,
  accessToken?: string,
  customAxiosConfig: AxiosRequestConfig = {}
): Promise<ResponseResult<any>> => {
  return await axiosRequestServerSide(
    async () =>
      await axios.get(
        url,
        createAxiosConfigServerSide(accessToken, customAxiosConfig)
      )
  );
};

export const axiosPostRequestServerSide = async (
  url: string,
  body: any,
  accessToken?: string,
  customAxiosConfig: AxiosRequestConfig = {}
): Promise<ResponseResult<any>> => {
  return await axiosRequestServerSide(
    async () =>
      await axios.post(
        url,
        body,
        createAxiosConfigServerSide(accessToken, customAxiosConfig)
      )
  );
};

export const axiosPutRequestServerSide = async (
  url: string,
  body: any,
  accessToken?: string,
  customAxiosConfig: AxiosRequestConfig = {}
): Promise<ResponseResult<any>> => {
  return await axiosRequestServerSide(
    async () =>
      await axios.put(
        url,
        body,
        createAxiosConfigServerSide(accessToken, customAxiosConfig)
      )
  );
};

export const axiosDeleteRequestServerSide = async (
  url: string,
  accessToken?: string,
  customAxiosConfig: AxiosRequestConfig = {}
): Promise<ResponseResult<any>> => {
  return await axiosRequestServerSide(
    async () =>
      await axios.delete(
        url,
        createAxiosConfigServerSide(accessToken, customAxiosConfig)
      )
  );
};

const axiosRequestServerSide = async (
  requestFn: () => Promise<any>
): Promise<ResponseResult<any>> => {
  try {
    const response = await requestFn();
    return new ResponseResult(response.data, response);
  } catch (error: any) {
    handleRequestErrorServerSide(error, "");
    if (error && error.response) {
      return ResponseResult.fromError(error);
    }
    return ResponseResult.fromError(error);
  }
};

/**
 * general axios get request function
 * TODO this is no final version this might change later
 *
 * @param {} url
 * @param {*} accessToken
 * @param {*} customAxiosConfig
 * @returns
 */
export const handleRequestErrorServerSide = (
  error: any,
  customLog?: string
): void => {
  let supressErrorLog: boolean = false;
  if (process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT === "local") {
    if (customLog) {
      global.log.error("[handleRequestErrorServerSide] " + customLog);
    }
  }

  // supress specific 404 error messages
  if (
    error &&
    error.response &&
    error.response.status === 404 &&
    error.config &&
    error.config.url &&
    SUPRESSED_404_ERRORS.some((errorSubstring) =>
      error.config.url.includes(errorSubstring)
    )
  ) {
    const supressed404Error = SUPRESSED_404_ERRORS.find((errorSubstring) =>
      error.config.url.includes(errorSubstring)
    );
    supressErrorLog = true;
    logMissingAsset(error, supressed404Error);
  }

  if (!supressErrorLog) {
    if (error) {
      global.log.error(
        error.message +
          `${
            error.config && error.config.url ? ` ${error.config.url}` : ""
          } - ${
            error.config && error.config.method ? ` ${error.config.method}` : ""
          }`
      );
    }

    const errorObject = getErrorResponseObjectServerSide(error);
    if (errorObject.status !== -1) {
      global.log.error({ errorObject }, "[getErrorResponseObjectServerSide]");
    }
  }
};

/**
 *
 * get the strapi error object
 *
 * e.g.:
 *  {
 *   status: 413,
 *   error: 'Request Entity Too Large',
 *   message: 'FileTooBig',
 *  }
 *
 * if the strapi error.response.data is not present this
 * function will create a temporary error object with some
 * request config information
 *
 * @param {*} error
 * @returns
 */
export const getErrorResponseObjectServerSide = (error: any) => {
  if (error?.response?.data?.error) {
    return error.response.data.error;
  }
  return {
    status: -1,
    error: error?.message,
    message: `${error?.message} - ${error?.config?.method ?? ""}`,
  };
};

/**
 * gets the correct status from a strapi error object
 * otherwise it will always return status code 400
 *
 * @param {*} error
 * @returns
 */
export const getErrorResponseStatusCodeServerSide = (error: any) => {
  if (error?.response?.data?.error?.status) {
    return error.response.data.error.status;
  }
  if (error?.response?.status) {
    global.log.error(
      `[getErrorResponseStatusCodeServerSide] could not get status from error.response.data object returning status from actual response (${error.response.status})`
    );
    return error.response.status;
  }
  global.log.error(
    "[getErrorResponseStatusCodeServerSide] could not get status returning default code 400"
  );
  return 400;
};

/**
 * Sends an HTTP request to a server-side endpoint using Axios based on
 * the provided request method.
 *
 * @param {NextApiRequest} req - The Next.js request object.
 * @param {string} requestPath - The path for the HTTP request.
 * @param {string|undefined} accessToken - (Optional) The access token to
 *  be included in the request header.
 * @param {any} customRequestBody - (Optional) Custom request body for the
 *  HTTP request.
 * @param {AxiosRequestConfig} customAxiosConfig - (Optional) Custom Axios
 *  configuration for the request.
 *
 * @returns {Promise<ResponseResult<any>>} A Promise that resolves with
 *  the response data from the server-side endpoint.
 */
export const forwardRequestServerSide = async (
  req: NextApiRequest,
  requestPath: string,
  accessToken?: string,
  customRequestBody?: any,
  customAxiosConfig: AxiosRequestConfig = {}
): Promise<ResponseResult<any>> => {
  let result: ResponseResult<any>;
  if (req.method === REQ_METHOD_POST) {
    result = await axiosPostRequestServerSide(
      requestPath,
      customRequestBody ? customRequestBody : req.body,
      accessToken,
      customAxiosConfig
    );
  } else if (req.method === REQ_METHOD_PUT) {
    result = await axiosPutRequestServerSide(
      requestPath,
      customRequestBody ? customRequestBody : req.body,
      accessToken,
      customAxiosConfig
    );
  } else if (req.method === REQ_METHOD_DELETE) {
    result = await axiosDeleteRequestServerSide(
      requestPath,
      accessToken,
      customAxiosConfig
    );
  } else {
    // default case
    result = await axiosGetRequestServerSide(
      requestPath,
      accessToken,
      customAxiosConfig
    );
  }
  return result;
};

/**
 * Build and send an HTTP response on the server-side based
 * on the provided response result.
 *
 * @param {NextApiResponse} res - The Next.js response object.
 * @param {ResponseResult<any>} result - The response result containing
 *  success status and data, or error information.
 */
export const buildResponseResultServerSide = (
  res: NextApiResponse,
  result: ResponseResult<any>
): void => {
  if (result?.success) {
    res.status(200).json(result.response!.data);
    return;
  }
  res
    .status(getErrorResponseStatusCodeServerSide(result.error))
    .json(getErrorResponseObjectServerSide(result.error));
};

const logMissingAsset = (
  error: any,
  missingAsset: string | undefined
): void => {
  switch (missingAsset) {
    case ASSET_CONFIG_GLOBAL_JSON:
    case ASSET_CONFIG_CONTENTELEMENTSETTINGS_JSON:
    case ASSET_CSS_GLOBAL_MIN_CSS:
    case ASSET_CONTENT_NAVIGATION_JSON:
    case ASSET_CONTENT_DYNAMICLIST_JSON:
    case ASSET_HEAD_HTML:
    case ASSET_BODY_HTML:
    default:
      global.log.warn(
        `[missing asset] (${error.config.url}) - not configured (yet?)`
      );
      break;
  }
};
