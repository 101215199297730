import useCmsTranslation from "@/hooks/useCmsTranslation";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Unpublished from "@mui/icons-material/Unpublished";

interface CmsTopBarPublishedIndicatorProps {
  published: boolean;
}

const CmsTopBarPublishedIndicator = (
  props: CmsTopBarPublishedIndicatorProps
) => {
  const tCms = useCmsTranslation();
  return (
    <div className="px-3" style={{ marginTop: "auto", marginBottom: "auto" }}>
      {props.published ? (
        <CheckCircle
          fontSize="small"
          style={{ fill: "#fff" }}
          titleAccess={tCms("published")}
        />
      ) : (
        <Unpublished
          fontSize="small"
          style={{ fill: "#fff" }}
          titleAccess={tCms("unpublished")}
        />
      )}
    </div>
  );
};

export default CmsTopBarPublishedIndicator;
