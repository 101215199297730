import {
  createFormRequest,
  deleteFormRequest,
  getFormCreateValuesRequest,
  getFormRequest,
  updateFormRequest,
} from "@/services/cmsFormService/cmsFormService";
import { cmsTranslate } from "@/services/cmsTranslation/cmsTranslationService";
import { ReduxThunkAction } from "@/store/store";
import {
  hideLoadingOverlayAction,
  showLoadingOverlayAction
} from "../general/generalSlice";
import { initEditDataAction } from "./cmsEditSlice";

const emptyForm = {
  name: "",
  title: "",
  mailRecipients: "",
  mailSubject: "",
  cfgFormSubmitButtonText: "",
  cfgFormBackButtonText: "",
  cfgWithCaptcha: false,
  cfgMailTemplate: 0,
  creator: "",
  fields: [],
};

export const cmsGetFormAndInitEditThunk =
  (formId: number): ReduxThunkAction =>
  async (dispatch, getState) => {
    const result = await getFormRequest(formId);
    if (!result.success) {
      return Promise.reject();
    }
    dispatch(
      initEditDataAction({
        editForm: result.data.form,
        availableFormFields: result.data.availableFormFields,
        availableFormEmailTemplates: result.data.availableFormEmailTemplates,
      })
    );
    return Promise.resolve();
  };

export const cmsGetFormCreateValuesAndInitEditorThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    const result = await getFormCreateValuesRequest();
    if (!result.success) {
      return Promise.reject();
    }
    dispatch(
      initEditDataAction({
        editForm: emptyForm,
        availableFormFields: result.data.availableFormFields,
        availableFormEmailTemplates: result.data.availableFormEmailTemplates,
      })
    );
    return Promise.resolve();
  };

export const cmsDeleteFormThunk =
  (formId: number): ReduxThunkAction =>
  async (dispatch, getState) => {
    const result = await deleteFormRequest(formId);
    if (!result.success) {
      global.log.error(`could not delete form with id ${formId}`);
      global.log.error(result.error);
      return Promise.reject();
    }
    return Promise.resolve();
  };

export const cmsSaveFormThunk =
  (): ReduxThunkAction =>
  async (dispatch, getState) => {

    const isEdit = !!getState().cmsEdit.data.editForm.id;

    if (!getState().cmsEdit.data.editForm.name) {
      return Promise.reject("formNameEmpty");
    }

    dispatch(showLoadingOverlayAction(cmsTranslate(isEdit ? "updatingForm" : "creatingForm")));
    let result;
    if (!isEdit) {
      result = await createFormRequest(
        getState().cmsEdit.data.editForm,
        getState().cmsUser.user.username
      );
    } else {
      result = await updateFormRequest(getState().cmsEdit.data.editForm);
    }
    dispatch(hideLoadingOverlayAction());
    if (!result.success) {
      if (!isEdit) {
        global.log.error(`could not create form`);
      } else {
        global.log.error(
          `could not update form with id ${!getState().cmsEdit.data.editForm
            .id}`
        );
      }
      global.log.error(result.error);
      return Promise.reject();
    }
    return Promise.resolve();
  };