import { mediaManagerModalSelectFolderAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { StrapiUploadFolder } from "@/types/strapi";
import clsx from "clsx";

interface CmsMediaManagerFolderProps {
  id?: string;
  folder: StrapiUploadFolder;
}
export default function CmsMediaManagerFolder(
  props: CmsMediaManagerFolderProps
) {
  const dispatch = useAppDispatch();
  const { currentItem, focus } = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal
  );
  const handleClickEvent = () => {
    dispatch(mediaManagerModalSelectFolderAction(props.folder));
  };

  const isSelectedFolder = () => {
    return (
      currentItem &&
      "pathId" in currentItem &&
      currentItem.id === props.folder.id
    );
  };

  return (
    <>
      <div
        aria-hidden="true"
        id={props.id}
        className={clsx(
          "media-manager-item-folder",
          isSelectedFolder() && "selected",
          !focus && "grey"
        )}
        onClick={handleClickEvent}
      >
        <div className="media-manager-item-folder-image">
          <img alt="icon-folder" src="/svg/fontAwesome/folder-light.svg" />
        </div>
        <div className="media-manager-item-folder-name">
          <p className="cms-modal-text">{props.folder.name}</p>
        </div>
      </div>
      <style jsx>{`
        .media-manager-item-folder {
          cursor: pointer;
          display: flex;
          flex-flow: column;
          height: 100%;
          position: relative;
          border: 3px solid transparent;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);
        }

        .media-manager-item-folder.selected {
          border: 3px solid #105f8d;

          &.grey {
            border: 3px solid #dee2e6;
          }
        }

        .media-manager-item-folder-image {
          flex: 1 1 auto;
          background-size: cover;
          background: none center;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 120px;
            width: 120px;
          }
        }

        .media-manager-item-folder-name {
          flex: 0 1 37px;
          padding: 4px 3px 3px;
          border-top: 1px solid #efefef;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            font-size: 12px;
            font-weight: 600;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      `}</style>
    </>
  );
}
