import PbAutoComplete from "@/components/input/pbAutoComplete/pbAutoComplete";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useIsMounted from "@/hooks/useIsMounted";
import { getPageTypeNameLocalized } from "@/services/cmsPageTypeService/cmsPageTypeService";
import {
  getAllCmsPages,
  getAllCmsPageTypes,
} from "@/services/cmsService/cmsService";
import {
  setAllPages,
  setPageTypes,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ROOT_PAGE_URL } from "@/utils/constants";
import { getLocalizedValue } from "@/utils/localizationUtil";
import { removeUrlAnchor, removeUrlQueryParams } from "@/utils/urlUtil";
import { ListSubheader } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import CmsTopBarCenterControlsStyle from "./cmsTopBarCenterControlsStyle";
/**
 * Represents the props for the CmsTopBarProps component.
 */
export interface CmsTopBarCenterControlsProps {}

/**
 * CmsInputLabel component represents a label for an input field.
 * @param props - The component properties.
 * @returns A JSX element representing the CmsInputLabel.
 */
const CmsTopBarCenterControls = (
  props: CmsTopBarCenterControlsProps
): JSX.Element => {
  const allPages = useAppSelector((state) => state.cmsGeneral.allPages);
  const pageTypes = useAppSelector((state) => state.cmsGeneral.pageTypes);
  const router = useRouter();
  const tCms = useCmsTranslation();

  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getSelectedPage = (): any => {
    return allPages.find((page: any) => {
      let url = removeUrlAnchor(router.asPath);
      url = removeUrlQueryParams(url);
      return url === page.url ? page : null;
    });
  };

  // Moving getAllPages to cmsTopBarCenterControls to be fetched client-side.
  useEffect(() => {
    const getAllPagesAndPageTypes = async () => {
      setIsLoading(true);
      const allPages = await getAllCmsPages();
      const allPageTypes = await getAllCmsPageTypes();

      allPages.forEach((page) => {
        if (page.type) {
          page.group = getPageTypeNameLocalized(
            page.type,
            process.env.NEXT_PUBLIC_CMS_USER_LANGUAGE!
          );
        }
      });

      allPages.sort((a, b) => {
        if (a.group !== b.group) {
          return a.group.localeCompare(b.group);
        }
        return a.name.localeCompare(b.name);
      });

      if (isMounted()) {
        setIsLoading(false);
        dispatch(setAllPages(allPages));
        dispatch(setPageTypes(allPageTypes));
      }
    };

    getAllPagesAndPageTypes();
  }, [isMounted, dispatch]);

  return (
    <>
      <Box className="page-navigation">
        <PbAutoComplete
          withCmsStyle
          noOptionsText={tCms("no-page-found")}
          fullWidth
          groupBy={(option) => option.group}
          renderGroup={(params) => {
            const currentPageType = pageTypes.find(
              (pageType) =>
                params.group ===
                  getLocalizedValue(
                    pageType.localizedName,
                    process.env.NEXT_PUBLIC_CMS_USER_LANGUAGE!
                  ) || params.group === pageType.name
            );
            return (
              <ul key={params.key}>
                <ListSubheader>
                  <span
                    className="cms-dropdown-icon"
                    style={{
                      marginTop: "-1px",
                      marginLeft: "7px",
                      paddingRight: "6px",
                    }}
                    title={
                      tCms("pageType") +
                      ": " +
                      (currentPageType
                        ? getPageTypeNameLocalized(
                            currentPageType,
                            process.env.NEXT_PUBLIC_CMS_USER_LANGUAGE!
                          )
                        : "")
                    }
                  >
                    <PbIcon
                      width={15}
                      name="table-layout-light"
                      style={{
                        fill:
                          currentPageType && currentPageType.color
                            ? currentPageType.color
                            : "var(--pb-cms-color)",
                      }}
                    />
                  </span>
                  {params.group}
                </ListSubheader>
                {params.children}
              </ul>
            );
          }}
          label=""
          onChange={(e, selection) => {
            if (selection) {
              if (selection.url === ROOT_PAGE_URL) {
                router.push("/");
              } else {
                router.push("/" + selection.url);
              }
            }
          }}
          defaultValue={getSelectedPage()}
          getOptionLabel={(option) => option.name}
          options={allPages}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              {...params}
              InputLabelProps={{
                style: { color: " " },
              }}
              margin="none"
              InputProps={{
                ...params.InputProps,
                startAdornment: isLoading ? (
                  <>
                    <span
                      className="loading-pages-indicator"
                      style={{ marginLeft: "7px", display: "flex" }}
                    >
                      <PbIcon
                        name="loader-light"
                        width={15}
                        height={15}
                        className={"svg-fill-white rotate"}
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      />
                      <span
                        style={{
                          color: "var(--pb-cms-variable-white)",
                          marginLeft: "5px",
                          display: "flex",
                        }}
                      >
                        {" " + tCms("loadingDotDotDot")}
                      </span>
                    </span>
                  </>
                ) : (
                  <>
                    {getSelectedPage()?.type && (
                      <span
                        className="cms-dropdown-icon"
                        style={{
                          marginLeft: "7px",
                          display: "flex",
                        }}
                        title={
                          tCms("pageType") +
                          ": " +
                          (getPageTypeNameLocalized(
                            getSelectedPage().type,
                            process.env.NEXT_PUBLIC_CMS_USER_LANGUAGE!
                          ) ?? "")
                        }
                      >
                        <PbIcon
                          width={15}
                          height={15}
                          name="table-layout-light"
                          style={{
                            fill:
                              getSelectedPage().type.color ??
                              "var(--pb-cms-color)",
                          }}
                        />
                      </span>
                    )}
                    {getSelectedPage()?.url === "/" ? (
                      <span
                        className="cms-dropdown-icon"
                        style={{ marginLeft: "7px", display: "flex" }}
                        title={tCms("homepage")}
                      >
                        <PbIcon name="globe-light" className="svg-fill-white" />
                      </span>
                    ) : null}
                    {getSelectedPage()?.redirectEnabled ? (
                      <span
                        className="cms-dropdown-icon"
                        style={{ marginLeft: "7px", display: "flex" }}
                        title={tCms("redirect")}
                      >
                        <PbIcon name="link-light" className="svg-fill-white" />
                      </span>
                    ) : null}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.name}{" "}
                {option.url === "/" ? (
                  <span
                    className="default-page-indicator"
                    style={{ display: "flex" }}
                    title={tCms("homepage")}
                  >
                    <PbIcon name="globe-light" className="svg-fill-white" />
                  </span>
                ) : null}
                {option.redirectEnabled && option.redirectUrl ? (
                  <span
                    className="default-page-indicator"
                    style={{ display: "flex" }}
                    title={tCms("redirect")}
                  >
                    <PbIcon name="link-light" className="svg-fill-white" />
                  </span>
                ) : null}
              </li>
            );
          }}
        />
      </Box>
      <CmsTopBarCenterControlsStyle />
    </>
  );
};

export default CmsTopBarCenterControls;
