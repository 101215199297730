import { setScrollState } from "@/store/slices/general/generalSlice";
import { useAppDispatch } from "@/store/store";
import { ScrollStateType } from "@/types/types";
import { useCallback, useEffect, useRef } from "react";
import useStateDebounce from "./useStateDebounce";

interface ScrollState {
  scrollState: ScrollStateType;
  position: number;
}

export default function useScrollState(): string | null {
  const dispatch = useAppDispatch();

  const scrollStateRef = useRef<ScrollState>({
    scrollState: null,
    position: -1,
  });

  const { setValueToDebounce: setScrollStateDebounced } = useStateDebounce(
    { scrollState: null, position: -1 },
    50,
    (debouncedValue) => {
      dispatch(setScrollState(debouncedValue));
    }
  );

  const getScrollState = useCallback(() => {
    let currentScrollState: ScrollState = {
      scrollState: null,
      position: window.scrollY,
    };
    if (window.scrollY === 0) {
      currentScrollState.scrollState = "top";
    } else if (
      document.body.scrollHeight ===
      window.scrollY + window.innerHeight
    ) {
      currentScrollState.scrollState = "bottom";
    } else if (window.scrollY > 0) {
      currentScrollState.scrollState = "scrolled";
    }
    return currentScrollState;
  }, []);

  const scrollHandler = useCallback(() => {
    const currentScrollState = getScrollState();
    scrollStateRef.current = currentScrollState;
    setScrollStateDebounced(currentScrollState);
  }, [getScrollState, setScrollStateDebounced]);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    const currentScrollState = getScrollState();
    scrollStateRef.current = currentScrollState;
    setScrollStateDebounced(currentScrollState);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [setScrollStateDebounced, getScrollState, scrollHandler]);

  return scrollStateRef.current.scrollState;
}
