import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import { resetEditDataAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import { cmsSaveFormThunk } from "@/store/slices/cmsEdit/cmsFormThunks";
import { setEditModeAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import {
  disableBeforeunloadAction,
  showLoadingOverlayAction,
} from "@/store/slices/general/generalSlice";
import { redirectThunk } from "@/store/slices/general/generalThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { getDefaultLocale } from "@/utils/localizationUtil";
import { createToastError } from "@/utils/utilComponents";
import Box from "@mui/material/Box";
import { useRouter } from "next/router";

/**
 * Represents the props for the CmsTopBarProps component.
 */
export interface CmsTopBarLeftControlsFormProps {}

/**
 * CmsInputLabel component represents a label for an input field.
 * @param props - The component properties.
 * @returns A JSX element representing the CmsInputLabel.
 */
const CmsTopBarLeftControlsForm = (
  props: CmsTopBarLeftControlsFormProps
): JSX.Element => {
  const dispatch = useAppDispatch();
  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);
  const currentContext = useAppSelector(
    (state) => state.cmsGeneral.currentContext
  );
  const router = useRouter();
  const tCms = useCmsTranslation();

  const { showConfirmModal } = useConfirmModal(() => {
    dispatch(showLoadingOverlayAction());
    dispatch(disableBeforeunloadAction());
    dispatch(resetEditDataAction());
    dispatch(setEditModeAction(false));
    dispatch(redirectThunk(() => router.push("/cms/forms"), "/cms/forms"));
  });

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      {editMode ? (
        <>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            onClick={async () => {
              try {
                await dispatch(cmsSaveFormThunk());
                dispatch(disableBeforeunloadAction());
                dispatch(showLoadingOverlayAction());
                dispatch(redirectThunk(() => router.push("/cms/forms")))
              } catch (error) {
                if (error) {
                  createToastError(tCms(error as string));
                }
              }
            }}
            color="primary"
            startIcon={
              <PbIcon
                name="floppy-disk-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
          >
            {tCms("save")}
          </PbButton>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            onClick={() => {
              showConfirmModal({
                title: tCms("stopEditing"),
                content:
                  tCms("confirmStopEditing") +
                  " \n " +
                  tCms("unsavedChangesWillBeLost"),
                acceptBtnText: tCms("yes"),
                denyBtnText: tCms("no"),
                icon: "triangle-exclamation-light",
              });
            }}
            color="secondary"
            startIcon={
              <PbIcon
                name="xmark-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
          >
            {tCms("end")}
          </PbButton>
        </>
      ) : (
        <>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            color="secondary"
            onClick={async () => {
              dispatch(showLoadingOverlayAction());
              router.push("/");
            }}
            startIcon={
              <PbIcon
                name="angle-left-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
            disabled={currentContext === null}
          >
            {tCms("backToWYSIWYG")}
          </PbButton>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            color="primary"
            onClick={async () => {
              router.replace("/cms/forms/create", undefined, {
                locale: getDefaultLocale(),
              });
            }}
            startIcon={
              <PbIcon
                name="plus-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
            disabled={currentContext === null}
          >
            {tCms("createForm")}
          </PbButton>
        </>
      )}
    </Box>
  );
};

export default CmsTopBarLeftControlsForm;
