import useCmsTranslation from "@/hooks/useCmsTranslation";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { useAppSelector } from "@/store/store";
import { Listitem } from "@/types/strapi";
import {
  getTargetValue,
  isUrlDownloadUrl,
  sanitizeLink,
} from "@/utils/urlUtil";
import { createHref } from "@/utils/util";
import clsx from "clsx";
import Link from "next/link";
import React from "react";
import { Col, Row } from "react-bootstrap";
import CmsImage from "../cms/cmsImage/cmsImage";
import OptimizeStrapiImage from "../cms/cmsImage/optimizeStrapiImage";

export interface SocialMediaListProps {
  socialMediaList: any;
  richtextClassname: string;
}

export default function SocialMediaList(props: SocialMediaListProps) {
  const tCms = useCmsTranslation();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const SocialMediaTitleTag: "h2" | "h3" | "h4" | "h5" | "h6" | "p" =
    globalConfig?.footer?.socialMediaTitleType?.startsWith("h")
      ? globalConfig.footer.socialMediaTitleType
      : "p";

  const breakpointTablet = globalConfig?.responsive?.breakpoints?.tablet;

  return (
    <>
      {props.socialMediaList &&
      (props.socialMediaList.title ||
        props.socialMediaList.content?.length > 0) ? (
        <>
          {props.socialMediaList.title && (
            <Row className="edge-to-edge-row">
              <Col className={clsx(props.richtextClassname, "pb-richtext")}>
                <SocialMediaTitleTag className={`social-media-list-title`}>
                  {props.socialMediaList.title}
                </SocialMediaTitleTag>
              </Col>
            </Row>
          )}
          <Row className="edge-to-edge-row">
            {props.socialMediaList.content.length > 0 &&
              props.socialMediaList.content.map(
                (element: Listitem, index: number) => {
                  const href = createHref(
                    element.linkUrl,
                    element.linkAnchor,
                    element.linkGetParams
                  );
                  return (
                    <React.Fragment key={index}>
                      {element.img && href ? (
                        <Col className="col-auto">
                          <div className="social-media-img">
                            <Link
                              href={href}
                              target={
                                element.linkTarget
                                  ? element.linkTarget
                                  : getTargetValue(
                                      sanitizeLink(element.linkUrl) || "#"
                                    )
                              }
                              download={isUrlDownloadUrl(element.linkUrl)}
                              hrefLang={element.linkHrefLang ?? null}
                              rel={element.linkRel ?? null}
                            >
                              <OptimizeStrapiImage
                                image={element.img}
                                isMobile={true}
                                widthLimitSettings={200}
                              >
                                <CmsImage
                                  width={50}
                                  height={50}
                                  image={element.img}
                                />
                              </OptimizeStrapiImage>
                            </Link>
                          </div>
                        </Col>
                      ) : null}
                      {element.img && !href ? (
                        <Col className="col-auto">
                          <div className="social-media-img">
                            <OptimizeStrapiImage
                              image={element.img}
                              isMobile={true}
                              widthLimitSettings={200}
                            >
                              <CmsImage
                                width={50}
                                height={50}
                                image={element.img}
                              />
                            </OptimizeStrapiImage>
                          </div>
                        </Col>
                      ) : null}
                    </React.Fragment>
                  );
                }
              )}
          </Row>
        </>
      ) : (
        <>
          {editView && (
            <Row className="edge-to-edge-row">
              <Col xs={12}>
                <div className="social-media-placeholder">
                  {tCms("createSocialMediaContents")}
                </div>
              </Col>
            </Row>
          )}
        </>
      )}
      {/* Dynamic style*/}
      <style jsx>{`
        .social-media-placeholder {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: center;
        }

        .social-media-list-title {
          line-height: normal;
          hyphens: auto;
          -moz-hyphens: auto;
          -webkit-hyphens: auto;
          @media (min-width: ${breakpointTablet}px) {
            word-break: break-word;
            hyphens: none;
          }
        }

        .social-media-img {
          margin-top: 25px;
          :hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }
      `}</style>
    </>
  );
}
