import { useAppSelector } from "@/store/store";
import LoadingSpinner from "../loadingSpinner/loadingSpinner";
import LoadingSpinnerOverlayStyle from "./loadingSpinnerOverlayStyle";

export default function LoadingSpinnerOverlay() {

  const loadingOverlayIsShown = useAppSelector(
    (state) => state.general.loadingOverlay.visible
  );

  const loadingOverlayMessage = useAppSelector(
    (state) => state.general.loadingOverlay.overlayMessage
  );

  return (
    loadingOverlayIsShown && (
      <>
        <div className="loading-overlay-backdrop"></div>
        <div className="loading-overlay">
          <div className="loading-spinner-container">
            <LoadingSpinner />
            {loadingOverlayMessage && (
              <h2 className="loading-overlay-message cms-info-text">
                {loadingOverlayMessage}
              </h2>
            )}
          </div>
        </div>

        <LoadingSpinnerOverlayStyle />
      </>
    )
  );
}
