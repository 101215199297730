export default function LoadingSpinnerOverlayStyle() {
  return (
    <style jsx global>{`
      .loading-overlay-backdrop {
        background-color: white;
        opacity: 0.9;
        width: 100%;
        height: 100%;
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        z-index: 3080;
      }
      .loading-overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        z-index: 3090;
      }
      .loading-spinner-container {
        width: 100%;
        height: 400px;
        max-width: 100%;
        max-height: 100%;
        position: relative;
        display: block;
        flex-direction: columnn;
        margin: auto;
        padding-left: 3rem;
        padding-right: 3rem;
      }
      .loading-overlay-message {
        margin-top: 25px;
        text-align: center;
        color: black !important;
      }
    `}</style>
  );
}
