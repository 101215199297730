export default function PbGenericModalStyle() {
  return (
    <style jsx global>{`
      .pb-generic-modal {
        .MuiDialogTitle-root {
          font-family: var(--pb-cms-font);
          font-size: 24px;
          font-weight: var(--pb-cms-font-weight);
          line-height: var(--pb-cms-line-height);
          color: var(--pb-cms-text-color);
          letter-spacing: normal;
          text-transform: none;
          padding: 30px;
          border-bottom: 1px solid #00000040;
        }

        .modal-close {
          position: absolute;
          padding: 0;
          top: 30px;
          right: 30px;
          svg {
            font-size: 30px;
            fill: var(--pb-cms-text-color);
          }
        }

        .MuiDialogContent-root {
          padding: 30px;
        }

        .confirm-modal-text,
        .confirm-modal-text div p b {
          font-family: var(--pb-cms-font);
          font-size: 18px;
          font-weight: var(--pb-cms-font-weight);
          line-height: var(--pb-cms-line-height);
          color: var(--pb-cms-text-color);
          letter-spacing: normal;
          text-transform: none;
        }

        .MuiDialogActions-root {
          border-top: 1px solid #00000040;
          padding: 20px 30px;
          justify-content: center;

          button {
            min-width: 130px;
          }
        }
      }
    `}</style>
  );
}
