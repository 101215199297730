import { StrapiUploadFolder } from "@/types/strapi";
import { Breadcrumbs } from "@mui/material";
import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";

interface MediaManagerBreadcrumbProps {
  parents: Array<StrapiUploadFolder> | undefined;
  onSelectEvent: (folder: StrapiUploadFolder | undefined) => void;
}

export default function CmsMediaManagerBreadcrumbs(
  props: MediaManagerBreadcrumbProps
) {
  const tCms = useCmsTranslation();
  const selectionEvent = (folder: StrapiUploadFolder | undefined) => {
    props.onSelectEvent(folder);
  };

  const navigateBack = () => {
    const { parents } = props;
    selectionEvent(
      parents && parents.length > 1 ? parents[parents.length - 2] : undefined
    );
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <PbButton
          startIcon={<PbIcon name="angle-left-light" />}
          color="inherit"
          onClick={navigateBack}
        />
        <Breadcrumbs aria-label="breadcrumb" maxItems={5}>
          <div
            aria-hidden="true"
            className="media-manager-breadcrumb"
            onClick={() => selectionEvent(undefined)}
          >
            {tCms("mediaManager-home")}
          </div>
          {props.parents?.map((parent, index) => {
            return (
              <div
                aria-hidden="true"
                className="media-manager-breadcrumb"
                key={index}
                onClick={() => selectionEvent(parent)}
              >
                {parent.name}
              </div>
            );
          })}
        </Breadcrumbs>
      </div>
      <style jsx>{`
        .media-manager-breadcrumb {
          cursor: pointer;
        }
      `}</style>
    </>
  );
}
