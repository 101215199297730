import {
  createDynamicList,
  getDynamicList,
  updateDynamicList,
} from "@/services/dynamicListService/dynamicListService";
import { ReduxThunkAction } from "@/store/store";
import { Listitem } from "@/types/strapi";
import { handleRequestErrorClientSide } from "@/utils/axiosClientUtil";
import { getRandomSlug } from "@/utils/util";
import {
  updateAttributeAction as cmsGeneralUpdateAttributeAction,
  dynamicListExistsToggleAction,
} from "../cmsGeneral/cmsGeneralSlice";
import {
  addCreatedDynamicListAction,
  updateDynamicListAction,
} from "../general/generalSlice";
import {
  addToArrayAction,
  deleteAttributeAction,
  updateAttributeAction,
} from "./cmsEditSlice";

export interface EditDynamicList {
  id?: number;
  name?: string;
  title?: string;
  createdAt?: Date;
  updatedAt?: Date;
  locale?: string;
  content?: Array<Listitem>;
  localizations?: Array<{ locale: string; id: number }>;
}
export interface cmsDynamicListState {
  editDynamicList?: EditDynamicList | null;
  originalDynamicList?: EditDynamicList | null;
}

export const initCmsDynamicListState: cmsDynamicListState = {
  editDynamicList: null,
  originalDynamicList: null,
};

export const getDynamicListAndInitEditThunk =
  (dynamicListName: string): ReduxThunkAction =>
  async (dispatch, getState) => {
    const result = await getDynamicList(dynamicListName);
    if (result.success) {
      dispatch(
        updateAttributeAction({
          attributePath: "dynamicList",
          value: {
            editDynamicList: result?.response?.data,
            originalDynamicList: result?.response?.data,
          },
        })
      );
      return Promise.resolve();
    } else {
      if (
        result.error.response?.status &&
        result.error.response.status === 404
      ) {
        dispatch(dynamicListExistsToggleAction({ dynamicListExists: false }));
      } else {
        global.log.warn("could not initialize dynamicList editor!");
        global.log.error(result.error);
      }
    }
    return Promise.reject();
  };

export const addEmptyDynamicListItemThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(
      addToArrayAction({
        attributePath: `dynamicList.editDynamicList.content`,
        value: {
          __component: "pb.lsttem",
          __new_id: getRandomSlug(),
          title: "",
          richTextContent: "",
          imgAlt: "",
          imgCaption: "",
          linkUrl: "",
          linkText: "",
          img: null,
        },
      })
    );
  };

export const resetDynamicListModalChangesThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(
      updateAttributeAction({
        attributePath: `dynamicList.editDynamicList`,
        value: getState().cmsEdit.data.dynamicList.originalDynamicList,
      })
    );
  };

export const createDynamicListAndInitEditThunk =
  (dynamicListName: string): ReduxThunkAction =>
  async (dispatch, getState) => {
    const result = await createDynamicList(dynamicListName);
    if (result.success) {
      global.log.debug(`created dynamiclist with name ${dynamicListName}`);
      dispatch(
        updateAttributeAction({
          attributePath: `dynamicList`,
          value: {
            editDynamicList: { ...result?.response?.data, content: [] },
            originalDynamicList: { ...result?.response?.data, content: [] },
          },
        })
      );
      dispatch(addCreatedDynamicListAction(result.response?.data));
      dispatch(dynamicListExistsToggleAction({ dynamicListExists: true }));
      return Promise.resolve();
    } else {
      handleRequestErrorClientSide(
        result.error,
        "could not create dynamiclist [createDynamicList]"
      );
      return Promise.reject();
    }
  };

export const sendUpdateDynamicListThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    const result = await updateDynamicList(
      getState().cmsEdit.data.dynamicList.editDynamicList
    );
    if (result.success) {
      dispatch(deleteAttributeAction({ attributePath: "dynamicList" }));
      dispatch(
        cmsGeneralUpdateAttributeAction({
          attributePath: "dynamicListModal.dynamicListEditorOpen",
          value: false,
        })
      );
      dispatch(updateDynamicListAction(result.response?.data));

      return Promise.resolve(result.response?.data);
    } else {
      return Promise.reject();
    }
  };