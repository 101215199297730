import PbGenericModal from "@/components/genericModal/pbGenericModal";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { CMS_PABU_VERSION } from "@/utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Link } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import CmsAboutModalStyle from "./cmsAboutModalStyle";

interface CmsAboutModalProps {
  open: boolean;
  onClose: () => void;
}
export default function CmsAboutModal(props: CmsAboutModalProps) {
  const tCms = useCmsTranslation();

  const handleModalCloseEvent = () => {
    props.onClose();
  };

  const version = `${tCms("version")}: ${CMS_PABU_VERSION}`;

  return (
    <>
      <PbGenericModal
        className="cms-about-modal cms-modal"
        open={props.open}
        title={version}
        muiDialogProps={{ fullWidth: true }}
        muiDialogContentSxProps={{
          padding: 0,
        }}
      >
        <IconButton
          className="modal-close"
          aria-label="close"
          onClick={handleModalCloseEvent}
        >
          <CloseIcon />
        </IconButton>
        <Row>
          <Col>
            <div>
              <PbIcon name="pabu-logo-allow-fill" height={85} width={250} />
            </div>
          </Col>
          <Col>
            <div className="link-list">
              <div>
                <Link href="https://am-gmbh.de/en/pabu-preise-lizenzen">
                  {tCms("about-needMoreLicences")}
                </Link>
              </div>
              <div>
                <Link href="https://am-gmbh.de/en/pabu-dokumentation">
                  {tCms("about-documentation")}
                </Link>
              </div>
              <div>
                <Link href="https://am-gmbh.de/en/pagebuilder-fuer-strapi">
                  {tCms("about-productWebsite")}
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pabu-text">
          <Col>
            {tCms("about-pabuText1")}{" "}
            <a target="_blank" href="https://am-gmbh.de/en/kontakt">
              {tCms("about-contactUs")}
            </a>
            {tCms("about-pabuText2")}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <div>
              <Row>
                <Col xs={3}>
                  <div className="am-logo-container">
                    <PbIcon name="am-logo" height={70} width={110} />
                  </div>
                </Col>
                <Col>{tCms("about-amText")}</Col>
              </Row>
              <br />
              <ul>
                <li>{tCms("about-li1")}</li>
                <li>{tCms("about-li2")}</li>
                <li>{tCms("about-li3")}</li>
                <li>{tCms("about-li4")}</li>
              </ul>
            </div>
          </Col>
        </Row>
      </PbGenericModal>
      <CmsAboutModalStyle />
    </>
  );
}
