import { useEffect } from "react";
import { Workbox } from "workbox-window";
import { cleanCache } from "../utils/util";

/**
 * useRegisterServiceWorker takes care of the SW registration/unregistration
 * contains a useEffect with empty dependency array
 * 
 * All PWA / SW handling begins here. 
 * There is next-pwa config in next.config.js and an environment variable (NEXT_PUBLIC_CMS_FEATURES "pwa")
 * 
 * by default the PWA is disabled in development (local it is not by default in the NEXT_PUBLIC_CMS_FEATURES array) 
 * You can add "pwa" to the NEXT_PUBLIC_CMS_FEATURES to use the PWA in development (local)
 * 
 * if "pwa" is not in NEXT_PUBLIC_CMS_FEATURES the Serviceworker will be unregistered and the cache is cleaned.
 * if NEXT_PUBLIC_CMS_FEATURES is present the Serviceworker will be registered/updated and the cache is untouched 
 * The service worker source can be found in root service-worker.js
 * 
 * If you want to change caching behaviour look into service-worker.js and modify the caching there.
 */
function useRegisterServiceWorker() {

  useEffect(() => {
    /**
     * PWA unregister function
     **/
    function unregister() {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (const registration of registrations) {
              console.log("SW unregister...");
              registration.unregister();
            }
          });
      }
    }

    if (!process.env.NEXT_PUBLIC_CMS_FEATURES.split(",").includes("pwa")) {
      // if the PWA is disabled by environment variables:
      // unregister it if it was registered
      unregister();
      // clean the cache
      cleanCache();
    } else {
      // if the PWA is not disabled by environment variables:

      /**
       * this property registers/unregisters the serviceworker
       * true = serviceWorker will be registered
       * false = serviceWorker will be unregistered
       *
       * if you want to remove a service worker set this to false
       */
      const __PWA_ENABLE_REGISTER__ = true;

      /**
       * true = cleans the entire cache
       * false = do nothing with the existing cache
       *
       * if you want to clean the entire cache for your service worker set this to true
       * You can always call cleanCache from util.js
       */
      const __PWA_CLEAN_CACHE__ = false;

      /**
       * the pwa start URL
       */
      const __PWA_START_URL__ = "/"; // basePath

      /**
       * service worker source file (this will be generated through next-pwa)
       * do not change this const
       */
      const __PWA_SW__ = "/sw.js";

      /**
       * pwa scope for now this is "/" too but this can be /pwa or /app or something else
       * so the service worker / PWA will only work with this path
       */
      const __PWA_SCOPE__ = "/";

      /**
       * reload the page if your online status changes from offline to online
       */
      const __PWA_RELOAD_ON_ONLINE__ = false;

      if (__PWA_CLEAN_CACHE__) {
        cleanCache();
      }

      if (!__PWA_ENABLE_REGISTER__) {
        unregister();
      }

      if (
        typeof window !== "undefined" &&
        "serviceWorker" in navigator &&
        typeof caches !== "undefined"
      ) {
        if (__PWA_START_URL__) {
          caches.has("start-url").then(function (has) {
            if (!has) {
              caches
                .open("start-url")
                .then((c) =>
                  c.put(__PWA_START_URL__, new Response("", { status: 200 }))
                );
            }
          });
        }

        window.workbox = new Workbox(window.location.origin + __PWA_SW__, {
          scope: __PWA_SCOPE__,
        });

        window.workbox.addEventListener("installed", async (e) => {
          console.log(`SW installed isUpdate=${e.isUpdate}`);
        });

        // register the service worker
        if (__PWA_ENABLE_REGISTER__) {
          console.log("SW register serviceworker (Workbox)...");
          window.workbox.register();
        }

        window.addEventListener("offline", () => {
          console.log("status: offline");
          // Update your UI to reflect that there's no connection.
        });

        window.addEventListener("online", () => {
          console.log("status: online");
          // Update your UI to reflect that the connection is back.

          if (__PWA_RELOAD_ON_ONLINE__) {
            location.reload();
          }
        });
      }
    }
  }, []);
}

export default useRegisterServiceWorker;
