import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { updateCmsUserSettingsAction } from "@/store/slices/cmsUser/cmsUserSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";

export default function CmsUserSettings() {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();

  const updateUserSettings = (cmsUserSettings: any) => {
    dispatch(updateCmsUserSettingsAction(cmsUserSettings));
  };

  const cmsUserSettings = useAppSelector(
    (state) => state.cmsUser.cmsUserSettings
  );

  return (
    <div className="cms-modal-input-wrapper-container">
      <ModalInputWrapper
        label={tCms("user-activateCfgModalAsSidebar")}
        description={tCms("user-activateCfgModalAsSidebarDesc")}
      >
        <PbCheckBox
          isSlider
          value={cmsUserSettings?.sideMenu ? true : false}
          onChange={() =>
            updateUserSettings({
              ...cmsUserSettings,
              sideMenu: !cmsUserSettings?.sideMenu,
            })
          }
          fullHeight
        />
      </ModalInputWrapper>
    </div>
  );
}
