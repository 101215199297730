import { globalConfig } from "@/services/globalConfig/globalConfigService";
export interface FooterStyleProps {
  repeatableElementsAmount: number; // Column amount
  spaceX: number;
}

export const FooterStyle = (props: FooterStyleProps) => {
  const footerElementsAlignmentLeft = globalConfig?.footer
    ?.footerElementsAlignmentLeft
    ? true
    : false;
  const repeatableElementWidth =
    globalConfig?.footer?.repeatableElementWidth || 290;
  // socialMediaWidth: Is allowed to be 0
  const socialMediaWidth =
    globalConfig?.footer?.socialMediaWidth === 0
      ? 0
      : globalConfig?.footer?.socialMediaWidth
      ? globalConfig.footer.socialMediaWidth
      : 290;
  // Defines the breakpoint for snapping of socialMedia-Element.
  // Note: Will only take into account desktop-spaceX.
  const socialMediaSnapBreakpoint =
    repeatableElementWidth * props.repeatableElementsAmount +
    socialMediaWidth +
    2 * props.spaceX;

  // Note: Styling was moved into global.css.
  return (
    <style jsx global>
      {`
        .footer-wrapper {
          .social-media-column {
            @media (min-width: ${socialMediaSnapBreakpoint}px) {
              margin-left: ${footerElementsAlignmentLeft
                ? "auto"
                : "unset"} !important;
              margin-right: ${footerElementsAlignmentLeft
                ? "unset"
                : "unset"} !important;
            }
          }
        }
      `}
    </style>
  );
};
