import { ReduxThunkAction } from "@/store/store";
import {
  createStrapiFolder,
  deleteStrapiFile,
  deleteStrapiFolder,
  findStrapiFileReferences,
  MediaManagerCreateFolderRequest,
  MediaManagerUpdateFolderRequest,
  updateStrapiFile,
  updateStrapiFolder,
} from "@/services/cmsMediaManager/cmsMediaManagerService";
import { StrapiUploadFile } from "@/types/strapi";
import {
  hideLoadingOverlayAction,
  showLoadingOverlayAction,
} from "@/store/slices/general/generalSlice";

export const cmsMediaManagerCreateFolderThunk =
  (
    name: string,
    description: string,
    parentId: number | null
  ): ReduxThunkAction =>
  async (dispatch, getState) => {
    const folderCreateRequest: MediaManagerCreateFolderRequest = {
      name,
      description,
      parentId,
    };
    dispatch(showLoadingOverlayAction());
    const folderCreateResponse = await createStrapiFolder(folderCreateRequest);
    dispatch(hideLoadingOverlayAction());
    return folderCreateResponse.success ? Promise.resolve() : Promise.reject();
  };

export const cmsMediaManagerUpdateFolderThunk =
  (id: number, name: string): ReduxThunkAction =>
  async (dispatch, getState) => {
    const folderUpdateRequest: MediaManagerUpdateFolderRequest = {
      name,
    };
    dispatch(showLoadingOverlayAction());
    const folderUpdateResponse = await updateStrapiFolder(
      id,
      folderUpdateRequest
    );
    dispatch(hideLoadingOverlayAction());
    return folderUpdateResponse.success ? Promise.resolve() : Promise.reject();
  };

export const cmsMediaManagerDeleteFolderThunk =
  (id: number): ReduxThunkAction =>
  async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction());
    const deleteFolderResponse = await deleteStrapiFolder(id);
    dispatch(hideLoadingOverlayAction());
    return deleteFolderResponse.success ? Promise.resolve() : Promise.reject();
  };

export const cmsMediaManagerFileUpdateThunk =
  (file: StrapiUploadFile): ReduxThunkAction =>
  async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction());
    const updateFileResponse = await updateStrapiFile(file);
    dispatch(hideLoadingOverlayAction());
    return updateFileResponse.success ? Promise.resolve() : Promise.reject();
  };

export const cmsMediaManagerFileDeleteThunk =
  (fileId: number): ReduxThunkAction =>
  async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction());
    const deleteFileResponse = await deleteStrapiFile(fileId);
    dispatch(hideLoadingOverlayAction());
    return deleteFileResponse.success ? Promise.resolve() : Promise.reject();
  };

export const cmsMediaManagerFindFileReferencesThunk =
  (file: StrapiUploadFile): ReduxThunkAction =>
  async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction());
    const findFileReferences = await findStrapiFileReferences(file);
    dispatch(hideLoadingOverlayAction());
    return findFileReferences.success
      ? Promise.resolve(findFileReferences.data)
      : Promise.reject();
  };
