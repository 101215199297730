export let globalCss: string = "";

export const setGlobalCss = (globalMinCss: string) => {
  if (globalMinCss) {
    globalCss = globalMinCss;
  } else {
    global.log.warn(
      "[setGlobalCss] no global css set - global css is empty."
    );
  }
};
