import { CmsSettings } from "@/types/cmsSettings/cmsSettings";

export let cmsSettings: CmsSettings | null = null;

export const setCmsSettings = (cSettings: any) => {
  if (cSettings) {
    cmsSettings = cSettings;
  } else {
    global.log.warn("fetched cmsSettings is null or empty.");
  }
};
