export default function CmsTabsStyle() {
  return (
    <style jsx global>{`
      .content-tabs-wrapper {
        width: 100%;
        min-height: 400px; /* Preventing empty tabs from 'jumping'.*/
        font-family: inherit;
        div.tab-list {
          font-family: inherit;
          background-color: var(--pb-cms-color);
          div.MuiTabs-flexContainer {
            button.MuiTab-root {
              font-weight: normal;
              text-transform: capitalize;
              border-right: 1px solid white;
              color: white;
              padding-left: 3rem;
              padding-right: 3rem;
              &.Mui-selected {
                background-color: #105f8d;
                position: relative;
                overflow: visible;
                &::before {
                  content: "";
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  bottom: 0;
                  left: 50%;
                  transform: translate(-50%, 50%) rotate(45deg);
                  background-color: #105f8d;
                }
              }
            }
          }
          .MuiTabs-indicator {
            display: none !important;
          }
        }
      }
    `}</style>
  );
}
