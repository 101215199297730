import { GlobalConfig } from "@/types/globalConfig/globalConfig";

export let globalConfig: GlobalConfig | null = null;

export const setGlobalConfig = (gConfig: any) => {
  if (gConfig) {
    globalConfig = gConfig;
  } else {
    global.log.warn("fetched globalConfig is null or empty.");
  }
};
