import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import { resetEditDataAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import {
  cmsCreateNavigationThunk,
  cmsUpdateNavigationThunk,
} from "@/store/slices/cmsEdit/cmsNavigationThunks";
import { setEditModeAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import {
  disableBeforeunloadAction,
  showLoadingOverlayAction,
} from "@/store/slices/general/generalSlice";
import { redirectThunk } from "@/store/slices/general/generalThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ROOT_PAGE_URL } from "@/utils/constants";
import { isLocaleDefaultLocale } from "@/utils/localizationUtil";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { useRouter } from "next/router";

const CmsTopBarLeftControlsNavigation = () => {
  const dispatch = useAppDispatch();
  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);
  const navigation = useAppSelector(
    (state) => state.cmsEdit.data?.editNavigation
  );

  const router = useRouter();
  const tCms = useCmsTranslation();

  const { showConfirmModal } = useConfirmModal(() => {
    dispatch(resetEditDataAction());
    dispatch(setEditModeAction(false));
    dispatch(disableBeforeunloadAction());
    router.replace(ROOT_PAGE_URL);
  });

  const backToWYSIWYG = () => {
    dispatch(showLoadingOverlayAction());
    dispatch(redirectThunk(() => router.push("/"), "/"));
  };

  let isDefaultLocale = false;
  if (router.locale) {
    isDefaultLocale = isLocaleDefaultLocale(router.locale);
  }
  return (
    <>
      {editMode ? (
        <>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            color="primary"
            onClick={async () => {
              if (navigation.id || !isDefaultLocale) {
                // EDIT:
                try {
                  dispatch(showLoadingOverlayAction("saving navigation..."));
                  await dispatch(cmsUpdateNavigationThunk());
                  createToastSuccess(tCms("navigationUpdateSuccess"));
                  dispatch(disableBeforeunloadAction());
                } catch (e) {
                  createToastError(tCms("navigationUpdateError"));
                }
                } else {
                // CREATE:
                try {
                  dispatch(showLoadingOverlayAction("creating navigation..."));
                  await dispatch(cmsCreateNavigationThunk());
                  createToastSuccess(tCms("navigationCreateSuccess"));
                  dispatch(disableBeforeunloadAction());
                } catch (e) {
                  createToastError(tCms("navigationCreationError"));
                }
              }
            }}
            startIcon={
              <PbIcon
                name="floppy-disk-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
          >
            {tCms("save")}
          </PbButton>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            onClick={() => {
              showConfirmModal({
                title: tCms("stopEditing"),
                content:
                  tCms("confirmStopEditing") +
                  " \n " +
                  tCms("unsavedChangesWillBeLost"),
                acceptBtnText: tCms("yes"),
                denyBtnText: tCms("no"),
                icon: "triangle-exclamation-light",
              });
            }}
            color="secondary"
            startIcon={
              <PbIcon
                name="xmark-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
          >
            {tCms("end")}
          </PbButton>
        </>
      ) : null}
    </>
  );
};

export default CmsTopBarLeftControlsNavigation;
