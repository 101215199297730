import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCategory from "@/hooks/useCategory";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { showLoadingOverlayAction } from "@/store/slices/general/generalSlice";
import { useAppDispatch } from "@/store/store";
import Box from "@mui/material/Box";
import { useRouter } from "next/router";
import CmsEditCategoryModal from "../cmsEditCategoryModal/cmsEditCategoryModal";

export interface CmsTopBarLeftControlsCategoryProps {}

const CmsTopBarLeftControlsCategory = (
  props: CmsTopBarLeftControlsCategoryProps
): JSX.Element => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const tCms = useCmsTranslation();

  const {
    category,
    handleCategory,
    onCategoryChange,
    onHideModal,
    openModal,
    showModal,
  } = useCategory();

  return (
    <>
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            color="secondary"
            onClick={async () => {
              dispatch(showLoadingOverlayAction());
              router.push("/");
            }}
            startIcon={
              <PbIcon
                name="angle-left-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
          >
            {tCms("backToWYSIWYG")}
          </PbButton>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            color="primary"
            onClick={openModal}
            startIcon={
              <PbIcon
                name="plus-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
          >
            {tCms("category-create")}
          </PbButton>
        </>
      </Box>
      <CmsEditCategoryModal
        onHide={onHideModal}
        onConfirm={() => handleCategory("create")}
        category={category}
        onCategoryChange={onCategoryChange}
        show={showModal}
      />
    </>
  );
};

export default CmsTopBarLeftControlsCategory;
