import { useAppSelector } from "@/store/store";
import { PbPage } from "@/types/content-elements";
import { isPageUrlRootUrl } from "@/utils/urlUtil";
import clsx from "clsx";
import { useRouter } from "next/router";

const usePageClassNames = (page: PbPage) => {
  const router = useRouter();

  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);
  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  return clsx(
    page && page.name,
    (page && isPageUrlRootUrl(page.url)) && "is-default",
    (page && page.url) && `url-${page.url}`,
    router.locale,
    editView && "cms-edit-view",
    (editMode && !editView) && "cms-preview-view",
    editMode ? "cms-edit-mode" : "live-view",
  );
};

export default usePageClassNames;
