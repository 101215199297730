import CmsTabsStyle from "./cmsTabsStyle";


interface CmsTabsProps {
  children: React.ReactNode;
}

/**
 * This component is used as a styling wrapper for Mui Tabs
 *
 * @param {JSX} children
 * @returns
 */
const CmsTabs = (props: CmsTabsProps) => {
  return (
    <div className="content-tabs-wrapper">
      {props.children}
      <CmsTabsStyle />
    </div>
  );
};

export default CmsTabs;
