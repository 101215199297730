import ResponseResult from "@/types/classes/ResponseResult";
import { PbCategory } from "@/types/strapi";
import { getNextJsApiURL } from "@/utils/api";
import {
  axiosDeleteRequestClientSide,
  axiosPostRequestClientSide,
  axiosPutRequestClientSide,
} from "@/utils/axiosClientUtil";

export const createCategoryRequest = async (
  category: PbCategory
): Promise<ResponseResult<any>> => {
  const publishResult: ResponseResult<any> = await axiosPostRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/categories/create`),
    category
  );
  return publishResult;
};

export const updateCategoryRequest = async (
  category: PbCategory
): Promise<ResponseResult<any>> => {
  return await axiosPutRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/categories/update/${category.id}`),
    category
  );
};

export const deleteCategoryRequest = async (
  id: number
): Promise<ResponseResult<any>> => {
  return await axiosDeleteRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/categories/delete/${id}`)
  );
};