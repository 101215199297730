import { convertPropertyPath } from "@/utils/util";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import * as objectPath from "object-path";

export interface CmsEditState {
  data: any;
}

export const initialState: CmsEditState = {
  data: null,
};

export const cmsEditSlice = createSlice({
  name: "cmsEdit",
  initialState,
  reducers: {
    resetEditDataAction(state) {
      state.data = null;
    },
    initEditDataAction(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
    updateAttributeAction(
      state,
      action: PayloadAction<{ attributePath: string; value: any }>
    ) {
      objectPath.set(
        state,
        `data.${convertPropertyPath(action.payload.attributePath)}`,
        action.payload.value
      );
    },
    deleteAttributeAction(
      state,
      action: PayloadAction<{ attributePath: string }>
    ) {
      objectPath.del(
        state,
        `data.${convertPropertyPath(action.payload.attributePath)}`
      );
    },
    moveAttributeToNewAttributePathAction(
      state,
      action: PayloadAction<{ attributePath: string; newAttributePath: string }>
    ) {
      const attributeToMove = objectPath.get(
        state,
        `data.${convertPropertyPath(action.payload.attributePath)}`
      );
      if (attributeToMove) {
        objectPath.set(
          state,
          `data.${convertPropertyPath(action.payload.newAttributePath)}`,
          attributeToMove
        );
        objectPath.del(
          state,
          `data.${convertPropertyPath(action.payload.attributePath)}`
        );
      }
    },
    addToArrayAction(
      state,
      action: PayloadAction<{ attributePath: string; value: any; pos?: number }>
    ) {
      if (
        !Array.isArray(
          objectPath.get(
            state,
            `data.${convertPropertyPath(action.payload.attributePath)}`
          )
        )
      ) {
        global.log.debug(
          "[cmsEditSlice addToArray] attributePath is not an array"
        );
        return;
      }
      if (typeof action.payload.pos !== "undefined") {
        objectPath.insert(
          state,
          `data.${convertPropertyPath(action.payload.attributePath)}`,
          action.payload.value,
          action.payload.pos
        );
      } else {
        objectPath.push(
          state,
          `data.${convertPropertyPath(action.payload.attributePath)}`,
          action.payload.value
        );
      }
    },
    removeFromArrayAction(
      state,
      action: PayloadAction<{ attributePath: string; position: number }>
    ) {
      if (
        !Array.isArray(
          objectPath.get(
            state,
            `data.${convertPropertyPath(action.payload.attributePath)}`
          )
        )
      ) {
        global.log.debug(
          "[cmsEditSlice removeFromArray] attributePath is not an array"
        );
        return;
      }
      const fullDelPath =
        action.payload.attributePath + `[${action.payload.position}]`;
      objectPath.del(state, `data.${convertPropertyPath(fullDelPath)}`);
    },
    moveInArrayAction(
      state,
      action: PayloadAction<{
        attributePath: string;
        direction: "up" | "down";
      }>
    ) {
      const convertedAttributePath = `data.${convertPropertyPath(
        action.payload.attributePath
      )}`;
      const arrayAttributePath = convertedAttributePath.substring(
        0,
        convertedAttributePath.lastIndexOf(".")
      );

      if (!/\d+$/.test(convertedAttributePath)) {
        global.log.debug(
          "[cmsEditSlice moveInArray] attributepath does not end with an array position"
        );
      }

      const currentArrayPosition = parseInt(
        convertedAttributePath.substring(
          convertedAttributePath.lastIndexOf(".") + 1
        ),
        10
      );
      
      if (isNaN(currentArrayPosition)) {
        global.log.debug(
          "[cmsEditSlice moveInArray] could not get current array position " +
            currentArrayPosition + " isNaN"
        );
        return;
      }

      const dataArray: any = objectPath.get(state, arrayAttributePath);

      if (!Array.isArray(dataArray)) {
        global.log.debug(
          "[cmsEditSlice moveInArray] attributePath is not an array"
        );
        return;
      }
      const currentArrayLength = dataArray.length;

      let newArrayPosition = currentArrayPosition;
      if (action.payload.direction == "up") {
        // move up
        newArrayPosition = currentArrayPosition - 1;
        if (currentArrayPosition < 0) {
          newArrayPosition = 0;
        }
      } else {
        // move down
        newArrayPosition = currentArrayPosition + 1;
        if (currentArrayPosition > currentArrayLength - 1) {
          newArrayPosition = currentArrayLength - 1;
        }
      }

      const attributeToMove = objectPath.get(state, convertedAttributePath);
      if (attributeToMove) {
        objectPath.set(
          state,
          `${arrayAttributePath}.${currentArrayPosition}`,
          objectPath.get(state, `${arrayAttributePath}.${newArrayPosition}`)
        );
        objectPath.set(
          state,
          `${arrayAttributePath}.${newArrayPosition}`,
          attributeToMove
        );
      }
    },
  },
  extraReducers: (builder) => {},
});

export const {
  resetEditDataAction,
  initEditDataAction,
  updateAttributeAction,
  moveAttributeToNewAttributePathAction,
  deleteAttributeAction,
  addToArrayAction,
  removeFromArrayAction,
  moveInArrayAction,
} = cmsEditSlice.actions;

export default cmsEditSlice.reducer;
