import { setPageVisibilityAction } from "@/store/slices/general/generalSlice";
import { useAppDispatch } from "@/store/store";
import { isSSR } from "@/utils/util";
import React from "react";



function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    return "visibilitychange";
  }
}

function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  }
}

function getIsDocumentHidden() {
  if (isSSR()) {
    return true;
  } else {
    return !document[getBrowserDocumentHiddenProp() ?? "hidden"];
  }
}
/**
 * this hook sets a flag into the general redux state
 * state.general.isPageVisible
 *
 * if you want to use this:
 *
 * const isPageVisible = useSelector((state) => state.general.isPageVisible);
 */
export function usePageVisibility() {
  const dispatch = useAppDispatch();
  const onVisibilityChange = () =>
    dispatch(setPageVisibilityAction(getIsDocumentHidden()));

  React.useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    document.addEventListener(visibilityChange!, onVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityChange!, onVisibilityChange);
    };
  });
}
