/**
 * PbButton style.
 */
const PbButtonStyle = () => {
  return (
    <style jsx global>{`
      .cms-default-button-mui.MuiButton-root {
        height: 40px;
        font-size: 16px;
        text-transform: none;

        // mui button color variants override
        &.MuiButton-textPrimary {
          background-color: var(--pb-cms-color);
          color: white;
          &:hover {
            background-color: #1565c0;
          }
        }
        &.MuiButton-textSecondary {
          background-color: #999999;
          color: white;
          &:hover {
            background-color: #7b7b7b;
          }
        }
        &.MuiButton-textSuccess {
          background-color: #19d923;
          color: white;
          &:hover {
            background-color: #15c023;
          }
        }
        &.MuiButton-textError {
          background-color: #e0284f;
          color: white;
          &:hover {
            background-color: #bc2244;
          }
        }
        &.MuiButton-textInfo {
          background-color: #c6c6c6;
          color: white;
          &:hover {
            background-color: #a9a9a9;
          }
        }
        &.MuiButton-textWarning {
          background-color: #d9b319;
          color: white;
          &:hover {
            background-color: #b88f15;
          }
        }

        .MuiButton-startIcon {
          padding-left: 5px;
        }

        &.no-text {
          min-width: 40px;
          .MuiButton-startIcon {
            padding: 0px;
            margin: 0px;
          }
        }

        &.rounded-bg {
          border-radius: 45px;
        }

        &.no-bg {
          background-color: transparent;

          &:hover {
            background-color: transparent;
            opacity: 50%;
          }
        }

        &.Mui-disabled {
          opacity: 0.5;
          pointer-events: auto;
          &:hover {
            cursor: not-allowed !important;
          }
        }
      }
    `}</style>
  );
};

export default PbButtonStyle;
