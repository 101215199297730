import ResponseResult from "@/types/classes/ResponseResult";
import { getNextJsApiURL } from "@/utils/api";
import {
  axiosDeleteRequestClientSide,
  axiosGetRequestClientSide,
  axiosPostRequestClientSide,
} from "@/utils/axiosClientUtil";
import { translate } from "@/utils/localizationUtil";
import { createToastError } from "@/utils/utilComponents";

export const getNavigationByIdRequest = async (
  navigationId: string
): Promise<ResponseResult<any>> => {
  const result: ResponseResult<any> = await axiosGetRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/navigations/find/${navigationId}`)
  );
  return result;
};

export const createNavigationRequest = async (navigation: any) => {
  const result = await axiosPostRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/navigations/create`),
    navigation
  );

  return result;
};

export const updateNavigationRequest = async (editNavigation: any) => {
  const result = await axiosPostRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/navigations/update/${editNavigation.id}`),
    editNavigation
  );

  return result;
};

export const deleteNavigationRequest = async (id: number) => {
  const result = await axiosDeleteRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/navigations/delete/${id}`)
  );

  return result;
};

export const validateNavigationRequest = (navigationData: any) => {
  let isNavigationValid = true;

  if (
    !navigationData.name ||
    (navigationData.name &&
      typeof navigationData.name === "string" &&
      navigationData.name.trim().length < 1)
  ) {
    isNavigationValid = false;
    createToastError(translate("cms:noNameInput"));
  }

  return isNavigationValid;
};
