import PbInput from "@/components/input/pbInput/pbInput";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import {
  moveInArrayAction,
  removeFromArrayAction,
  updateAttributeAction,
} from "@/store/slices/cmsEdit/cmsEditSlice";
import {
  EnabledListAttributesType,
  refreshRenderKey,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { Listitem } from "@/types/strapi";
import { getIdOrNewId } from "@/utils/util";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import CmsGeneralConfigFrame from "../cmsGeneralConfigFrame/cmsGeneralConfigFrame";
import CmsDynamicListItem from "./cmsDynamicListItem";
import CmsDynamicListItemCreator from "./cmsDynamicListItemCreator";

interface CmsDynamicListEditorFormProps {
  dynamicListName: string;
}

function CmsDynamicListEditorForm(props: CmsDynamicListEditorFormProps) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const [selectedDeleteItemPos, setSelectedDeleteItemPos] = useState<
    number | null
  >(null);
  const renderKey = useAppSelector((state) => state.cmsGeneral.renderKey);

  const { showConfirmModal } = useConfirmModal(() => {
    dispatch(
      removeFromArrayAction({
        attributePath: `dynamicList.editDynamicList.content`,
        position: selectedDeleteItemPos!,
      })
    );
  });

  const deleteClick = (pos: number) => {
    setSelectedDeleteItemPos(pos);
    showConfirmModal({
      title: tCms("dynamicList-deleteListElementConfirmation"),
      content: tCms("dynamicList-deleteListElementConfirmationLong"),
      acceptBtnText: tCms("delete"),
      denyBtnText: tCms("cancel"),
      icon: "triangle-exclamation-light",
    });
  };

  const enabledListAttributes = useAppSelector(
    (state) => state.cmsGeneral.dynamicListModal?.enabledListAttributes
  );

  const isEnabled = (fieldName: EnabledListAttributesType) => {
    return enabledListAttributes.includes(fieldName);
  };

  const editDynamicList = useAppSelector(
    (state) => state.cmsEdit.data?.dynamicList?.editDynamicList
  );

  const content = useAppSelector(
    (state) => state.cmsEdit.data?.dynamicList?.editDynamicList?.content
  );

  const dynamicListDisplayName = useAppSelector(
    (state) => state.cmsGeneral.dynamicListModal?.dynamicListDisplayName
  );

  return (
    <Row key={renderKey}>
      {isEnabled("title") ? (
        <>
          <Col xs={6} className="mb-3 without-config-frame g-0">
            <ModalInputWrapper
              label={tCms("title")}
              description={tCms("dynamicListModal-titleDescription")}
            >
              <PbInput
                withCmsStyle
                fullWidth
                defaultValue={editDynamicList.title}
                onChange={(e) =>
                  dispatch(
                    updateAttributeAction({
                      attributePath: `dynamicList.editDynamicList.title`,
                      value: e.target.value,
                    })
                  )
                }
              />
            </ModalInputWrapper>
          </Col>
          <hr />
        </>
      ) : null}
      <Col xs={12} className="g-0">
        {content?.map((item: Listitem, index: number) => {
          return (
            <React.Fragment key={getIdOrNewId(item, index)}>
              <CmsGeneralConfigFrame
                hideAddCEButton
                title={`${dynamicListDisplayName} ${index + 1}`}
                moveUp={() => {
                  dispatch(
                    moveInArrayAction({
                      attributePath: `dynamicList.editDynamicList.content[${index}]`,
                      direction: "up",
                    })
                  );
                  dispatch(refreshRenderKey());
                }}
                moveDown={() => {
                  dispatch(
                    moveInArrayAction({
                      attributePath: `dynamicList.editDynamicList.content[${index}]`,
                      direction: "down",
                    })
                  );
                  dispatch(refreshRenderKey());
                }}
                onDelete={() => deleteClick(index)}
                position={index}
                arrayLength={content.length}
              >
                <CmsDynamicListItem position={index} item={item} />
              </CmsGeneralConfigFrame>
              <hr />
            </React.Fragment>
          );
        })}
        <CmsDynamicListItemCreator />
      </Col>
    </Row>
  );
}

export default CmsDynamicListEditorForm;
