import useCmsTranslation from "@/hooks/useCmsTranslation";
import { PbIcon } from "../pbIcon/PbIcon";

export interface LoadingTextProps {
  fontColor?: string;
}

const LoadingText = (props: LoadingTextProps) => {
  const tCms = useCmsTranslation();
  return (
    <div className="d-flex">
      <div className="my-auto">
        <PbIcon
          name="loader-light"
          width={15}
          height={15}
          className={"svg-fill-black rotate"}
          style={{ marginRight: "8px" }}
        />
      </div>
      <div>
        <p style={{ color: props.fontColor ?? "var(--pb-cms-variable-black)" }}>
          {tCms("loading")}
        </p>
      </div>
    </div>
  );
};

export default LoadingText;
