import { PageElement } from "@/types/navigation";
import { getPageUrlOrRootUrl, isNavigationLinkActive } from "@/utils/urlUtil";
import { navigationLinkStyleByLevel } from "@/utils/util";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import { Divider, ListItem, ListItemText } from "@mui/material";
import { Collapse, List } from "@mui/material/";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";

interface NavigationMobileItemProps {
  page: PageElement;
  level: number;
  onNavigateEvent: () => void;
}
export default function NavigationMobileItem(props: NavigationMobileItemProps) {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const isExpandable = props.page.subPages?.length > 0;

  const renderInternalOrExternalMenuItem = (
    page: PageElement,
    className?: string
  ) => {
    if (page.externalUrl && page.externalUrl.trim().length > 0) {
      return (
        <ListItem
          key={`nav-mobile-${page.id}`}
          className={className}
          rel="nofollow noopener noreferrer"
          target="_blank"
          href={page.externalUrl}
          component="a"
        >
          <ListItemText key={`nav-mobile-${page.id}-text`}>
            {page.name}
          </ListItemText>
        </ListItem>
      );
    }
    return (
      <Link
        href={`${getPageUrlOrRootUrl(page.page.url)}${
          page.anchor ? `#${page.anchor}` : ""
        }`}
        passHref
        legacyBehavior
      >
        <ListItem
          key={`nav-mobile-${page.id}`}
          className={className}
          component="a"
          onClick={props.onNavigateEvent}
        >
          <ListItemText key={`nav-mobile-${page.id}-text`}>
            {page.name}
          </ListItemText>
        </ListItem>
      </Link>
    );
  };

  if (!props.page.page?.published && props.page.externalUrl.length === 0) {
    return null;
  }

  return (
    <>
      {isExpandable ? (
        <>
          <ListItem
            tabIndex={0}
            key={`nav-item-${props.level}`}
            className={clsx(
              "navigation-item",
              `level-${props.level}`,
              navigationLinkStyleByLevel(props.level),
              isNavigationLinkActive(router, props.page) && "active"
            )}
            onClick={() => setOpen(!open)}
            onKeyUp={(e) => {
              if (e.key === " " || e.key === "Enter") {
                setOpen(!open);
              }
            }}
          >
            <ListItemText>{props.page.name}</ListItemText>
            {open ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <List component="div" disablePadding>
              {[{ ...props.page, subPages: [] }, ...props.page.subPages].map(
                (item, index) => (
                  <NavigationMobileItem
                    page={item}
                    onNavigateEvent={props.onNavigateEvent}
                    level={props.level + 1}
                    key={`nav-mobile-ex-${index}-level-${props.level + 1}`}
                  />
                )
              )}
            </List>
          </Collapse>
        </>
      ) : (
        renderInternalOrExternalMenuItem(
          props.page,
          clsx(
            "navigation-item",
            `level-${props.level}`,
            navigationLinkStyleByLevel(props.level),
            isNavigationLinkActive(router, props.page) && "active"
          )
        )
      )}
    </>
  );
}
