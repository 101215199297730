import {
  hideLoadingOverlayAction,
  showLoadingOverlayAction,
} from "@/store/slices/general/generalSlice";
import { ReduxDispatch } from "@/store/store";
import ResponseResult from "@/types/classes/ResponseResult";
import { StrapiUploadFile } from "@/types/strapi";
import { getNextJsApiURL } from "@/utils/api";
import { axiosPostRequestClientSide } from "@/utils/axiosClientUtil";
import { CHUNK_UPLOAD_DEFAULT_SLICE_SIZE, CHUNK_UPLOAD_MAX_SLICE_SIZE_IN_MB } from "@/utils/constants";
import { translate } from "@/utils/localizationUtil";
import { sleep } from "@/utils/util";
import { createToast } from "@/utils/utilComponents";
import { cmsTranslate } from "../cmsTranslation/cmsTranslationService";

export interface FileUploadParams {
  file: any;
  dispatch?: ReduxDispatch;
  overlayMessage?: string;
  hideLoadingOverlayOnFinish?: boolean;
  fileNameOverride?: string;
  isPrivate?: false;
  fileInformation?: FileInformation;
}

interface FileInformation {
  alternativeText?: string;
  caption?: string;
  folder?: number;
  strapiUploadUpdateId?: number;
}
export interface FileUploadResult {
  success: boolean;
  error: any | null;
  data: StrapiUploadFile | null;
}

interface UploadState {
  started: "started";
  progress: "progress";
  finished: "finished";
  failed: "failed";
}

interface ChunkUploadProgress {
  status: keyof UploadState;
  sliceStart: number;
  newFileName: string | null;
  uploadId?: number;
  file?: any;
  errorMsg?: string;
  info?: any;
}

const UPLOAD_STATE: UploadState = {
  started: "started",
  progress: "progress",
  finished: "finished",
  failed: "failed",
};

interface UploadChunkParams {
  file: any;
  dispatch?: ReduxDispatch;
  overlayMessage?: string;
  fileReader: FileReader;
  sliceStart: number;
  newFileName: string | null;
  fileNameOverride?: string;
  isPrivate?: false;
  fileInformation?: FileInformation;
}

interface ChunkUploadReqeuest {
  fileBase64Chunk: string;
  fileName: string;
  fileType: string;
  fileBegin: boolean;
  fileSize: number;
  newFileName: string | null;
  isPrivate: false;
  fileInformation?: FileInformation;
}

/**
 * uploads a file chunked in base64 format with the help of a FileReader.
 * returns the new uploaded file id from backend
 *
 * this function can be used separately to the other functions if needed.
 * This function will only upload to uploads and adds it to the DB.
 * The new fileId is returned then
 *
 * @param {File} file JS file blob
 * @param {Object} dispatch optional - the redux dispatch object to show the loadingoverlay
 * @param {boolean} hideLoadingOverlayOnFinish optional - hides the loadingoverlay on finished upload
 * @param {String} fileNamePrefix optional - prefix for the saved file in backend
 * @param {boolean} isPrivate optional - defaults to false
 * @param {Object} fileInformation optional - object that contains custom file information
 * @param {Object} folderInformation optional - object that contains custom folder information
 * @returns {*} result.success true/false and result.response.data = the new upload id or the file object
 */
export const startFileUpload = async ({
  file,
  dispatch,
  overlayMessage,
  hideLoadingOverlayOnFinish,
  fileNameOverride,
  isPrivate = false,
  fileInformation = {},
}: FileUploadParams): Promise<FileUploadResult> => {
  const fileReader = new FileReader();

  let chunkedUploadProgress: ChunkUploadProgress = {
    status: UPLOAD_STATE.started,
    sliceStart: 0,
    newFileName: null,
  };

  while (
    chunkedUploadProgress.status !== UPLOAD_STATE.failed &&
    chunkedUploadProgress.status !== UPLOAD_STATE.finished
  ) {
    chunkedUploadProgress = await uploadChunk({
      file,
      dispatch,
      overlayMessage,
      fileReader,
      sliceStart: chunkedUploadProgress.sliceStart,
      newFileName: chunkedUploadProgress.newFileName,
      fileNameOverride,
      isPrivate,
      fileInformation,
    });
    // throttle the request loop because of server side request limiting
    await sleep(100);
  }

  global.log.debug("uploadChunk finished...");
  if (dispatch && hideLoadingOverlayOnFinish) {
    dispatch(hideLoadingOverlayAction());
  }

  if (chunkedUploadProgress.status === UPLOAD_STATE.finished) {
    global.log.debug(`uploadId: ${chunkedUploadProgress.uploadId}`);
    return {
      success: true,
      error: null,
      data: chunkedUploadProgress.file
    };
  } else {
    return {
      success: false,
      error: null,
      data: null,
    };
  }
};

/**
 * uploads only a chunk from a file this function gets called from
 * startChunkedUpload
 *
 * @param {*} file
 * @param {*} dispatch
 * @param {*} overlayMessage
 * @param {*} fileReader
 * @param {*} sliceStart
 * @param {*} newFileName
 * @returns
 */
const uploadChunk = async ({
  file,
  dispatch,
  overlayMessage,
  fileReader,
  sliceStart,
  newFileName,
  fileNameOverride,
  isPrivate = false,
  fileInformation,
}: UploadChunkParams): Promise<ChunkUploadProgress> => {
  return new Promise((resolve, reject) => {
    let nextSlice = sliceStart + getSliceSize(file) + 1;
    let blob = file.slice(sliceStart, nextSlice);

    fileReader.onloadend = async function (event: any) {
      if (event.target.readyState !== FileReader.DONE) {
        return;
      }

      const uploadUrl = getNextJsApiURL("/cms/upload/chunk");

      const requestData: ChunkUploadReqeuest = {
        fileBase64Chunk: event.target.result,
        fileName: fileNameOverride ? fileNameOverride : file.name,
        fileType: file.type,
        fileBegin: sliceStart === 0 ? true : false,
        fileSize: file.size,
        newFileName: newFileName,
        isPrivate: isPrivate,
        fileInformation,
      };

      const result: ResponseResult<any> = await axiosPostRequestClientSide(
        uploadUrl,
        requestData
      );

      if (result.success && result.data.status !== UPLOAD_STATE.failed) {
        const uploadedSize = sliceStart + getSliceSize(file);
        let progressInPercent = Math.floor((uploadedSize / file.size) * 100);
        if (progressInPercent > 100) {
          progressInPercent = 100;
        }
        if (dispatch) {
          dispatch(
            showLoadingOverlayAction(
              `${
                overlayMessage
                  ? overlayMessage
                  : translate("cms:uploadingFiles")
              } ${progressInPercent}%`
            )
          );
        }

        if (nextSlice < file.size) {
          // the chunked upload isnt finished set the next sliceStart to nextSlice
          // and upload the next chunk
          resolve({
            status: UPLOAD_STATE.progress,
            sliceStart: nextSlice,
            newFileName: result.data.newFileName,
          });
          return;
        } else {
          // if the chunked upload is finished add the new inserted upload ID
          resolve({
            status: UPLOAD_STATE.finished,
            sliceStart: nextSlice,
            newFileName: result.data.newFileName,
            uploadId: result.data.uploadId,
            file: result.data.file,
          });
          return;
        }
      } else {
        // there was an error abort the fileupload.
        global.log.error("chunked upload failed");

        if (result?.response?.data?.errorMsg) {
          global.log.debug(result?.response?.data);
          if (result?.response?.data?.info) {
            createToast(
              {
                type: "warning",
                msg: cmsTranslate("fileTypeIncomingVsExpected", {
                  actualFileExt: result.data.info.actualFileExt,
                  incomingFileExt: result.data.info.incomingFileExt,
                }),
              },
              10000
            );
          }
          createToast(
            {
              type: "warning",
              msg: cmsTranslate(result.data.errorMsg),
            },
            10000
          );
        }

        resolve({
          status: UPLOAD_STATE.failed,
          sliceStart: sliceStart,
          newFileName: "",
        });
        return;
      }
    };

    fileReader.readAsDataURL(blob);
  });
};

const getSliceSize = (file: any) => {
  const fileSizeInMB = file.size / (1024 * 1024);
  if (fileSizeInMB > CHUNK_UPLOAD_MAX_SLICE_SIZE_IN_MB) {
    return CHUNK_UPLOAD_DEFAULT_SLICE_SIZE;
  } else {
    return file.size / 2;
  }
};
