import ResponseResult from "@/types/classes/ResponseResult";
import { CmsUserSettings } from "@/types/cmsUserSettings";
import { getNextJsApiURL } from "@/utils/api";
import { axiosPostRequestClientSide } from "@/utils/axiosClientUtil";

export let cmsUserSettings: CmsUserSettings | null = null;

export const updateCmsUserSettings = async (
  data: any
): Promise<ResponseResult<any>> => {
  const updateUserSettingsResult: ResponseResult<any> =
    await axiosPostRequestClientSide(
      getNextJsApiURL(`/cms/manage/pabu/pbcmsusersetting/update`),
      data
    );
  return updateUserSettingsResult;
};
