export default function CmsTopBarCenterControlsStyle() {
  return (
    <style jsx global>{`
      .page-navigation {
        .MuiOutlinedInput-notchedOutline {
          border-color: var(--pb-cms-color);
        }
        .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
          border-color: var(--pb-cms-color);
        }
      }
    `}</style>
  );
}
