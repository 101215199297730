const CmsDynamicListItemCreatorStyle = (): JSX.Element => {
  return (
    <style jsx global>{`
      .cms-dynamic-list-item-creator {
        padding-top: 20px;
        font-size: 2rem !important;
        justify-content: center;
        display: flex;
      }

      .cms-dynamic-list-item-creator button {
        border-radius: 7px;
        margin: 0;
      }

      .layer-1 {
        margin-left: 10px;
      }

      .layer-2 {
        margin-left: 100px;
      }

      .layer-3 {
        margin-left: 200px;
      }
    `}</style>
  );
};

export default CmsDynamicListItemCreatorStyle;
