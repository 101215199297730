import { useAppSelector } from "@/store/store";

export interface CmsLocaleSyncWarningProps {
  children: React.ReactNode;
}

/**
 * Use to wrap other components that should be shown if the strapi and next locales are out of sync
 */
export default function CmsLocaleSyncWarning(props: CmsLocaleSyncWarningProps) {
  const localesInSync = useAppSelector(
    (state) => state.cmsGeneral.localesStatus.localesInSync
  );
  return <>{localesInSync ? null : props.children}</>;
}
