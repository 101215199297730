import ResponseResult from "@/types/classes/ResponseResult";
import { getNextJsApiURL } from "@/utils/api";
import { axiosGetRequestClientSide } from "@/utils/axiosClientUtil";

export const getSearchSuggestions = async (
  searchTerm: string
): Promise<ResponseResult<Array<any>>> => {
  const result: any = await axiosGetRequestClientSide(
    getNextJsApiURL(`/content/pabu/search/suggest/${searchTerm}`)
  );
  if (!result.success) {
    global.log.warn("[getSearchSuggestions] searchSuggestionError");
  }
  return result;
};

export const getSearchResults = async (
  searchTerm: string
): Promise<ResponseResult<any>> => {
  const result: ResponseResult<any> = await axiosGetRequestClientSide(
    getNextJsApiURL(`/content/pabu/search/${searchTerm}`)
  );
  if (!result.success) {
    global.log.warn("[getSearchResults] searchError");
  }
  return result;
};
