import CmsThemeProvider from "@/components/themeProviders/CmsThemeProvider";
import PublicThemeProvider from "@/components/themeProviders/PublicThemeProvider";
import { ComponentType, forwardRef } from "react";

type WithCmsStyleProps = {
  withCmsStyle?: boolean;
  withPublicStyle?: boolean;
};

/**
 * Wraps the given component with either CmsThemeProvider, PublicThmeProvider or nothing
 */
const withStyle = <Props extends object>(Component: ComponentType<Props>) => {
  const WrappedComponent = forwardRef<unknown, Props & WithCmsStyleProps>(
    (props, ref) => {
      if (props.withCmsStyle) {
        return (
          <CmsThemeProvider>
            <Component {...(props as Props)} ref={ref} />
          </CmsThemeProvider>
        );
      } else if (props.withPublicStyle) {
        return (
          <PublicThemeProvider>
            <Component {...(props as Props)} ref={ref} />
          </PublicThemeProvider>
        );
      }
      return <Component {...(props as Props)} ref={ref} />;
    }
  );

  WrappedComponent.displayName = `WithStyle(${
    Component.displayName && Component.name
  })`;

  return WrappedComponent;
};

export default withStyle;
