import PbButton from "@/components/input/pbButton/pbButton";
import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbDropdown, {
  PbDropdownItem,
} from "@/components/input/pbDropdown/pbDropdown";
import PbInputControlled from "@/components/input/pbInputControlled/pbInputControlled";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import { getPageTypeNameLocalized } from "@/services/cmsPageTypeService/cmsPageTypeService";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import {
  cmsResetDraftPageContentToDefaultLocaleThunk,
  cmsResetDraftPageToPublishedThunk,
} from "@/store/slices/cmsEdit/cmsPageThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { DEFAULT_AS_SELECTABLE_VALUE } from "@/utils/constants";
import {
  getDefaultLocale,
  isLocaleDefaultLocale,
} from "@/utils/localizationUtil";
import { isPageUrlRootUrl, isRedirectUrlPointingToPageUrl } from "@/utils/urlUtil";
import { isStoreValueDefaultAllowed } from "@/utils/util";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { useRouter } from "next/router";

export interface CmsPageSettingsProps {}

export default function CmsPageSettings(props: CmsPageSettingsProps) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const page = useAppSelector((state) =>
    state.cmsGeneral.editMode
      ? state.cmsEdit.data?.draftPage
      : state.cmsEdit.data
  );
  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  const canEditUrl =
    !isPageUrlRootUrl(page?.url) && isLocaleDefaultLocale(router.locale!);

  const getAttributePath = (key: string): string => {
    return editView ? `draftPage.${key}` : `${key}`;
  };

  const getDefaultValue = (values: any) => {
    if (!values || (values && values.length === 0)) {
      return undefined;
    }
    if (isStoreValueDefaultAllowed(values)) {
      return -1 + "";
    }
    return values.at(0) + "";
  };

  const backgroundDropdownItems = () => {
    return globalConfig?.layout.bgImages?.data
      ? Object.entries(globalConfig?.layout.bgImages.data)
          .map(([key, value]): PbDropdownItem | undefined => {
            if (key === `${DEFAULT_AS_SELECTABLE_VALUE}`) {
              return {
                value: key,
                name: tCms("configModal-defaultValue"),
              };
            }
            if (!value) {
              return undefined;
            }
            return {
              value: key,
              name: value.strname,
            };
          })
          .filter((item) => item !== undefined)
          .map((item) => item as PbDropdownItem)
      : [];
  };

  const backgroundOptions = backgroundDropdownItems();
  const { showConfirmModal: showResetToDefaultLocaleModal } = useConfirmModal(
    async () => {
      if (!isLocaleDefaultLocale(router.locale!) && page && page.id) {
        try {
          await dispatch(
            cmsResetDraftPageContentToDefaultLocaleThunk(
              page.id,
              process.env.NEXT_PUBLIC_DEFAULT_LOCALE!
            )
          );
          createToastSuccess(tCms("page-synchronizeSuccess"));
        } catch (error: any) {
          global.log.error(error);
          createToastError(tCms("page-synchronizeError"));
        }
      }
    }
  );

  const { showConfirmModal: showResetToPublishedModal } = useConfirmModal(
    async () => {
      if (page && page.id) {
        try {
          await dispatch(cmsResetDraftPageToPublishedThunk());
          createToastSuccess(tCms("page-resetToPublishedSuccess"));
        } catch (error: any) {
          global.log.error(error);
          createToastError(tCms("page-resetToPublishedError"));
        }
      }
    }
  );

  return (
    <div className="cms-modal-input-wrapper-container">
      <ModalInputWrapper
        label={tCms("pageType")}
        description={tCms("pageTypeDescription")}
      >
        {page?.type && (
          <div className="d-flex">
            <span
              style={{
                marginRight: "7px",
              }}
              title={
                tCms("pageType") +
                ": " +
                (getPageTypeNameLocalized(
                  page.type,
                  process.env.NEXT_PUBLIC_CMS_USER_LANGUAGE!
                ) ?? "")
              }
            >
              <PbIcon
                width={15}
                height={15}
                name="table-layout-light"
                style={{ fill: page.type.color ?? "var(--pb-cms-color)" }}
              />
            </span>

            <p style={{ color: "var(--pb-cms-variable-black)" }}>
              <b>
                {getPageTypeNameLocalized(
                  page.type,
                  process.env.NEXT_PUBLIC_CMS_USER_LANGUAGE!
                )}
              </b>
            </p>
          </div>
        )}
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("name")}
        description={tCms("page-typePageNameHere")}
      >
        <PbInputControlled
          withCmsStyle
          label={""}
          value={page?.name}
          onChange={(e) => {
            dispatch(
              updateAttributeAction({
                attributePath: `${getAttributePath("name")}`,
                value: e.target.value,
              })
            );
          }}
          fullWidth
        />
      </ModalInputWrapper>
      {editView && (
        <ModalInputWrapper
          label={tCms("url")}
          description={tCms("page-addPageUrlHere")}
          nonLocalized
        >
          <PbInputControlled
            withCmsStyle
            startAdornment={
              page?.type?.contextPath ? (
                <div className="cms-input-startadornment-chip">
                  <div
                    className="chip-text"
                    title={page.type.contextPath + "/"}
                  >
                    {page.type.contextPath + "/"}
                  </div>
                </div>
              ) : undefined
            }
            fullWidth
            label={""}
            disabled={!canEditUrl}
            value={page.url.replace(page.type.contextPath + "/", "")}
            type="text"
            onChange={(event) => {
              let url = event.target.value;
              if (page.type?.contextPath) {
                url = page.type.contextPath + "/" + event.target.value;
              }
              dispatch(
                updateAttributeAction({
                  attributePath: `${getAttributePath("url")}`,
                  value: url,
                })
              );
            }}
          ></PbInputControlled>
        </ModalInputWrapper>
      )}
      <ModalInputWrapper
        label={tCms("page-disableNavigation")}
        description={tCms("page-selectDisabledNavigation")}
      >
        <PbCheckBox
          isSlider
          value={!page?.hasNavigation}
          onChange={(event) => {
            dispatch(
              updateAttributeAction({
                attributePath: `${getAttributePath("hasNavigation")}`,
                value: !event.target.checked,
              })
            );
          }}
          fullHeight
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("page-disableFooter")}
        description={tCms("page-selectDisableFooter")}
      >
        <PbCheckBox
          isSlider
          value={!page?.hasFooter}
          onChange={(event) => {
            dispatch(
              updateAttributeAction({
                attributePath: `${getAttributePath("hasFooter")}`,
                value: !event.target.checked,
              })
            );
          }}
          fullHeight
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("page-redirectEnabled")}
        description={tCms("page-redirectEnabledDescription")}
        nonLocalized
      >
        <PbCheckBox
          isSlider
          isDisabled={editView && !isLocaleDefaultLocale(router.locale!)}
          value={page?.redirectEnabled}
          onChange={(event) => {
            dispatch(
              updateAttributeAction({
                attributePath: `${getAttributePath("redirectEnabled")}`,
                value: !page?.redirectEnabled,
              })
            );
          }}
          fullHeight
        />
      </ModalInputWrapper>

      <ModalInputWrapper
        label={tCms("page-redirectUrl")}
        description={tCms("page-redirectUrlDescription")}
        nonLocalized
      >
        <PbInputControlled
          withCmsStyle
          label={""}
          disabled={
            !page?.redirectEnabled ||
            (editView && !isLocaleDefaultLocale(router.locale!))
          }
          value={page?.redirectUrl ?? ""}
          onChange={(e) => {
            dispatch(
              updateAttributeAction({
                attributePath: `${getAttributePath("redirectUrl")}`,
                value: e.target.value,
              })
            );
          }}
          fullWidth
          error={editView && isRedirectUrlPointingToPageUrl(page)}
          errorText={tCms("page-redirectUrlIsSameAsUrl")}
        />
      </ModalInputWrapper>
      {globalConfig?.layout.bgImages &&
      globalConfig?.layout.bgImages.values.length > 1 ? (
        <ModalInputWrapper
          label={tCms("page-background")}
          description={tCms("page-selectBackground")}
        >
          <>
            <PbDropdown
              withCmsStyle
              value={page?.strBgImage ? page?.strBgImage.values : undefined}
              onChange={(event) => {
                const selectedValue = parseInt(event.target.value as string);
                dispatch(
                  updateAttributeAction({
                    attributePath: `${getAttributePath("strBgImage")}`,
                    value: {
                      store: "str",
                      storeType: "background",
                      values: selectedValue ? [selectedValue] : [],
                    },
                  })
                );
              }}
              defaultValue={getDefaultValue(
                page?.strBgImage ? page?.strBgImage.values : undefined
              )}
              dropdownList={backgroundOptions}
              itemNameKey="name"
              itemValueKey="value"
            ></PbDropdown>
            {page?.strBgImage &&
            page.strBgImage.values &&
            page.strBgImage.values[0] &&
            page.strBgImage.values[0] != DEFAULT_AS_SELECTABLE_VALUE ? (
              <div
                className={`str-background-${
                  page?.strBgImage && page.strBgImage.values[0]
                    ? page.strBgImage.values[0]
                    : undefined
                }-preview`}
                style={{
                  marginTop: "1rem",
                  height: "200px",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              ></div>
            ) : null}
          </>
        </ModalInputWrapper>
      ) : null}
      {editView && !isLocaleDefaultLocale(router.locale!) && (
        <ModalInputWrapper
          label={tCms("page-synchronize", {
            defaultLocale: tCms(getDefaultLocale()),
          })}
          description={tCms("page-syncToDefaultLocalePage", {
            defaultLocale: tCms(getDefaultLocale()),
          })}
        >
          <PbButton
            value={page.content}
            className="mx-1"
            onClick={() => {
              showResetToDefaultLocaleModal({
                title: tCms("page-confirmSyncToDefaultLocalePage"),
                content: tCms("page-confirmSyncToDefaultLocalePageLong", {
                  defaultLocale: tCms(getDefaultLocale()),
                }),
                acceptBtnText: tCms("yes"),
                denyBtnText: tCms("no"),
                icon: "triangle-exclamation-light",
              });
            }}
          >
            {tCms("syncButton")}
          </PbButton>
        </ModalInputWrapper>
      )}

      {editView && (
        <ModalInputWrapper
          label={tCms("page-resetToPublished")}
          description={tCms("page-resetToPublishedDescription")}
        >
          <PbButton
            onClick={() => {
              showResetToPublishedModal({
                title: tCms("page-confirmResetToPublished"),
                content: tCms("page-confirmResetToPublishedLong"),
                acceptBtnText: tCms("yes"),
                denyBtnText: tCms("no"),
                icon: "triangle-exclamation-light",
              });
            }}
          >
            {tCms("resetButton")}
          </PbButton>
        </ModalInputWrapper>
      )}
    </div>
  );
}
