import ResponseResult from "@/types/classes/ResponseResult";
import { getNextJsApiURL } from "@/utils/api";
import { axiosGetRequestClientSide } from "@/utils/axiosClientUtil";

export const getPropertyDataProviderOptions = async (
  url: string | undefined
): Promise<Array<any>> => {
  if (!url) {
    global.log.warn("[getPropertyDataProviderOptions] no url specified");
    return [];
  }

  const dataProviderListResult: ResponseResult<any> =
    await axiosGetRequestClientSide(url);

  if (!dataProviderListResult.success) {
    global.log.warn(
      "[getPropertyDataProviderOptions] could not get relation list"
    );
    return [];
  }

  return dataProviderListResult.data as Array<any>;
};

export const getPropertyRelationOptions = async (
  locale: string,
  model: string | undefined
): Promise<Array<any>> => {
  if (!model) {
    global.log.warn("[getPropertyRelationOptions] no model specified");
    return [];
  }

  const relationListResult: ResponseResult<any> =
    await axiosGetRequestClientSide(
      getNextJsApiURL(
        `/cms/manage/pabu/properties/relation-options?locale=${locale}&model=${model}`
      )
    );

  if (!relationListResult.success) {
    global.log.warn("[getPropertyRelationOptions] could not get relation list");
    return [];
  }

  return relationListResult.data as Array<any>;
};
