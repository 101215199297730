import { LocalizedObject } from "@/types/strapi";
import { i18n } from "next-i18next";
import { NextRouter } from "next/router";
import { isSSR } from "./util";

/**
 * language selector change function
 *
 * @param {*} nextLocale
 * @param {*} router
 */
export const changeLanguage = (
  nextLocale: string,
  router: NextRouter,
  nextRoute?: string,
  deferTime?: number,
  startEditMode?: boolean
): void => {
  let newRoute = router.asPath;
  if (nextRoute) {
    newRoute = nextRoute;
  } else if (router.asPath.indexOf("?edit=true") > -1) {
    newRoute = router.asPath.replace("?edit=true", "");
  }

  if (startEditMode) {
    newRoute = newRoute + "?edit=true";
  }

  updateLanguageCookie(nextLocale);

  let isNextLocaleDefaultLocale = false;
  if (nextLocale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE) {
    isNextLocaleDefaultLocale = true;
  }

  setTimeout(
    () => {
      if (router && !isSSR()) {
        router.replace(newRoute, undefined, { locale: nextLocale });
      }
    },
    deferTime ? deferTime : 0
  );
};

/**
 * updates the NEXT_LOCALE cookie to the visited/set locale
 * @param {*} locale
 */
export const updateLanguageCookie = (locale: string): void => {
  if (!isSSR()) {
    // get the current language from cookie;

    const currentLanguagePart =
      "; " + document.cookie.split(`; NEXT_LOCALE=`).pop();

    if (currentLanguagePart) {
      const currentLanguage = currentLanguagePart.split(";")[0];
      if (!currentLanguage || (currentLanguage && currentLanguage !== locale)) {
        document.cookie = `NEXT_LOCALE=${locale}; path=/; expires=${new Date(
          Date.now() + 86400 * 1000 * 365
        ).toUTCString()}`;
      }
    }
  }
};

/**
 * returns true if the locale is the default locale
 *
 * @param {string} locale (router.locale) or "de"/"en"...
 * @returns true=default locale / false=non default locale
 */
export const isLocaleDefaultLocale = (locale: string): boolean => {
  return locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE;
};

export const getDefaultLocale = (): string => {
  return process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
};

/**
 * translate function
 *
 * @param {string} messageKey the message key from the localized *.json file (e.g.: common.json)
 * @param {object} values for more information look into https://www.i18next.com/translation-function/interpolation
 * @returns
 */
export const translate = (messageKey: string, values?: any): any => {
  // on startup the i18n object might not be initialized
  if (i18n && i18n.t) {
    return i18n.t(messageKey, values);
  }
  return messageKey;
};

/**
 * finds the default language id from the given object
 * this function looks into the localizedEntity.locale attribute if this was not
 * the default locale the function will search the localizedEntity.localizations
 * array for the default locale id
 *
 * @param {object} localizedEntity localized object with localizations array in it
 * @returns the object id from the default locale
 */
export const findDefaultLocaleIdInLocalizationsArray = (
  localizedEntity: any
) => {
  if (localizedEntity.locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE) {
    return localizedEntity.id;
  } else {
    if (localizedEntity.localizations) {
      const defaultLocaleObject = localizedEntity.localizations.find(
        (localeObject: any) =>
          localeObject.locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE!
      );
      if (defaultLocaleObject) {
        return defaultLocaleObject.id;
      }
    }
  }
  global.log.info(
    `could not find default locale id returning id from parameter 
      locale=${localizedEntity.locale}`
  );
  return localizedEntity.id;
};

/**
 * Gets localized pagename if exists
 * Used for cmsLinkchooser and cmsNavItem
 * @param {*} router useRouter instance
 * @param {*} option autocomplete getOptionLabel / renderOption Parameter
 * @returns
 */
export const getLocalizedPagename = (router: NextRouter, option: any): any => {
  let pageName = option.name;

  if (router.locale !== process.env.NEXT_PUBLIC_DEFAULT_LOCALE) {
    // change pagename to different locale if exists
    const localizedPage = option.localizations.find(
      (localization: any) => localization.locale === router.locale
    );
    if (localizedPage) {
      pageName = localizedPage.name;
    }
  }
  return pageName;
};

/**
 * Gets the corresponding language string from a
 * language code like "de-DE" -> "de"
 *
 * @param locale
 * @returns
 */
export const getLanguageFromLocaleString = (
  locale: string | undefined
): string => {
  if (locale) {
    const intlLocale = new Intl.Locale(locale);
    if (intlLocale) {
      return intlLocale.language;
    }
  }
  return process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
};

/**
 * Currently we use JSON fields to get some attributes localized.
 * They are structured like this (key = locale, value = localized string/object):
 *   {
 *     en: "Page",
 *     de: "Seite"
 *   },
 * This function will try to get the locale you want from the passed locale parameter.
 * If the requested locale does not exist in the current JSON object the default locale
 * will be returned.
 * If the default locale does not exist null is returned.
 *
 * @param localizedObject
 * @param locale
 * @param defaultLocaleOverride can be used to fall back to another locale instead of the
 *  the NEXT_PUBLIC_DEFAULT_LOCALE. This if for example needed to ensure the
 *  NEXT_PUBLIC_CMS_USER_LANGUAGE will be used
 * @returns
 */
export const getLocalizedValue = (
  localizedObject: LocalizedObject,
  locale: string
): any | null => {
  if (!localizedObject) {
    // global.log.error(
    //   "[getLocalizedOrDefaultLocale] localizedObject is null or undefined. returning null"
    // );
    return null;
  }
  let localizedValue = localizedObject[locale];
  if (!localizedValue) {
    // global.log.debug(
    //   `[getLocalizedOrDefaultLocale] localizedObject does not have a key with locale=${locale}.` +
    //     ` Trying to use default locale ${process.env
    //       .NEXT_PUBLIC_DEFAULT_LOCALE!} instead`
    // );
    localizedValue = localizedObject[process.env.NEXT_PUBLIC_DEFAULT_LOCALE!];
  }
  return localizedValue ? localizedValue : null;
};
