import ResponseResult from "@/types/classes/ResponseResult";
import { getNextJsApiURL } from "@/utils/api";
import {
  axiosGetRequestClientSide,
  axiosPostRequestClientSide,
  handleRequestErrorClientSide,
} from "@/utils/axiosClientUtil";
import { translate } from "@/utils/localizationUtil";
import { createToast } from "@/utils/utilComponents";

export const getDynamicList = async (
  dynamicListName: string
): Promise<ResponseResult<any>> => {
  const dynamicListResult: ResponseResult<any> =
    await axiosGetRequestClientSide(
      getNextJsApiURL(`/cms/manage/pabu/dynamiclists/name/${dynamicListName}`)
    );
  if (dynamicListResult.success) {
    global.log.debug(`fetched dynamiclist: ${dynamicListName}`);
  } else {
    global.log.warn(`could not fetch dynamiclist with name: ${dynamicListName}`);
    if (
      dynamicListResult.response?.status &&
      dynamicListResult.response.status !== 404
    ) {
      createToast({
        type: "warning",
        msg: translate("cms:dynamicListFetchError"),
      });
    }
  }
  return dynamicListResult;
};

export const updateDynamicList = async (
  editDynamicList: any
): Promise<ResponseResult<any>> => {
  const dynamicListResult = await axiosPostRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/dynamiclists/${editDynamicList.id}`),
    editDynamicList
  );
  return dynamicListResult;
};

export const createDynamicList = async (dynamicListName: string) => {
  const result = await axiosPostRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/dynamiclists`),
    {
      name: dynamicListName,
    }
  );
  if (result.success) {
    global.log.debug(`created dynamiclist with name ${dynamicListName}`);
  } else {
    handleRequestErrorClientSide(
      result.error,
      "could not create dynamiclist [createDynamicList]"
    );
  }
  return result;
};