import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { SvgIds } from "@/components/pbIcon/svg";
import { ScrollStateType } from "@/types/types";
import { ReduxState } from "../../store";

export interface GeneralState {
  loadingOverlay: {
    visible: boolean;
    overlayMessage: string;
  };
  confirmModal: {
    /**
     * flag for show/hide the confirm modal
     */
    isOpen: boolean;
    /**
     * flag that determines that a user action has been done
     * true if the user clicked something (performed any action)
     */
    userActionPerformed: boolean;
    /**
     * the modal was confirmed via the confirm/accept button (user choice was positive)
     */
    isConfirmed: boolean;
    /**
     * the modal was denied via the deny button (user choice was negative)
     */
    isDenied: boolean;
    /**
     * like isConfirmed/isDenied -> the modal was canceled/hidden
     * (user choice was neutral, the user canceled the confirm modal)
     */
    isCanceled: boolean;
    /**
     * true = on X close button the deny function will be triggered.
     * false = the cancel (hide) function will be triggered
     */
    xIsDeny: boolean;
    /**
     * confirm modal title
     */
    title: string;
    /**
     * confrim modal text prompt
     */
    content: string | React.ReactNode;
    /**
     * confirm button label
     */
    acceptBtnText: string;
    /**
     * deny button label
     */
    denyBtnText: string;
    /**
     * cancel (hide) button text (the button that just hides the modal)
     */
    cancelBtnText: string;
    /**
     * the icon that will be displayed in the confirm modal body
     */
    icon?: SvgIds | undefined;
    /**
     * className added to the modal
     */
    className: string;
    /**
     * hides the deny button
     */
    hideDenyBtn: boolean;
    /**
     * shows the cancel (hide) button
     */
    showCancelBtn: boolean;
    /**
     * true = hides the modal close icon
     */
    hideCloseIconBtn: boolean;
    /**
     * if true cms classes are set in the confirm modal
     */
    isCms: boolean;
  };
  clipboard: {
    // look into the useReduxClipboard hook
    clipboardData: any;
    clipboardSet: boolean;
    clipboardCanceled: boolean;
  };
  dynamicLists: Array<any>;
  beforeunloadEvent: boolean;
  redirectUrl: string;
  // page visibility (https://developer.mozilla.org/de/docs/Web/API/Page_Visibility_API)
  // used by usePageVisibility hook
  isPageVisible: boolean;
  showPwaPrompt: boolean;
  scroll: {
    scrollState: ScrollStateType;
    position: number;
  };
  navigation: {
    searchBarActive: boolean;
  };
}

export interface ShowConfirmModalActionParams {
  title: string;
  content: string | React.ReactNode;
  acceptBtnText?: string;
  denyBtnText?: string;
  cancelBtnText?: string;
  xIsDeny?: boolean;
  icon?: SvgIds | undefined;
  className?: string;
  hideDenyBtn?: boolean;
  showCancelBtn?: boolean;
  hideCloseIconBtn?: boolean;
  isCms?: boolean;
}

export const initialState: GeneralState = {
  loadingOverlay: {
    visible: false,
    overlayMessage: "",
  },
  confirmModal: {
    isOpen: false,
    userActionPerformed: false,
    isConfirmed: false,
    isDenied: false,
    isCanceled: false,
    xIsDeny: false,
    title: "",
    content: "",
    acceptBtnText: "",
    denyBtnText: "",
    cancelBtnText: "",
    icon: undefined,
    className: "",
    hideDenyBtn: false,
    showCancelBtn: false,
    hideCloseIconBtn: false,
    isCms: false,
  },
  clipboard: {
    // used by useReduxClipboard hook
    clipboardData: null,
    clipboardSet: false,
    clipboardCanceled: false,
  },
  dynamicLists: [],
  beforeunloadEvent: false,
  redirectUrl: "",
  // page visibility (https://developer.mozilla.org/de/docs/Web/API/Page_Visibility_API)
  // used by usePageVisibility hook
  isPageVisible: true,
  showPwaPrompt: false,
  scroll: {
    scrollState: null,
    position: -1,
  },
  navigation: {
    searchBarActive: false,
  },
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    showConfirmModalAction(
      state,
      action: PayloadAction<ShowConfirmModalActionParams>
    ) {
      state.confirmModal.isOpen = true;
      state.confirmModal.userActionPerformed = true;
      state.confirmModal.isConfirmed = false;
      state.confirmModal.isDenied = false;
      state.confirmModal.isCanceled = false;
      state.confirmModal.xIsDeny = action.payload.xIsDeny
        ? action.payload.xIsDeny
        : false;
      state.confirmModal.title = action.payload.title;
      state.confirmModal.content = action.payload.content;
      state.confirmModal.acceptBtnText = action.payload.acceptBtnText
        ? action.payload.acceptBtnText
        : "confirm";
      state.confirmModal.denyBtnText = action.payload.denyBtnText
        ? action.payload.denyBtnText
        : "cancel";
      state.confirmModal.cancelBtnText = action.payload.cancelBtnText
        ? action.payload.cancelBtnText
        : "";
      state.confirmModal.icon = action.payload.icon
        ? action.payload.icon
        : undefined;
      state.confirmModal.className = action.payload.className
        ? action.payload.className
        : "";
      state.confirmModal.hideDenyBtn = action.payload.hideDenyBtn
        ? true
        : false;
      state.confirmModal.showCancelBtn = action.payload.showCancelBtn
        ? true
        : false;
      state.confirmModal.hideCloseIconBtn = action.payload.hideCloseIconBtn
        ? true
        : false;
      state.confirmModal.isCms = !!action.payload.isCms;
    },
    confirmConfirmModalAction(state) {
      state.confirmModal.isOpen = false;
      state.confirmModal.userActionPerformed = true;
      state.confirmModal.isConfirmed = true;
      state.confirmModal.isCanceled = false;
      state.confirmModal.isDenied = false;
    },
    denyConfirmModalAction(state) {
      state.confirmModal.isOpen = false;
      state.confirmModal.userActionPerformed = true;
      state.confirmModal.isConfirmed = false;
      state.confirmModal.isDenied = true;
      state.confirmModal.isCanceled = false;
    },
    resetConfirmModalAction(state) {
      state.confirmModal = initialState.confirmModal;
    },
    cancelConfirmModalAction(state) {
      state.confirmModal.isOpen = false;
      state.confirmModal.userActionPerformed = true;
      state.confirmModal.isConfirmed = false;
      state.confirmModal.isDenied = false;
      state.confirmModal.isCanceled = true;
    },
    showLoadingOverlayAction(state, action: PayloadAction<string | undefined>) {
      state.loadingOverlay.visible = true;
      state.loadingOverlay.overlayMessage = action.payload ?? "";
    },
    hideLoadingOverlayAction(state) {
      state.loadingOverlay.visible = false;
      state.loadingOverlay.overlayMessage = "";
    },
    setLoadingOverlayMessageAction(state, action: PayloadAction<string>) {
      state.loadingOverlay.overlayMessage = action.payload;
    },
    enableBeforeunloadAction(state) {
      state.beforeunloadEvent = true;
    },
    disableBeforeunloadAction(state) {
      state.beforeunloadEvent = false;
    },
    redirectStartAction(state, action: PayloadAction<string>) {
      global.log.debug(`redirecting to ${action.payload}...`);
    },
    setPageVisibilityAction(state, action: PayloadAction<boolean>) {
      state.isPageVisible = action.payload;
    },
    setClipboardAction(state, action: PayloadAction<any>) {
      state.clipboard.clipboardData = action.payload;
      state.clipboard.clipboardSet = true;
      state.clipboard.clipboardCanceled = false;
    },
    cancelClipboardAction(state) {
      state.clipboard.clipboardData = null;
      state.clipboard.clipboardSet = false;
      state.clipboard.clipboardCanceled = true;
    },
    resetClipBoardAction(state) {
      state.clipboard.clipboardData = null;
      state.clipboard.clipboardSet = false;
      state.clipboard.clipboardCanceled = false;
    },
    setShowPwaPromptAction(state, action: PayloadAction<boolean>) {
      state.showPwaPrompt = action.payload;
    },
    updateDynamicListAction(state, action: PayloadAction<any>) {
      state.dynamicLists = state.dynamicLists.some(
        (dynamicList) => dynamicList.name === action.payload.name
      )
        ? state.dynamicLists.map((dynamicList) =>
            dynamicList.name === action.payload.name
              ? action.payload
              : dynamicList
          )
        : [...state.dynamicLists, action.payload];
    },
    addCreatedDynamicListAction(state, action: PayloadAction<any>) {
      return {
        ...state,
        dynamicLists: [...state.dynamicLists, action.payload],
      };
    },
    showSearchBar(state) {
      state.navigation.searchBarActive = true;
    },
    hideSearchBar(state) {
      state.navigation.searchBarActive = false;
    },
    setScrollState(
      state,
      action: PayloadAction<{
        scrollState: ScrollStateType;
        position: number;
      }>
    ) {
      state.scroll.scrollState = action.payload.scrollState;
      state.scroll.position = action.payload.position;
    },
  },

  extraReducers: (builder) => {},
});

export const {
  showConfirmModalAction,
  confirmConfirmModalAction,
  denyConfirmModalAction,
  cancelConfirmModalAction,
  resetConfirmModalAction,
  showLoadingOverlayAction,
  hideLoadingOverlayAction,
  setLoadingOverlayMessageAction,
  enableBeforeunloadAction,
  disableBeforeunloadAction,
  redirectStartAction,
  setPageVisibilityAction,
  setClipboardAction,
  cancelClipboardAction,
  resetClipBoardAction,
  setShowPwaPromptAction,
  updateDynamicListAction,
  addCreatedDynamicListAction,
  showSearchBar,
  hideSearchBar,
  setScrollState,
} = generalSlice.actions;

export const selectGeneralState = (state: ReduxState) =>
  state.general?.loadingOverlay;

export default generalSlice.reducer;
