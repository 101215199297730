import PbGenericModal from "@/components/genericModal/pbGenericModal";
import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import {
  createDynamicListAndInitEditThunk,
  getDynamicListAndInitEditThunk,
  resetDynamicListModalChangesThunk,
  sendUpdateDynamicListThunk,
} from "@/store/slices/cmsEdit/cmsDynamicListThunks";
import { deleteAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import {
  hideDynamicListModalAction,
  refreshRenderKey,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CmsDynamicListEditorForm from "./cmsDynamicListEditorForm";

function CmsDynamicListEditorModal() {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();

  const { showConfirmModal } = useConfirmModal(() => {
    dispatch(resetDynamicListModalChangesThunk());
    dispatch(refreshRenderKey());
  });

  const dynamicListEditorOpen = useAppSelector(
    (state) => state.cmsGeneral.dynamicListModal?.dynamicListEditorOpen
  );
  const dynamicListName = useAppSelector(
    (state) => state.cmsGeneral.dynamicListModal.dynamicListName
  );
  const dynamicListDoesNotExist = useAppSelector(
    (state) => state.cmsGeneral.dynamicListModal.dynamicListDoesNotExist
  );
  const editDynamicList = useAppSelector(
    (state) => state.cmsEdit.data?.dynamicList?.editDynamicList
  );

  const onHide = () => {
    dispatch(hideDynamicListModalAction());
    dispatch(deleteAttributeAction({ attributePath: "footer" }));
  };

  const onSave = async () => {
    try {
      await dispatch(sendUpdateDynamicListThunk());
      createToastSuccess(tCms("dynamicListFooterUpdateSuccess"));
    } catch (e) {
      createToastError(tCms("dynamicListFooterUpdateError"));
    }
  };

  const resetChanges = () => {
    showConfirmModal({
      title: tCms("resetChanges"),
      content: tCms("resetChangesConfirmation"),
      acceptBtnText: tCms("restore"),
      denyBtnText: tCms("cancel"),
      icon: "triangle-exclamation-light",
    });
  };

  useEffect(() => {
    if (dynamicListEditorOpen) {
      dispatch(getDynamicListAndInitEditThunk(dynamicListName));
    }
  }, [dynamicListEditorOpen, dispatch, dynamicListName]);

  return (
    <PbGenericModal
      className="cms-modal cms-dynamic-list-editor-modal"
      title={tCms("footer-configureFooterType", {
        type: tCms("footer-" + dynamicListName),
      })}
      open={dynamicListEditorOpen}
      buttonCancelLabel={tCms("reset")}
      buttonConfirmLabel={tCms("acceptAndSave")}
      hideCloseIcon={false}
      onCloseIconClick={onHide}
      onCancelClick={resetChanges}
      onConfirmClick={onSave}
      muiDialogProps={{ fullWidth: true, maxWidth: "lg" }}
    >
      <Container fluid>
        {dynamicListDoesNotExist ? (
          <Row>
            <Col xs={12}>
              {tCms("footer-dynamicListDoesNotExist", {
                dynamicListName,
              })}
            </Col>
            <Col xs={12}>
              <PbButton
                className="cms-btn-add-content"
                title={tCms("footer-createList")}
                onClick={async () => {
                  try {
                    await dispatch(
                      createDynamicListAndInitEditThunk(dynamicListName)
                    );
                  } catch (e) {
                    createToastError(tCms("dynamicListCreateError"));
                  }
                }}
                startIcon={
                  <PbIcon
                    name="plus-light"
                    width={20}
                    height={20}
                    className="svg-fill-white"
                  />
                }
              >
                {tCms("footer-createList")}
              </PbButton>
            </Col>
          </Row>
        ) : editDynamicList ? (
          <CmsDynamicListEditorForm dynamicListName={dynamicListName} />
        ) : (
          tCms("loadingDotDotDot")
        )}
      </Container>
    </PbGenericModal>
  );
}

export default CmsDynamicListEditorModal;
