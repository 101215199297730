import {
  cancelConfirmModalAction,
  confirmConfirmModalAction,
  denyConfirmModalAction,
} from "@/store/slices/general/generalSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { Col, Row } from "react-bootstrap";
import PbGenericModal from "../genericModal/pbGenericModal";
import { PbIcon } from "../pbIcon/PbIcon";

export default function ConfirmModal(): JSX.Element {
  const confirmModalConfig = useAppSelector(
    (state) => state.general.confirmModal
  );
  const dispatch = useAppDispatch();
  return (
    <PbGenericModal
      className={confirmModalConfig.isCms ? "cms-modal" : "confirm-modal"}
      title={confirmModalConfig.title}
      open={confirmModalConfig.isOpen}
      buttonCancelLabel={confirmModalConfig.cancelBtnText}
      buttonConfirmLabel={confirmModalConfig.acceptBtnText}
      buttonDenyLabel={confirmModalConfig.denyBtnText}
      hideCloseIcon={confirmModalConfig.hideCloseIconBtn}
      onCloseIconClick={
        confirmModalConfig.xIsDeny
          ? () => {
              dispatch(denyConfirmModalAction());
            }
          : () => {
              dispatch(cancelConfirmModalAction());
            }
      }
      onCancelClick={
        confirmModalConfig.showCancelBtn
          ? () => {
              dispatch(cancelConfirmModalAction());
            }
          : undefined
      }
      onConfirmClick={() => {
        dispatch(confirmConfirmModalAction());
      }}
      onDenyClick={
        confirmModalConfig.hideDenyBtn
          ? undefined
          : () => {
              dispatch(denyConfirmModalAction());
            }
      }
      muiDialogProps={{ fullWidth: true }}
    >
      <>
        {confirmModalConfig.icon && (
          <Row>
            <Col>
              <div className="text-center">
                <PbIcon name={confirmModalConfig.icon} width={70} height={70} />
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div className="text-center mt-5">
              <p className="confirm-modal-text">{confirmModalConfig.content}</p>
            </div>
          </Col>
        </Row>
      </>
    </PbGenericModal>
  );
}
