/**
 * Represents the props for the PbInputLabel component.
 */
export interface PbInputLabelProps {
  /**
   * The label text for the input.
   */
  label: string;

  /**
   * An optional additional class name for styling purposes.
   */
  className?: string;

  /**
   * Indicates whether the input is mandatory.
   */
  mandatory?: boolean;

  /**
   * Indicates whether a line break should be rendered after the label.
   */
  lineBreak?: boolean;

  tooltip?: string;
}

/**
 * PbInputLabel component represents a label for an input field.
 * @param props - The component properties.
 * @returns A JSX element representing the PbInputLabel.
 */
const PbInputLabel = (props: PbInputLabelProps): JSX.Element => {
  return (
    <>
      <label className={`cms-input-label ${props.className || ""}`} title={props.tooltip}>
        {props.label}
        {props.mandatory && <span className="cms-input-mandatory">*</span>}
      </label>
      {props.lineBreak && <br />}
    </>
  );
};

export default PbInputLabel;
