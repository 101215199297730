import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { getNextJsApiURL, getStrapiURLClientSide } from "./api";
import { IMAGE_PLACEHOLDER } from "./constants";

/**
 * renderImage
 * @param {Object} imgOrMimg managedImage with .file (the actual image)
 * @param {boolean} isThumbnail if true strapi thumbnail path is returned else strapi url
 * @returns {string} Path of Image
 */
export const renderImage = (imgOrMimg: any, isThumbnail: boolean): string => {
  if (!imgOrMimg) {
    return "";
  }

  if (typeof imgOrMimg === "string") {
    return imgOrMimg;
  }

  // this is needed to have a actual javascript file fallback (used in media manager and file input)
  if (typeof window !== "undefined" && imgOrMimg.file instanceof File) {
    // File is not defined on serverside so you have to check
    // if this is running in browser first nodejs will throw a
    // ReferenceError: File is not defined
    return URL.createObjectURL(imgOrMimg.file);
  }

  if (imgOrMimg.file && imgOrMimg.file.url) {
    // Managed-Image:
    if (isThumbnail && imgOrMimg.file.formats?.thumbnail?.url) {
      // Note: file.formats only exist for public files
      return getStrapiURLClientSide(imgOrMimg.file.formats.thumbnail.url);
    } else if (imgOrMimg.isPrivate) {
      // Private:
      return getNextJsApiURL(imgOrMimg.file.url);
    } else {
      // Public OR missing file.formats:
      return getStrapiURLClientSide(imgOrMimg.file.url);
    }
  }

  // Note: Use this only for public files. (file.formats only exist for public files)
  if (imgOrMimg.url) {
    // Case for using a specific strapi-image-object directly.
    // For example: small, like in gallery.
    return getStrapiURLClientSide(imgOrMimg.url);
  }

  return IMAGE_PLACEHOLDER;
};

/**
 * Get the MaxWidth for the "span" of a next.js-Image
 *
 * All params are optional
 * @param {number} imgWidth in px
 * @param {number} imgHeight in px
 * @param {number | string} maxWidth in px | in %
 * @param {number} maxHeight in px
 *
 * @returns {number | string} maxWidth in px | original maxWidth in %
 **/
export const calculateMaxWidth = (
  imgWidth: number,
  imgHeight: number,
  maxWidth: number,
  maxHeight: number
): number => {
  const scaledMaxWidth = (maxHeight / imgHeight) * imgWidth;

  if (!scaledMaxWidth) {
    if (maxWidth) {
      // only maxWidth (no MaxHeight) is set
      return maxWidth;
    }
    // no maxWidth or maxHeight set
    return imgWidth;
  }

  if (!imgWidth && !imgHeight) {
    // no img set -> placeholder image rendered
    // need to keep dimension (512:512)
    if (maxHeight) {
      // placeholder will be rendered with maxHeight so the maxWidth needs to be the same
      return maxHeight;
    } else {
      // no maxHeight set -> original sized placeholder image will be rendered
      return 512;
    }
  }

  if (maxWidth && scaledMaxWidth > maxWidth) {
    // maxWidth and maxHeight set, img bigger than maxWidth
    return maxWidth;
  }
  // maxWidth and maxHeight set, img smaller than maxWidth
  return scaledMaxWidth;
};

/**
 * takes the height and width settings of a content element and returns an object.
 * That object is needed, because there are content elements with different height and width definitions for different view ports.
 * Also converts a number to a string ending with px to use in the style
 * @param {*} cssDimension
 * @returns {
 * mobileDimension
 * tabletDimension
 * desktopDimension
 * wqhdDimension
 * }
 */
export const createImageDimensionObject = (
  cssDimension: any
): {
  mobile: string;
  tablet: string;
  desktop: string;
  wqhd: string;
} => {
  if (typeof cssDimension !== "object") {
    let tempDimension = convertImageDimensionObject(cssDimension);
    return {
      mobile: tempDimension,
      tablet: tempDimension,
      desktop: tempDimension,
      wqhd: tempDimension,
    };
  } else {
    return {
      mobile: convertImageDimensionObject(cssDimension.mobile),
      tablet: convertImageDimensionObject(cssDimension.tablet),
      desktop: convertImageDimensionObject(cssDimension.desktop),
      wqhd:
        convertImageDimensionObject(cssDimension.wqhd) === "auto"
          ? convertImageDimensionObject(cssDimension.desktop)
          : convertImageDimensionObject(cssDimension.wqhd),
    };
  }
};

const convertImageDimensionObject = (cssDimension: any): string => {
  let returnCssDimension;
  if (
    typeof cssDimension === "string" &&
    cssDimension.match(/^\d+$/) === null
  ) {
    returnCssDimension = cssDimension;
  } else if (
    typeof cssDimension === "number" ||
    (cssDimension && cssDimension.match(/^\d+$/) !== null)
  ) {
    returnCssDimension = `${cssDimension}px`;
  } else {
    returnCssDimension = "auto";
  }

  return returnCssDimension;
};

const convertMaxImageSizeToString = (cssDimension: string): string => {
  let cssString;
  if (
    typeof cssDimension === "string" &&
    cssDimension.match(/^\d+$/) === null
  ) {
    cssString = cssDimension;
  } else if (
    typeof cssDimension === "number" ||
    (cssDimension && cssDimension.match(/^\d+$/) !== null)
  ) {
    cssString = `${cssDimension}px`;
  } else {
    cssString = "100%";
  }
  return cssString;
};

/**
 * TODO: if this works fine combine both dimension functions to one (controlled by parameter)
 * converts the maxDimension to a string as to not have undefined in the css stylesheet
 * @param {*} maxDimension
 * @returns
 */
export const convertImageMaxDimension = (
  maxDimension: any
): {
  mobile: string;
  tablet: string;
  desktop: string;
  wqhd: string;
} => {
  if (typeof maxDimension !== "object") {
    let tempDimension = convertMaxImageSizeToString(maxDimension);
    return {
      mobile: tempDimension,
      tablet: tempDimension,
      desktop: tempDimension,
      wqhd: tempDimension,
    };
  } else {
    return {
      mobile: convertMaxImageSizeToString(maxDimension.mobile),
      tablet: convertMaxImageSizeToString(maxDimension.tablet),
      desktop: convertMaxImageSizeToString(maxDimension.desktop),
      wqhd:
        convertMaxImageSizeToString(maxDimension.wqhd) === "100%"
          ? convertMaxImageSizeToString(maxDimension.desktop)
          : convertMaxImageSizeToString(maxDimension.wqhd),
    };
  }
};

type imagePositionFitValue =
  | "cover"
  | "contain"
  | "coverTop"
  | "coverBottom"
  | "";

type imageFitReturnType = "cover" | "contain";
/**
 * Returns the corresponding CSS object-fit value
 *
 * @param {String} cfgValue the cfg value from cfgImageObjectFit
 * @returns {String} css attribute value
 */
export const imageObjectFitValue = (
  cfgValue: imagePositionFitValue
): imageFitReturnType => {
  switch (cfgValue) {
    case "cover":
    case "coverTop":
    case "coverBottom":
      return "cover";
    case "contain":
    default:
      return "contain";
  }
};

type imagePositionReturnType = "center" | "top" | "bottom";
/**
 * returns the corresponding CSS object-position value
 *
 * @param {String} cfgValue the cfg value from cfgImageObjectFit
 * @returns {String} css attribute value
 */
export const imageObjectPositionValue = (
  cfgValue: imagePositionFitValue
): imagePositionReturnType => {
  switch (cfgValue) {
    case "coverTop":
      return "top";
    case "coverBottom":
      return "bottom";
    case "contain":
    case "cover":
    default:
      return "center";
  }
};

type objectFitReturnType = "cover" | "contain" | "none";
/**
 * Returns the corresponding CSS object-fit value
 * @param {*} cfgValue the cfg value from cfgImageObjectFit
 * @returns "cover"/"none"/"contain" (default)
 */
export const getObjectFitValue = (cfgValue: string): objectFitReturnType => {
  switch (cfgValue) {
    case "objectfit-cover":
      return "cover";
    case "objectfit-none":
      return "none";
    case "objectfit-contain":
    default:
      return "contain";
  }
};

/**
 * Checks if the given file or managedfile is an image or
 * SVG that can be displayed in a <img> component
 * @param {*} fileOrManagedFile
 * @returns
 */
export const isImage = (fileOrManagedFile: any): boolean => {
  if (!fileOrManagedFile) {
    return false;
  }
  if (fileOrManagedFile instanceof File) {
    // check type attribute (javascript file)
    if (fileOrManagedFile.type.includes("image")) {
      return true;
    }
    return false;
  } else if (fileOrManagedFile.file && fileOrManagedFile.file.mime) {
    // check mime attribute (managedfile)
    if (fileOrManagedFile.file.mime.includes("image")) {
      return true;
    }
  }
  return false;
};

export const getFavIcons = () => {
  const favicon32 =
    globalConfig?.logo?.favicon32x32 &&
    getStrapiURLClientSide(globalConfig?.logo?.favicon32x32.url);

  const favicon64 =
    globalConfig?.logo?.favicon64x64 &&
    getStrapiURLClientSide(globalConfig.logo.favicon64x64.url);

  const favicon128 =
    globalConfig?.logo?.favicon128x128 &&
    getStrapiURLClientSide(globalConfig.logo.favicon128x128.url);

  const favicon192 =
    globalConfig?.logo?.favicon192x192 &&
    getStrapiURLClientSide(globalConfig.logo.favicon192x192.url);

  const faviconAndroid =
    globalConfig?.logo?.androidicon &&
    getStrapiURLClientSide(globalConfig.logo.androidicon.url);

  const faviconApple120 =
    globalConfig?.logo?.appletouchicon120x120 &&
    getStrapiURLClientSide(globalConfig.logo.appletouchicon120x120.url);

  const faviconApple152 =
    globalConfig?.logo?.appletouchicon152x152 &&
    getStrapiURLClientSide(globalConfig.logo.appletouchicon152x152.url);

  const faviconApple167 =
    globalConfig?.logo?.appletouchicon167x167 &&
    getStrapiURLClientSide(globalConfig.logo.appletouchicon167x167.url);

  const faviconApple180 =
    globalConfig?.logo?.appletouchicon180x180 &&
    getStrapiURLClientSide(globalConfig.logo.appletouchicon180x180.url);

  return {
    favicon32,
    favicon64,
    favicon128,
    favicon192,
    faviconAndroid,
    faviconApple120,
    faviconApple152,
    faviconApple167,
    faviconApple180,
  };
};
