import CmsImage from "@/components/cms/cmsImage/cmsImage";
import CmsMediaManagerFileReferenceList from "@/components/cms/cmsMediaManager/cmsMediaManagerFileReferenceList";
import PbButton from "@/components/input/pbButton/pbButton";
import PbInputControlled from "@/components/input/pbInputControlled/pbInputControlled";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import FilePlaceholderIcon from "@/components/util/filePlaceholderIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import {
  displayNameWithoutFileExt,
  fileNameExt,
} from "@/services/cmsMediaManager/cmsMediaManagerService";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { startFileUpload } from "@/services/upload/uploadService";
import {
  mediaManagerModalDiscardDetailView,
  mediaManagerModalRefreshCurrentFolderAction,
  mediaManagerTriggerRefreshOnClose,
  updateAttributeAction,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import {
  cmsMediaManagerFileDeleteThunk,
  cmsMediaManagerFileUpdateThunk,
  cmsMediaManagerFindFileReferencesThunk,
} from "@/store/slices/cmsGeneral/cmsMediaManagerThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { StrapiPbFile, StrapiUploadFile } from "@/types/strapi";
import { getStrapiURLClientSide } from "@/utils/api";
import { createPersistentDownloadUrlFromFile } from "@/utils/urlUtil";
import { copyToClipboard, deepImmutableCopy } from "@/utils/util";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { InputAdornment } from "@mui/material";
import clsx from "clsx";
import { useRouter } from "next/router";
import { ChangeEvent, useEffect, useRef, useState } from "react";

type FileReferenceAction = "REPLACE" | "DELETE" | undefined;

export default function CmsMediaManagerFileDetails() {
  const tCms = useCmsTranslation();
  const currentItem = useAppSelector(
    (state) =>
      state.cmsGeneral.mediaManagerModal.currentItem as StrapiUploadFile
  );
  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);

  const files = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.content.files
  );
  const currentFolder = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.currentFolder
  );
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);
  const [file, setFile] = useState<StrapiUploadFile>(currentItem);
  const [fileReferenceAction, setFileReferenceAction] =
    useState<FileReferenceAction>(undefined);
  const [replaceFile, setReplaceFile] = useState<any>(null);
  const [replaceFileUrl, setReplaceFileUrl] = useState<any>("");
  const replaceFileInput = useRef<any>(null);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { showConfirmModal } = useConfirmModal(
    async () => {
      if (fileReferenceAction === "DELETE") {
        try {
          await dispatch(cmsMediaManagerFileDeleteThunk(file.id));
          createToastSuccess(tCms("managedFileDeletedSuccess"));
          dispatch(mediaManagerModalRefreshCurrentFolderAction());
          setFileReferenceAction(undefined);
          dispatch(mediaManagerTriggerRefreshOnClose());
        } catch (err) {
          createToastError(tCms("managedFileDeletedError"));
        }
      } else if (fileReferenceAction === "REPLACE") {
        openReplaceFileInput();
      }
    },
    () => {
      setFileReferenceAction(undefined);
    }
  );

  const selectedLocale = useAppSelector((state) => {
    if (!state.cmsGeneral.mediaManagerModal.locale) {
      return router.locale!;
    }
    return state.cmsGeneral.mediaManagerModal.locale;
  });

  const handleUpdateEvent = async () => {
    setConfirmButtonDisabled(true);
    const fileDisplayname = displayNameWithoutFileExt(file.name);
    if (!fileDisplayname || fileDisplayname === "") {
      createToastError(tCms("filenameIsWrong"));
      setConfirmButtonDisabled(false);
      return;
    }

    let replacedFilename = "";
    if (replaceFile !== null) {
      const fileUploadResult = await startFileUpload({
        file: replaceFile,
        dispatch: dispatch,
        hideLoadingOverlayOnFinish: true,
        overlayMessage: tCms("uploadingFiles"),
        fileNameOverride: file.name,
        fileInformation: {
          folder: currentFolder?.id,
          strapiUploadUpdateId: file.id,
        },
      });

      if (!fileUploadResult.success) {
        createToastError("managedFileCreateError");
        setConfirmButtonDisabled(false);
        return;
      }

      dispatch(mediaManagerTriggerRefreshOnClose());
      replacedFilename = fileUploadResult.data!.name;
    }

    const filePayload = deepImmutableCopy(file);

    if (replacedFilename !== "") {
      setFile((prevState) => {
        return {
          ...prevState,
          name: replacedFilename,
        };
      });
      filePayload.name = replacedFilename;
    }

    try {
      await dispatch(cmsMediaManagerFileUpdateThunk(filePayload));
      createToastSuccess(tCms("managedFileUpdatedSuccess"));
      const fileIndex = files.findIndex(
        (existingFile) => existingFile.id === filePayload.id
      );
      if (fileIndex !== -1) {
        dispatch(
          updateAttributeAction({
            attributePath: `mediaManagerModal.content.files[${fileIndex}].name`,
            value: filePayload.name,
          })
        );
      }
      setReplaceFile(null);
      setReplaceFileUrl("");
      dispatch(
        mediaManagerModalRefreshCurrentFolderAction({
          selectItem: file,
        })
      );
    } catch (err) {
      createToastError(tCms("managedFileUpdatedError"));
    }
    setConfirmButtonDisabled(false);
  };

  const handleFileDelete = async () => {
    try {
      const fileReferenceList = await dispatch(
        cmsMediaManagerFindFileReferencesThunk(file)
      );
      setFileReferenceAction("DELETE");
      showConfirmModal({
        title: tCms("deleteManagedFile"),
        content: (
          <CmsMediaManagerFileReferenceList
            fileReferenceList={fileReferenceList}
          />
        ),
        acceptBtnText: tCms("delete"),
        denyBtnText: tCms("cancel"),
        xIsDeny: true,
        icon: "circle-exclamation-light",
      });
    } catch (err) {
      createToastError(tCms("unexpectedError"));
    }
  };

  const handleFileReplace = async () => {
    try {
      const fileReferenceList = await dispatch(
        cmsMediaManagerFindFileReferencesThunk(file)
      );
      setFileReferenceAction("REPLACE");
      showConfirmModal({
        title: tCms("managedfile-replace-file"),
        content: (
          <CmsMediaManagerFileReferenceList
            fileReferenceList={fileReferenceList}
          />
        ),
        acceptBtnText: tCms("confirm"),
        denyBtnText: tCms("cancel"),
        xIsDeny: true,
        icon: "circle-exclamation-light",
      });
    } catch (err) {
      createToastError(tCms("unexpectedError"));
    }
  };

  const handleFileNameChangeEvent = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let newName = event.target.value;
    const fileExt = fileNameExt(file.name);
    if (newName.includes(".")) {
      newName = newName.replaceAll(".", "");
    }
    newName = newName + fileExt;
    setFile((prevState) => {
      return {
        ...prevState,
        name: newName,
      };
    });
  };

  const handlePbFileChangeEvent = (key: string, value: string) => {
    setFile((prevState) => {
      const newPbFile: StrapiPbFile = deepImmutableCopy(prevState.pbFile);
      const localizedPbFile: StrapiPbFile = deepImmutableCopy(pbFileByLocale());
      const updatePbFileLocalization = (file: StrapiPbFile) => {
        const index = file.localizations.findIndex(
          (pbFile) => pbFile.id === localizedPbFile.id
        );
        if (index !== -1) {
          file.localizations[index][key] = value;
        } else {
          localizedPbFile[key] = value;
          file.localizations.push(localizedPbFile);
        }
      };

      if (newPbFile.id === localizedPbFile.id) {
        newPbFile[key] = value;
      } else {
        updatePbFileLocalization(newPbFile);
      }
      return {
        ...prevState,
        pbFile: { ...newPbFile },
      };
    });
  };

  useEffect(() => {
    if (currentItem && !("pathId" in currentItem)) {
      setFile(currentItem);
    }
  }, [currentItem]);

  const displaySize = (size: number) => {
    if (size < 1024) {
      return Math.trunc(size) + " KB";
    }
    return (size / 1024).toFixed(2) + " MB";
  };

  const pbFileByLocale = () => {
    if (file.pbFile?.locale === selectedLocale) {
      return file.pbFile;
    }

    if (file.pbFile?.localizations) {
      for (const localizedPbFile of file.pbFile.localizations) {
        if (localizedPbFile.locale === selectedLocale) {
          return localizedPbFile;
        }
      }
    }

    return {
      locale: selectedLocale,
    } as StrapiPbFile;
  };

  const openReplaceFileInput = () => {
    replaceFileInput.current.value = null;
    replaceFileInput.current.click();
  };

  const onChangeReplaceFileInput = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (!file.name.includes(".")) {
        createToastError(tCms("filenameIsWrong"));
        setReplaceFile(null);
        setReplaceFileUrl("");
        return;
      }
      setFile((prevState) => {
        return {
          ...prevState,
          name: file.name,
        };
      });
      setReplaceFile(file);
      if (file.type.includes("image")) {
        setReplaceFileUrl(URL.createObjectURL(file));
      }
    }
  };

  return (
    <>
      <input
        ref={replaceFileInput}
        type="file"
        hidden
        onChange={onChangeReplaceFileInput}
      />
      <div className="media-manager-file-details">
        <div className="media-manager-file-preview">
          {replaceFile && (
            <>
              {replaceFileUrl && replaceFile.type.includes("image") ? (
                <img
                  src={
                    replaceFile.type?.includes("image")
                      ? replaceFileUrl
                      : "/file-placeholder.png"
                  }
                  height={160}
                  alt={tCms("preview-selected-image")}
                />
              ) : (
                <FilePlaceholderIcon
                  mimeType={replaceFile.type}
                  height={120}
                  width={120}
                />
              )}
            </>
          )}
          {!replaceFile && file && (
            <>
              {file.mime.includes("image") ? (
                <CmsImage
                  image={file}
                  format="thumbnail"
                  alt={tCms("preview-selected-image")}
                  className={clsx(file.mime.includes("svg") && "svg")}
                />
              ) : (
                <FilePlaceholderIcon
                  mimeType={file.mime}
                  height={120}
                  width={120}
                />
              )}
            </>
          )}
        </div>
        <div className="media-manager-file-top-action-buttons">
          <PbButton
            variant="outlined"
            color="success"
            startIcon={
              <PbIcon name="rotate-light" className="svg-fill-green" />
            }
            disabled={editMode}
            onClick={handleFileReplace}
            title={
              editMode ? tCms("managedfile-delete-replace-hint") : undefined
            }
          >
            {tCms("file-replace")}
          </PbButton>
          <PbButton
            variant="outlined"
            color="error"
            startIcon={<PbIcon name="trash-light" className="svg-fill-red" />}
            disabled={editMode}
            onClick={handleFileDelete}
            title={
              editMode ? tCms("managedfile-delete-replace-hint") : undefined
            }
          >
            {tCms("file-delete")}
          </PbButton>
        </div>

        <div>
          <a
            style={{ wordBreak: "break-all" }}
            target="_blank"
            href={getStrapiURLClientSide(file.url)}
          >
            <PbIcon
              name="arrow-up-right-from-square-light"
              height={20}
              width={20}
              style={{ marginRight: "10px" }}
            />
            {file.url.substring(file.url.lastIndexOf("/") + 1)}
          </a>
        </div>
        <div>
          <a href={createPersistentDownloadUrlFromFile(file)} download>
            <PbIcon
              name="download-light"
              width={20}
              height={20}
              style={{ marginRight: "10px" }}
            />
            {tCms("download")}
          </a>
          <span style={{ marginLeft: "5px", fontSize: "14px" }}>
            ({displaySize(file.size)})
          </span>
        </div>
        <div
          aria-hidden="true"
          style={{ cursor: "pointer" }}
          onClick={() => copyToClipboard(getStrapiURLClientSide(file.url))}
        >
          <PbButton
            startIcon={
              <PbIcon
                name="copy-light"
                width={20}
                height={20}
                style={{ marginLeft: "-10px", marginRight: "5px" }}
              />
            }
            color="inherit"
          >
            {tCms("copy-link")}
          </PbButton>
        </div>
        <hr />
        <div>
          <PbInputControlled
            withCmsStyle
            label={tCms("managedFileModal-name")}
            placeholder={tCms("managedFileModal-name") + "..."}
            fullWidth
            value={displayNameWithoutFileExt(file.name)}
            endAdornment={
              <InputAdornment position="end">
                .{file.name.substring(file.name.lastIndexOf(".") + 1)}
              </InputAdornment>
            }
            onChange={handleFileNameChangeEvent}
          ></PbInputControlled>
        </div>
        <div>
          <PbInputControlled
            withCmsStyle
            label={tCms("managedFileModal-altText")}
            placeholder={tCms("managedFileModal-altText") + "..."}
            fullWidth
            value={pbFileByLocale()?.alternativeText ?? ""}
            onChange={(e) => {
              handlePbFileChangeEvent("alternativeText", e.target.value);
            }}
          ></PbInputControlled>
        </div>
        <div>
          <PbInputControlled
            withCmsStyle
            label={tCms("managedFileModal-title")}
            placeholder={tCms("managedFileModal-title") + "..."}
            fullWidth
            value={pbFileByLocale()?.title ?? ""}
            onChange={(e) => {
              handlePbFileChangeEvent("title", e.target.value);
            }}
          ></PbInputControlled>
        </div>
        <div>
          <PbInputControlled
            withCmsStyle
            label={tCms("managedFileModal-description")}
            placeholder={tCms("managedFileModal-description") + "..."}
            fullWidth
            value={pbFileByLocale()?.description ?? ""}
            onChange={(e) => {
              handlePbFileChangeEvent("description", e.target.value);
            }}
          ></PbInputControlled>
        </div>

        <div className="media-manager-file-bottom-action-buttons">
          <PbButton
            disabled={confirmButtonDisabled}
            startIcon={
              <PbIcon
                name="floppy-disk-light"
                height={20}
                width={20}
                className="svg-fill-white"
              />
            }
            onClick={handleUpdateEvent}
          >
            {tCms("file-confirmChanges")}
          </PbButton>
          <PbButton
            color="secondary"
            startIcon={
              <PbIcon
                name="xmark-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
            onClick={() => {
              setReplaceFile(null);
              setReplaceFileUrl("");
              setFile(currentItem);
              dispatch(mediaManagerModalDiscardDetailView());
            }}
          >
            {tCms("file-discard")}
          </PbButton>
        </div>
      </div>
      <style jsx>{`
        .media-manager-file-details {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin: 1rem;
          width: 100%;
        }

        .media-manager-file-preview {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 155px;

          :global(img) {
            height: 100%;
          }

          :global(.svg) {
            height: 155px;
            width: 155px;
          }
        }
        .media-manager-file-top-action-buttons {
          display: flex;
          justify-content: space-between;
        }
        .media-manager-file-bottom-action-buttons {
          display: flex;
          gap: 1em;
        }

        @media screen and (max-width: ${globalConfig?.responsive?.breakpoints
            ?.tablet}px) {
          .media-manager-file-top-action-buttons {
            flex-direction: column;
            gap: 1rem;
          }
        }
      `}</style>
    </>
  );
}
