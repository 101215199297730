const CmsDynamicListItemStyle = (): JSX.Element => {
  return (
    <style jsx global>{`
      .cms-dynamic-list-item {
        padding: 1rem;

        .overlay-action-wrapper {
          width: unset;
          div {
            padding: 10px;
          }
        }
      }
    `}</style>
  );
};

export default CmsDynamicListItemStyle;
