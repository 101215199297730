import { CustomTranslations } from "@/types/translations";

export let cmsTranslations: CustomTranslations | null = null;

export const setCmsTranslations = (pCmsTranslation: any) => {
  if (pCmsTranslation) {
    cmsTranslations = pCmsTranslation;
  } else {
    global.log.warn("fetched cmsTranslation is null or empty.");
  }
};

/**
 * Returns the translation for the given key
 * @param key
 * @returns
 */
export const cmsTranslate = (
  key: string,
  params?: { [key: string]: string }
) => {
  if (cmsTranslations && cmsTranslations[key]) {
    let translation = cmsTranslations[key];
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        translation = translation.replace("{{" + key + "}}", value);
      }
    }
    return translation;
  } else {
    return key;
  }
};
