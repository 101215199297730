import { globalConfig } from "@/services/globalConfig/globalConfigService";

/**
 * RichTextViewGlobalStyle
 */
export default function PbRichTextViewGlobalStyle() {
  return (
    <style jsx global>{`
      .richtext-view {
        word-wrap: break-word;
        .ql-indent-1:not(.ql-direction-rtl) {
          padding-left: 3em;
        }
        .ql-indent-2:not(.ql-direction-rtl) {
          padding-left: 6em;
        }
        .ql-indent-3:not(.ql-direction-rtl) {
          padding-left: 9em;
        }
        .ql-indent-4:not(.ql-direction-rtl) {
          padding-left: 12em;
        }
        .ql-indent-5:not(.ql-direction-rtl) {
          padding-left: 15em;
        }
        .ql-indent-6:not(.ql-direction-rtl) {
          padding-left: 18em;
        }
        .ql-indent-7:not(.ql-direction-rtl) {
          padding-left: 21em;
        }
        .ql-indent-8:not(.ql-direction-rtl) {
          padding-left: 24em;
        }
        .ql-indent-9:not(.ql-direction-rtl) {
          padding-left: 27em;
        }

        .ql-indent-1.ql-direction-rtl.ql-align-right {
          padding-right: 3em;
        }
        .ql-indent-2.ql-direction-rtl.ql-align-right {
          padding-right: 6em;
        }
        .ql-indent-3.ql-direction-rtl.ql-align-right {
          padding-right: 9em;
        }
        .ql-indent-4.ql-direction-rtl.ql-align-right {
          padding-right: 12em;
        }
        .ql-indent-5.ql-direction-rtl.ql-align-right {
          padding-right: 15em;
        }
        .ql-indent-6.ql-direction-rtl.ql-align-right {
          padding-right: 18em;
        }
        .ql-indent-7.ql-direction-rtl.ql-align-right {
          padding-right: 21em;
        }
        .ql-indent-8.ql-direction-rtl.ql-align-right {
          padding-right: 24em;
        }
        .ql-indent-9.ql-direction-rtl.ql-align-right {
          padding-right: 27em;
        }

        li.ql-indent-1:not(.ql-direction-rtl) {
          padding-left: 3em;
        }

        li.ql-indent-2:not(.ql-direction-rtl) {
          padding-left: 6em;
        }

        li.ql-indent-3:not(.ql-direction-rtl) {
          padding-left: 9em;
        }

        li.ql-indent-4:not(.ql-direction-rtl) {
          padding-left: 12em;
        }

        li.ql-indent-5:not(.ql-direction-rtl) {
          padding-left: 15em;
        }

        li.ql-indent-6:not(.ql-direction-rtl) {
          padding-left: 18em;
        }
        li.ql-indent-7:not(.ql-direction-rtl) {
          padding-left: 21em;
        }

        li.ql-indent-8:not(.ql-direction-rtl) {
          padding-left: 24em;
        }

        li.ql-indent-9:not(.ql-direction-rtl) {
          padding-left: 27em;
        }
        .ql-align-center {
          text-align: center;
        }

        .ql-align-right {
          text-align: end;
        }

        &::before {
          left: 0px;
        }

        p {
          hyphens: auto;
          @media (min-width: ${globalConfig?.responsive?.breakpoints
              ?.tablet}px) {
            word-break: break-word;
            hyphens: none;
          }

          span {
            hyphens: auto;
            @media (min-width: ${globalConfig?.responsive?.breakpoints
                ?.tablet}px) {
              word-break: break-word;
              hyphens: none;
            }
          }
        }

        ol {
          padding-left: calc(0.3em - 5px);

          li {
            margin-bottom: 10px;
            list-style-type: none;
            counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7
              list-8 list-9;
            counter-increment: list-0;
          }

          li::before {
            text-align: right !important;
            margin-left: -1.5em;
            margin-right: 0.5em;
            display: inline-block;
            white-space: nowrap;
            width: 1.2em;
            box-sizing: border-box;
            content: counter(list-0, decimal) ". ";
          }

          li,
          li:not(.ql-direction-rtl) {
            padding-left: 1.5em;
            word-break: break-word;
            hyphens: auto;
          }

          li.ql-indent-1 {
            counter-increment: list-1;
          }
          li.ql-indent-1:before {
            content: counter(list-1, lower-alpha) ". ";
          }
          li.ql-indent-1 {
            counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8
              list-9;
          }
          li.ql-indent-2 {
            counter-increment: list-2;
          }
          li.ql-indent-2:before {
            content: counter(list-2, lower-roman) ". ";
          }
          li.ql-indent-2 {
            counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
          }
          li.ql-indent-3 {
            counter-increment: list-3;
          }
          li.ql-indent-3:before {
            content: counter(list-3, decimal) ". ";
          }
          li.ql-indent-3 {
            counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
          }
          li.ql-indent-4 {
            counter-increment: list-4;
          }
          li.ql-indent-4:before {
            content: counter(list-4, lower-alpha) ". ";
          }
          li.ql-indent-4 {
            counter-reset: list-5 list-6 list-7 list-8 list-9;
          }
          li.ql-indent-5 {
            counter-increment: list-5;
          }
          li.ql-indent-5:before {
            content: counter(list-5, lower-roman) ". ";
          }
          li.ql-indent-5 {
            counter-reset: list-6 list-7 list-8 list-9;
          }
          li.ql-indent-6 {
            counter-increment: list-6;
          }
          li.ql-indent-6:before {
            content: counter(list-6, decimal) ". ";
          }
          li.ql-indent-6 {
            counter-reset: list-7 list-8 list-9;
          }
          li.ql-indent-7 {
            counter-increment: list-7;
          }
          li.ql-indent-7:before {
            content: counter(list-7, lower-alpha) ". ";
          }
          li.ql-indent-7 {
            counter-reset: list-8 list-9;
          }
          li.ql-indent-8 {
            counter-increment: list-8;
          }
          li.ql-indent-8:before {
            content: counter(list-8, lower-roman) ". ";
          }
          li.ql-indent-8 {
            counter-reset: list-9;
          }
          li.ql-indent-9 {
            counter-increment: list-9;
          }
          li.ql-indent-9:before {
            content: counter(list-9, decimal) ". ";
          }
        }

        ul {
          padding-left: calc(0.5em - 2px);
          li {
            margin-bottom: 10px;
          }

          li,
          li:not(.ql-direction-rtl) {
            list-style-position: inside;
            padding-left: 0 !important;
            hyphens: auto;
            @media (min-width: ${globalConfig?.responsive?.breakpoints
                ?.tablet}px) {
              word-break: break-word;
              hyphens: none;
            }
          }
        }
      }
    `}</style>
  );
}
