import useCmsTranslation from "@/hooks/useCmsTranslation";
import { StrapiUploadFileReference } from "@/types/strapi";

interface CmsMediaManagerFileReferenceListProps {
  fileReferenceList: Array<StrapiUploadFileReference>;
}

export default function CmsMediaManagerFileReferenceList(
  props: CmsMediaManagerFileReferenceListProps
) {
  const tCms = useCmsTranslation();
  return (
    <div style={{ maxHeight: "300px", overflow: "auto" }}>
      <p className="mb-4">
        <b>
          {props.fileReferenceList.length > 0
            ? tCms("managedfile-is-used")
            : tCms("managedfile-is-not-used")}
        </b>
      </p>
      <ul className="mb-0">
        {props.fileReferenceList
          ? props.fileReferenceList.map((collectionType, index) => {
            switch (collectionType.modelType) {
              case "navigation":
                return (
                  <li className="text-start" key={index}>
                    {tCms("navigation")}
                  </li>
                );
              case "dynamiclist":
                return (
                  <li className="text-start" key={index}>
                    {tCms(collectionType.description)} {collectionType.amount}x
                  </li>
                );
              default:
                return (
                  <li className="text-start" key={index}>
                    {tCms(collectionType.modelType)} &quot;
                    {collectionType.description}&quot; {collectionType.amount}x
                  </li>
                );
            }
            })
          : null}
      </ul>
    </div>
  );
}
