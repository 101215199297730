import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { StrapiUploadFile } from "@/types/strapi";
import { imageFormat } from "@/utils/util";
import React, { ReactNode } from "react";

/**
 * Represents the properties for optimizing a Strapi image.
 */
export interface OptimizeStrapiImageProps {
  /**
   * The Strapi image to be optimized.
   */
  image: StrapiUploadFile;

  /**
   * Specifies whether the optimization should be tailored for mobile devices.
   */
  isMobile?: boolean;

  /**
   * Configurable width limits for optimizing the image.
   * Can be defined as an object with desktop and mobile values or as a single numerical value.
   */
  widthLimitSettings?: OptimizeStrapiImageWidthLimits;

  /**
   * Skips the optimization process if set to true.
   */
  skipOptimization?: boolean;

  /**
   * The content to be rendered inside the component.
   */
  children: ReactNode;
}

/**
 * Type for defining width limits for optimizing a Strapi image.
 * Can be an object with desktop and mobile values or a single numerical value.
 */
export type OptimizeStrapiImageWidthLimits =
  | {
      desktop?: number;
      mobile?: number;
    }
  | number;

const MOBILE_MAX_IMG_WIDTH = 700;
const DESKTOP_MAX_IMG_WIDTH = 1400;

const imageDimensions = (file: StrapiUploadFile) => {
  if (!file.width) {
    return new Map<number, string>();
  }

  const dimensions = new Map<number, string>();
  dimensions.set(file.width, "original");
  if (file.formats) {
    Object.entries(file.formats)
      .sort((a, b) => (a[1].width ?? 0) - (b[1].width ?? 0))
      .forEach(([format, { width }]) => dimensions.set(width ?? 0, format));
  }
  return dimensions;
};

const widthLimitSettings = (
  widthLimitSettings: OptimizeStrapiImageWidthLimits | number | undefined,
  isMobile = false
) => {
  if (!widthLimitSettings) {
    return isMobile ? MOBILE_MAX_IMG_WIDTH : DESKTOP_MAX_IMG_WIDTH;
  }

  if (typeof widthLimitSettings === "number") {
    return widthLimitSettings;
  }

  const { mobile, desktop } = widthLimitSettings;
  return isMobile
    ? mobile ?? MOBILE_MAX_IMG_WIDTH
    : desktop ?? DESKTOP_MAX_IMG_WIDTH;
};

export default function OptimizeStrapiImage(props: OptimizeStrapiImageProps) {
  const optimizationDisabled =
    props.skipOptimization || globalConfig?.seo.disableImageOptimization;

  if (!props.image.mime.startsWith("image/")) {
    return React.cloneElement(props.children as React.ReactElement, {});
  }

  if (optimizationDisabled) {
    return React.cloneElement(props.children as React.ReactElement, {});
  }

  const optimizedImage: StrapiUploadFile = { ...props.image };
  const dimensions = imageDimensions(optimizedImage);
  const widthLimit = widthLimitSettings(
    props.widthLimitSettings,
    props.isMobile
  );

  // TBD how to handle very large images
  for (const [width, format] of dimensions) {
    if (width > widthLimit && format !== "original") {
      const formatData = imageFormat(optimizedImage, format);
      optimizedImage.url = formatData.url;
      optimizedImage.width = formatData.width;
      optimizedImage.height = formatData.height;
      break;
    }
  }

  return React.cloneElement(props.children as React.ReactElement, {
    image: optimizedImage,
  });
}
