import { getStrapiPrivateUrl } from "@/utils/api";
import { axiosGetRequestServerSide } from "@/utils/axiosServerUtil";
import { getCmsAccessToken } from "@/utils/serverUtil";
import { IncomingMessage } from "http";

export const getCmsSettingsServerSide = async (req: IncomingMessage) => {
  const result = await axiosGetRequestServerSide(
    getStrapiPrivateUrl("/pabu/cmssettings"),
    getCmsAccessToken(req)
  );
  if (!result.success) {
    return null;
  } 
  return result.data;
};
