import PbAutoComplete from "@/components/input/pbAutoComplete/pbAutoComplete";
import LoadingText from "@/components/loadingText/loadingText";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useIsMounted from "@/hooks/useIsMounted";
import { getPropertyRelationOptions } from "@/services/cmsPropertyService/cmsPropertyService";
import { useAppSelector } from "@/store/store";
import {
  CmsPropertyAttributeGroup,
  CmsPropertyAttributeRelation,
  CmsPropertyRelationValue,
} from "@/types/strapi";
import { TextField } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import CmsTooltip from "../cmsTooltip/cmsTooltip";

export interface CmsPropertyRelationProps {
  attributeGroup: CmsPropertyAttributeGroup;
  attribute: CmsPropertyAttributeRelation;
  currentValue: CmsPropertyRelationValue;
  isNonLocalizedDisabled?: boolean;
  onChange: (
    attributeGroupName: string,
    attributeName: string,
    value: any
  ) => void;
}

export default function CmsPropertyRelation(props: CmsPropertyRelationProps) {
  const isMounted = useIsMounted();
  const [selectOptions, setSelectOptions] = useState<Array<any> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [relationNotFound, setRelationNotFound] = useState<boolean>(false);
  const tCms = useCmsTranslation();
  const router = useRouter();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  useEffect(() => {
    const checkForMissingRelations = (options: Array<any>) => {
      if (props.currentValue.relationValue) {
        if (Array.isArray(props.currentValue.relationValue)) {
          if (props.currentValue.relationValue.length > 0) {
            const values = props.currentValue.relationValue
              .map((valueElement) => {
                const selectedOption = options?.find(
                  (selectOption) =>
                    selectOption[props.attribute.valueAttribute] ===
                    valueElement
                );
                if (selectedOption) {
                  return selectedOption;
                }
                return null;
              })
              .filter((selectedOption) => selectedOption !== null);
            if (values.length !== props.currentValue.relationValue.length) {
              setRelationNotFound(true);
            }
          }
        } else {
          const selectedOption = options?.find(
            (selectOption) =>
              selectOption[props.attribute.valueAttribute] ===
              props.currentValue.relationValue
          );
          if (!selectedOption) {
            setRelationNotFound(true);
          }
        }
      }
    };

    const getSelectOptions = async () => {
      let reqLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
      if (
        editView && // create is always in the default locale
        !props.attribute.nonLocalized &&
        props.attribute.localizedOptions
      ) {
        reqLocale = router.locale!;
      }

      const options = await getPropertyRelationOptions(
        reqLocale,
        props.attribute.model
      );

      if (isMounted()) {
        checkForMissingRelations(options);
        setSelectOptions(options);
        setIsLoading(false);
      }
    };

    if (selectOptions === null) {
      setIsLoading(true);
      getSelectOptions();
    }
  }, [
    isMounted,
    props.attribute.model,
    props.attribute.nonLocalized,
    props.attribute.localizedOptions,
    props.attribute.valueAttribute,
    props.currentValue.relationValue,
    selectOptions,
    router,
    editView,
  ]);

  /**
   * convert CmsPropertyRelationValue
   * back to objects that are in the selectOptions.
   *
   * @param value
   * @returns
   */
  const convertRelationValueToSelectValue = (
    value: CmsPropertyRelationValue | null
  ) => {
    if (!value) {
      return null;
    }

    if (Array.isArray(value.relationValue)) {
      const values = value.relationValue
        .map((valueElement) => {
          const selectedOption = selectOptions?.find(
            (selectOption) =>
              selectOption[props.attribute.valueAttribute] === valueElement
          );
          if (selectedOption) {
            return selectedOption;
          }
          return null;
        })
        .filter((selectedOption) => selectedOption !== null);
      return values;
    } else {
      const selectedOption = selectOptions?.find(
        (selectOption) =>
          selectOption[props.attribute.valueAttribute] === value.relationValue
      );
      return selectedOption ?? null;
    }
  };

  const getOptionLabel = (option: any) => {
    if (
      option &&
      props.attribute.labelAttribute &&
      option[props.attribute.labelAttribute]
    ) {
      if (
        typeof option[props.attribute.labelAttribute] === "object" &&
        typeof option[props.attribute.labelAttribute].hasOwnProperty(
          process.env.NEXT_PUBLIC_CMS_USER_LANGUAGE!
        )
      ) {
        return option[props.attribute.labelAttribute][
          process.env.NEXT_PUBLIC_CMS_USER_LANGUAGE!
        ];
      } else {
        return option[props.attribute.labelAttribute];
      }
    }

    if (option && option.id) {
      return option.id;
    }
    return undefined;
  };

  return (
    <>
      {selectOptions !== null ? (
        <PbAutoComplete
          disablePortal={false}
          withCmsStyle
          fullWidth
          multiple={props.attribute.multiple}
          options={selectOptions}
          defaultValue={
            convertRelationValueToSelectValue(props.currentValue) ?? null
          }
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {getOptionLabel(option)}
              </li>
            );
          }}
          getOptionLabel={(option) => getOptionLabel(option)}
          id={props.attribute.name}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={props.currentValue ? "" : tCms("choose-option")}
              margin="none"
            />
          )}
          onChange={(e, value) => {
            let relationValue: any | Array<any> = {
              model: props.attribute.model,
              relationKey: props.attribute.valueAttribute,
              relationValue: null,
              relation: null,
              populated: props.attribute.populated ?? true,
            };
            if (Array.isArray(value)) {
              // multiple
              const relationValuesArray = value.map((element) => {
                return element[props.attribute.valueAttribute];
              });
              relationValue = {
                model: props.attribute.model,
                relationKey: props.attribute.valueAttribute,
                relationValue: relationValuesArray,
                relation: [],
                populated: props.attribute.populated ?? true,
              };
            } else if (value) {
              // single
              relationValue = {
                model: props.attribute.model,
                relationKey: props.attribute.valueAttribute,
                relationValue: value[props.attribute.valueAttribute],
                relation: null,
                populated: props.attribute.populated ?? true,
              } as CmsPropertyRelationValue;
            }
            props.onChange(
              props.attributeGroup.name,
              props.attribute.name,
              relationValue
            );
            setRelationNotFound(false);
          }}
        />
      ) : (
        <>{isLoading && <LoadingText />}</>
      )}
      {relationNotFound && (
        <div className="d-flex">
          <div>
            <CmsTooltip
              iconName="circle-exclamation-light"
              iconWidth={20}
              iconHeight={20}
              title={tCms("property-relationNotFoundDescription")}
              iconClassName="svg-fill-red"
            />
          </div>
          <div className="my-auto">
            <p className="cms-modal-text">
              {tCms("property-relationNotFound")}
            </p>
          </div>
        </div>
      )}
    </>
  );
}
