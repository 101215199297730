import { SvgIds } from "./svg";

interface PbIconProps {
  /**
   * Additional class names to apply to the SVG.
   */
  className?: string;

  /**
   * The width of the SVG. Defaults to 16 if not provided.
   */
  width?: number;

  /**
   * The height of the SVG. Defaults to 16 if not provided.
   */
  height?: number;

  /**
   * Additional styles to apply to the SVG.
   */
  style?: React.CSSProperties;

  /**
   * id of each svg within public/svg/sprite.svg
   */
  name: SvgIds;
}

/**
 * Returns a SVG by given id from public/svg/...
 * @param {PbIconProps} props icon properties
 * @returns {JSX.Element}
 */
export const PbIcon = (props: PbIconProps) => (
  <svg
    className={props.className || ""}
    width={`${props.width || 16}px`}
    height={`${props.height || 16}px`}
    style={props.style || {}}
    role="img"
  >
    <use
      href={`${process.env.NEXT_PUBLIC_NEXTJS_URL}/svg/sprite.svg#${props.name}`}
    />
  </svg>
);
