import { MediaManagerFilterOption } from "@/components/cms/cmsMediaManager/cmsMediaManagerFilter";
import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useReduxClipboard from "@/hooks/useReduxClipboard";
import { rootFolder } from "@/services/cmsMediaManager/cmsMediaManagerService";
import { showMediaManagerModalAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch } from "@/store/store";

interface CmsMediaManagerSelectorProps {
  onConfirm?: (clipboardData: any) => void;
  onCancel?: () => void;
  mimeTypes?: Array<MediaManagerFilterOption>;
  children?: React.ReactNode;
  disabled?: boolean;
}

export default function CmsMediaManagerSelector(
  props: CmsMediaManagerSelectorProps
) {
  const dispatch = useAppDispatch();
  const { setClipboardPending, clipboardData } = useReduxClipboard({
    confirmSelectionFunction: () =>
      props.onConfirm && props.onConfirm(clipboardData),
    cancelSelectionFunction: () => props.onCancel && props.onCancel(),
  });

  const handleClick = async () => {
    setClipboardPending(true);
    const folder = await rootFolder();
    dispatch(
      showMediaManagerModalAction({
        itemSelection: !!confirm,
        mediaTypes: props.mimeTypes,
        currentFolder: folder ?? undefined,
        rootFolder: folder ?? undefined,
      })
    );
  };

  return (
    <>
      {props.children ? (
        <div role="button" onClick={handleClick} aria-hidden="true">
          {props.children}
        </div>
      ) : (
        <PbButton onClick={handleClick} disabled={props.disabled}>
          <PbIcon
            name={"photo-film-light"}
            className={"svg-fill-white"}
            width={20}
            height={20}
          />
        </PbButton>
      )}
    </>
  );
}
