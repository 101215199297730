import {
  ShowConfirmModalActionParams,
  resetConfirmModalAction,
  showConfirmModalAction,
} from "@/store/slices/general/generalSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { useEffect, useState } from "react";

/**
 * global confirm modal hook
 */
function useConfirmModal(
  confirmFunction: Function,
  denyFunction?: Function,
  cancelFunction?: Function
): {
  isConfirmPending: boolean;
  showConfirmModal: (payload: ShowConfirmModalActionParams) => void;
} {
  const dispatch = useAppDispatch();
  const confirmModal = useAppSelector((state) => state.general.confirmModal);
  const [isConfirmPending, setConfirmPending] = useState(false);

  const showConfirmModal = (payload: ShowConfirmModalActionParams) => {
    setConfirmPending(true);
    dispatch(showConfirmModalAction(payload));
  };

  useEffect(() => {
    if (isConfirmPending && confirmModal) {
      // only call the callback functions if the confirm modal is closed
      // and a user action was performed in the confirm modal
      if (!confirmModal.isOpen && confirmModal.userActionPerformed) {
        if (confirmFunction && confirmModal.isConfirmed) {
          confirmFunction();
        }
        if (denyFunction && confirmModal.isDenied) {
          denyFunction();
        }
        if (cancelFunction && confirmModal.isCanceled) {
          cancelFunction();
        }

        // reset the confirm modal to its default state
        dispatch(resetConfirmModalAction());
        setConfirmPending(false);
      }
    }
  }, [
    isConfirmPending,
    confirmModal,
    setConfirmPending,
    confirmFunction,
    denyFunction,
    cancelFunction,
    dispatch,
  ]);

  return { isConfirmPending, showConfirmModal };
}

export default useConfirmModal;
