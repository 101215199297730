import {
  dataValueFromCeStoreSetting,
  determineStoreValueId,
} from "@/services/ceSettings/ceSettingsService";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import { RICHTEXTEDITOR_DEFAULT_ENABLED_FORMATS } from "@/utils/constants";

export default function useCesStrRichText(
  richTextStoreSetting?: StoreSetting,
  ceSettings?: StoreSetting
) {
  if (!richTextStoreSetting) {
    return {
      richTextClassName: "",
      richtextEditorSettings: RICHTEXTEDITOR_DEFAULT_ENABLED_FORMATS,
    };
  }

  const richTextSettingsId = determineStoreValueId(
    richTextStoreSetting,
    ceSettings
  );

  const richTextClassName = richTextSettingsId
    ? `cesstr-richtext-${richTextSettingsId}`
    : "";

  const richtextEditorSettings = dataValueFromCeStoreSetting(
    richTextStoreSetting,
    richTextSettingsId ?? undefined,
    RICHTEXTEDITOR_DEFAULT_ENABLED_FORMATS
  );

  return { richTextClassName, richtextEditorSettings };
}
