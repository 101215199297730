interface CmsAboutModalStyleProps {}

export default function CmsAboutModalStyle(props: CmsAboutModalStyleProps) {
  return (
    <style jsx global>{`
      .cms-about-modal {
        .link-list {
          display: flex;
          justify-content: center;
          flex-direction: column;
          height: 100%;
        }
        .am-logo-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
        }
        .pabu-text {
          padding-top: 20px;
        }
        ul {
          margin: 0;
        }
        hr {
          margin: 2rem 0;
        }
      }
    `}</style>
  );
}
