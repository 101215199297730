import { storeColorCss } from "@/services/ceSettings/ceSettingsService";
import { globalConfig } from "@/services/globalConfig/globalConfigService";

export default function LanguageSelectorStyle() {
  const bgColor = storeColorCss(globalConfig?.multilanguage?.bgColor);

  const hoverBgColor = storeColorCss(globalConfig?.multilanguage?.hoverBgColor);

  const borderColor = storeColorCss(globalConfig?.multilanguage?.borderColor);

  const fontColor = storeColorCss(globalConfig?.multilanguage?.fontColor);

  const hoverFontColor = storeColorCss(
    globalConfig?.multilanguage?.hoverFontColor
  );

  return (
    <style jsx global>{`
      .language-selector {
        &.MuiInputBase-root {
          background-color: ${bgColor} !important;
          color: ${fontColor};
          border-color: ${borderColor} !important;

          svg {
            fill: ${fontColor};
          }

          .MuiSelect-select.Mui-disabled:hover {
            cursor: not-allowed;
          }

          .MuiList-root {
            background-color: ${bgColor} !important;

            .Mui-selected {
              background-color: unset;
            }

            .MuiMenuItem-root {
              color: ${fontColor} !important;
            }

            .MuiMenuItem-root:hover {
              background-color: ${hoverBgColor} !important;
              color: ${hoverFontColor} !important;
            }
          }

          .MuiOutlinedInput-notchedOutline::after,
          .MuiPaper-root ul {
            border-color: ${borderColor} !important;
          }
          fieldset {
            border-color: ${bgColor};
          }

          .MuiOutlinedInput-notchedOutline {
            overflow: hidden;
          }
        }
      }
    `}</style>
  );
}
