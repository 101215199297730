export interface CmsLinkChooserStyleProps {}

/**
 * CmsLinkChooser
 */
export default function CmsLinkChooserStyle(props: CmsLinkChooserStyleProps) {
  return (
    <style jsx global>{`
      .cms-link-chooser {
        .file-select-btn {
          width: 100%;
        }
        .download-link {
          word-break: break-all;
          font-size: var(--pb-cms-font-size);
        }
      }
    `}</style>
  );
}
