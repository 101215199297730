/**
 * Represents the props for the CmsMaxLengthIndicator component.
 */
export interface CmsMaxLengthIndicatorProps {
  /**
   * The maximum length allowed.
   */
  maxLength?: number;

  /**
   * The current length of the content.
   */
  length?: number;

  /**
   * Specifies the distance from the right (in pixels) if provided.
   */
  right?: string;

  /**
   * Specifies the font size (in pixels) if provided.
   */
  fontSize?: string;
}
/**
 * CmsMaxLengthIndicator component displays a length indicator for content with a maximum length.
 * @param props - The component properties.
 * @returns {JSX.Element} The CmsMaxLengthIndicator component.
 */
const CmsMaxLengthIndicator = (
  props: CmsMaxLengthIndicatorProps
): JSX.Element => {
  return (
    <>
      {props.maxLength && (
        <div className={`cms-max-length-indicator`}>
          {props.length && `${props.length}/${props.maxLength}`}
        </div>
      )}
      <style jsx>
        {`
          .cms-max-length-indicator {
            /* Only displayed if indicator is inside a focused element */
            display: none;
            /* Position: */
            position: absolute;
            bottom: 2px;
            right: 2px;
            margin-top: -12px;
            text-align: right;
            width: max-content;
            margin: 2px;
            /* Style: */
            pointer-events: none;
            font-family: var(--pb-cms-font);
            font-size: ${props.fontSize ?? "12px"};
            font-weight: 600;
            color: ${props.maxLength && props.length === props.maxLength
              ? "#e0284f"
              : "#000000"};
            text-shadow: ${props.maxLength && props.length === props.maxLength
              ? "var(--pb-cms-text-shadow)"
              : "none"};
            /* Without z-index: -1 the area of the indicator blocks the input area. */
            z-index: ${props.length &&
            props.maxLength &&
            props.maxLength / props.length > 2
              ? "-1"
              : "0"};
            /* Opacity is only not 0 and increasing if more than half of maxLength is reached. */
            opacity: ${props.maxLength &&
            props.length &&
            props.maxLength / props.length < 2
              ? `${1 / (props.maxLength / props.length)}`
              : "0"};
          }

          :global(.nested-content-frame:focus-within .cms-max-length-indicator),
          :global(.content-frame:focus-within .cms-max-length-indicator),
          :global(.news-content:focus-within .cms-max-length-indicator),
          :global(.event-content:focus-within .cms-max-length-indicator),
          :global(
              .with-max-length-indicator:focus-within .cms-max-length-indicator
            ) {
            display: block;
          }

          :global(.with-max-length-indicator) {
            position: relative;
          }
        `}
      </style>
    </>
  );
};

export default CmsMaxLengthIndicator;
